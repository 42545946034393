const { toast } = require("react-toastify");

let userID;

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}
const getUserID = async () => {
    const data = window.sessionStorage.getItem("data");
    return data;
}
export async function getUserIDFromSessionStorage() {
    if (userID && userID !== '') {
        return userID;
    }
    var whatever = Array(50).fill('');
    for (const i of whatever) {
        const uid = await getUserID();
        if (uid && uid !== '') {
            userID = uid;
            break;
        }
        await sleep(100);
    }
    return userID;
}