import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import brandReducer from './slice/brandSlice';
import kiranatvReducer from './slice/kiranaTvSlice';
import karidBetchReducer from './kharidBetch/slice';
import quizReducer from './quiz/slice';
import pngReducer from './pngSurvey/slice';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { kiranatvSlice } from './slice/kiranaTvSlice';

const quizPersitConfig = {
	key: 'quiz',
	storage: storage,
	stateReconciler: autoMergeLevel2,
	blacklist: [
		'dynamicLink',
		'dynamicLinkLoading',
		'leaderBoard',
		'leaderBoardPoints',
		'leaderBoardLoading',
		'sessionPoints',
	],
};

const surveyPersitConfig = {
	key: 'pngSurvey',
	storage: storage,
	whitelist: ['surveyActiveQuestions'],
};

const quizPersitReducer = persistReducer(quizPersitConfig, quizReducer);
const surveyPersitReducer = persistReducer(surveyPersitConfig, pngReducer);

const store = configureStore({
	middleware: [thunk],
	reducer: {
		brand: brandReducer,
		kharidBetch: karidBetchReducer,
		quiz: quizPersitReducer,
		pngSurvey: surveyPersitReducer,
		kiranatv: kiranatvReducer,
	},
});

export const persistor = persistStore(store);
export default store;
