import React, { useCallback, useState, useEffect } from 'react';

import InfiniteScroll from 'react-infinite-scroll-component';
// import InfiniteScroll from 'react-infinite-scroller';

import { useNavigate, useLocation } from 'react-router-dom';

import RateCard2 from './rateCard2';

import banner from '../../assests/KBCbanner.png';
import mandi from '../../assests/mandi.png';
import { navigateToScreen } from '../../utils/postMessage';
import { CircularProgress } from '@mui/material';
import mixpanel from '../../utils/mixpanel';
import RateFeedback from './rateFeedBackModal';
import RateCard3 from './rateCard3';
import BottomSheetSampark2 from './bottomSheetSampark2';

function RateList({ prodList = [], NoData, getRates = () => {}, isBuy, uid }) {
	const navigate = useNavigate();
	const location = useLocation();
	const [bottomSheet, setBottomSheet] = useState(false);
	const [content, setContent] = useState('');
	const [headline, setHeadline] = useState('');
	const [id, setId] = useState(null);

	const handleOnClickmandi = useCallback(() => {
		if (mixpanel?.get_distinct_id()) {
			mixpanel.track('Clicked on Mandi Banner in KBC');
		}
		navigateToScreen('MandiBhavTab', { name: 'dummy' });
	}, []);

	// useEffect(() => {
	// 	// Restore scroll position when coming back to the list page
	// 	if (location?.state && location?.state?.scrollPosition) {
	// 		window.scrollTo(0, location?.state?.scrollPosition);
	// 	} else {
	// 		window.scrollTo(0, 0);
	// 	}
	// }, []);

	useEffect(() => {
		if (location?.state && location?.state?.scrollPosition) {
			// Wait for a short delay to ensure scroll restoration happens after the component renders
			setTimeout(() => {
				window.scrollTo(0, location?.state?.scrollPosition);
			}, 50);
		} else {
			window.scrollTo(0, 0);
		}
	}, [location?.state?.scrollPosition]);


	return (
		<div className="scroll-smooth">
			{!!prodList?.length ? (
				<InfiniteScroll
					dataLength={prodList?.length}
					next={getRates}
					hasMore={true}
					loader={
						<div
							style={{
								width: '100%',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								height: 50,
							}}
						>
							<CircularProgress size={20} sx={{ color: '#D45339' }} />
						</div>
					}
					endMessage={
						!!prodList?.length ? (
							<p style={{ textAlign: 'center' }}>
								<b>All data Loaded</b>
							</p>
						) : (
							<div />
						)
					}
					pullDownToRefreshThreshold={3}
				>
					{prodList?.map((i, idx) => {
						return (
							<div key={i?.id}>
								{
									<div>
										<RateCard3
											dynamicLink={i?.dynamic_link}
											setHeadline={setHeadline}
											uid={uid}
											distance={parseInt(i?.distance)}
											keyy={idx}
											content={i?.content}
											feedBack={i?.feedBack}
											createdAt={i?.created_at}
											contact={i?.elementData?.phone_number}
											id={i?.id}
											user={i?.user}
											shouldShowMore={i?.shouldShowMore}
											elementData={i?.elementData}
											isBuy={isBuy}
											onClickFeedback={() => {
												setContent(i?.content);
												setBottomSheet(true);
												setId(i?.id);
											}}
										/>
									</div>
								}
								{/* <RateCard2
									setHeadline={setHeadline}
									uid={uid}
									distance={parseInt(i?.distance)}
									keyy={idx}
									content={i?.content}
									feedBack={i?.feedBack}
									createdAt={i?.created_at}
									contact={i?.elementData?.phone_number}
									id={i?.id}
									user={i?.user}
									shouldShowMore={i?.shouldShowMore}
									elementData={i?.elementData}
									isBuy={isBuy}
									onClickFeedback={() => {
										setContent(i?.content)
										setBottomSheet(true)
										setId(i?.id)
									}}
								/> */}
								{idx === 1 ? (
									<div
										onClick={() => {
											if (mixpanel?.get_distinct_id?.()) {
												mixpanel.track('Clicked on Mandi Banner in KBC');
											}
										}}
										id={`idx ${idx}`}
										style={{ marginBottom: 10, marginTop: 10, margin: 15 }}
									>
										<img src={banner} alt={'bannerImage'} />
									</div>
								) : (
									<div />
								)}
								{/* {idx === 3 ? (
									<div
										onClick={handleOnClickmandi}
										id={`idx ${idx}`}
										style={{ marginBottom: 10, marginTop: 10, margin: 15 }}
									>
										<img src={mandi} alt={'mandiImage'} />
									</div>
								) : (
									<div />
								)} */}
							</div>
						);
					})}
				</InfiniteScroll>
			) : (
				<div
					onClick={() => {
						if (!!isBuy) {
							navigate('/saleIncrease2/survey');
						} else {
							navigate('/sujhavDe');
						}
					}}
					style={{
						height: '100vh',
						paddingTop: '10%',
					}}
				>
					<img
						src={NoData}
						style={{ height: 300, marginLeft: 'auto', marginRight: 'auto', marginTop: '30%' }}
						alt={'no data'}
					></img>
				</div>
			)}
			{/* <BottomSheetSampark2
				setOpen={setBottomSheet}
				bottomSheet={bottomSheet}
				content={content}
				handleBottomSheet={() => { setBottomSheet(!bottomSheet) }}
				headline={headline}
				id={id}
			/> */}
			<RateFeedback
				setOpen={setBottomSheet}
				bottomSheet={bottomSheet}
				content={content}
				handleBottomSheet={() => {}}
				headline={headline}
				id={id}
			/>
		</div>
	);
}

export default RateList;
