import React, { useEffect, useRef } from 'react';
import Image from '../components/ui/Image';

const post1 = require('../assests/UGC_1.webp');
const post2 = require('../assests/UGC_2.webp');

function ShowSamplePosts() {
	const contentRef = useRef();

	useEffect(() => {
		contentRef?.current?.scrollIntoView(true);
	}, [])
	
	
	return (
	
			<div ref={contentRef} className="flex flex-col justify-between bg-[#F5F5F5]">
				<img alt="user" src={post1} className="w-[90%] bg-[#F5F5F5] h-80  mb-2 self-center " />
				<img alt="user" src={post2} className="w-[90%] bg-[#F5F5F5] h-96 mb-3 self-center " />
			</div>

	);
}

export default ShowSamplePosts;
