import React from 'react';

function Verified({ width = 16, height = 16, color = '#fff', background = '#2C99FF', className }) {
	return (
		<svg width={width} height={height} className={className} viewBox="0 0 16 16" fill="none">
			<g clipPath="url(#a)">
				<path
					d="M8.38 14.72h-.71L6 13H3.53L3 12.5v-2.42L1.31 8.36v-.71L3 5.93V3.5l.53-.5H6l1.67-1.71h.71L10.1 3h2.43l.5.49v2.44l1.71 1.72v.71L13 10.08v2.42l-.5.5h-2.4l-1.72 1.72Zm-1.65-4.24h.71l3.77-3.77L10.5 6 7.09 9.42 5.71 8.04 5 8.75l1.73 1.73Z"
					fill={background}
				/>
			</g>
			<defs>
				<clipPath id="a">
					<path fill={color} d="M1.311 1.289h13.43v13.43H1.311z" />
				</clipPath>
			</defs>
		</svg>
	);
}

export default React.memo(Verified);
