import React, { useCallback, useEffect, useState, useRef, useMemo } from 'react';

import RateList from './rateList';

import { useUserContext } from '../../context/userContext';
import { getUserIDFromSessionStorage } from '../../utils/getUserID';
import mixpanel from '../../utils/mixpanel';

import { makeStyles } from '@mui/styles';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import bannerPhoto from '../../assests/marketPlaceBanner.png';
import { BsArrowLeft } from 'react-icons/bs';

import { FiChevronRight } from 'react-icons/fi';

import { useDispatch, useSelector } from 'react-redux';
import {
	getBuyProductsExtra,
	getBuyProductsInitial,
	getSellProductsExtra,
	getSellProductsInitial,
} from '../../redux/kharidBetch/thunk';
import {
	sellProductsSelector,
	sellProductsLoadingSelector,
	buyProductsLoadingSelector,
	buyProductsSelector,
	tabState,
} from '../../redux/kharidBetch/selector';
import { getUserId } from '../../utils/userData';
import FAQ from '../../assests/FAQ.svg';
import { changeTab } from '../../redux/kharidBetch/slice';
import { CircularProgress } from '@mui/material';
import { sendPostMessage } from '../../utils/postMessage';
import { WEBVIEW_EVENTS } from '../../constant/constant';
const KharidBetchBtn = require('../../assests/KharidBetchBtn.png');
const NoBecheData = require('../../assests/NoBecheData.png');
const NoKhareedData = require('../../assests/NoKhareedData.png');

// Custom styles for the active tab text color
// const StyledTabs = styled(Tabs)(({ theme }) => ({
// 	'& .MuiTabs-indicator': {
// 		backgroundColor: '#D45339', // Replace with your desired indicator color
// 	},
// }));

// const StyledTab = styled(Tab)(({ theme }) => ({
// 	'&.Mui-selected': {
// 		color: '#D45339', // Replace with your desired active text color
// 		fontSize: 18,
// 	},
// }));

const onceCalledFunction = async () => {
	const uid = await getUserIDFromSessionStorage();
	if (uid && uid !== '') {
		mixpanel.identify(uid);
		window.webengage.user.login(uid);
		mixpanel.track('ScreenView', {
			screen_name: 'KBC Posts',
		});
		window.webengage.track('ScreenView', {
			screen_name: 'KBC Posts',
		});
	}
};

function MarketPlaceReq() {
	const dispatch = useDispatch();
    const navigate=useNavigate();

	const sellProducts = useSelector(sellProductsSelector);
	const sellProductsLoading = useSelector(sellProductsLoadingSelector);

	const tab = 1;
	const buyProducts = useSelector(buyProductsSelector);
	const buyProductsLoading = useSelector(buyProductsLoadingSelector);

	const { userDetails, setUserDetails } = useUserContext();

	const getLocationPermission = async () => {
		sendPostMessage(WEBVIEW_EVENTS.GET_LOCATION_PERMISSION);
	};
	useEffect(() => {
		onceCalledFunction();
	}, []);

	useEffect(() => {
		getLocationPermission();
	}, []);

	useEffect(() => {
		// if (tab === 0) {
		// 	if (!sellProducts?.length) {
		// 		dispatch(getSellProductsInitial({ userDetails }));
		// 	}
		// }
		// if (tab === 1) {
			if (!buyProducts?.length) {
				dispatch(getBuyProductsInitial({ userDetails }));
			}
		// }
	}, [buyProducts?.length, dispatch, sellProducts?.length, tab, userDetails]);

	//fetching user details from app
	useEffect(() => {
		setTimeout(() => {
			setUserDetails(() => {
				return {
					userId: getUserId(),
				};
			});
		}, 500);
	}, []);

	const handleTabChange = useCallback(
		(event, newValue) => {
			dispatch(changeTab(newValue));
		},
		[dispatch]
	);

	const getRates = useCallback(() => {
		// if (tab === 0) {
		// 	dispatch(getSellProductsExtra({ userDetails }));
		// 	return;
		// }
		// if (tab === 1) {
			dispatch(getBuyProductsExtra({ userDetails }));
			return;
		// }
	}, [dispatch, userDetails]);

	const renderTabContent = useMemo(() => {
		// switch (tab) {
		// 	case 0:
		// 		return (
		// 			<div
		// 				style={{
		// 					backgroundColor: '#F2F2F2',
		// 					paddingTop: !sellProducts?.length ? 0 : 15,
		// 					marginTop: 100,
		// 				}}
		// 			>
		// 				{/* <h4 style={{ fontSize: 18, marginLeft: 18 }}>आपके एरिया मैं 4 दुकानदार है...</h4> */}
		// 				{!sellProductsLoading ? (
		// 					<>
		// 						<div
		// 							style={{
		// 								display: 'flex',
		// 								justifyContent: 'center',
		// 								alignItems: 'center',
		// 								padding: 18,
		// 								paddingTop: 20,
		// 							}}
		// 						>
		// 							<img src={bannerPhoto} width={'100%'} height={94} alt="bannerPhoto" />
		// 						</div>
		// 						<RateList
		// 							NoData={NoBecheData}
		// 							uid={getUserId()}
		// 							prodList={sellProducts}
		// 							getRates={getRates}
		// 							isBuy={false}
		// 						/>
		// 					</>
		// 				) : (
		// 					<div
		// 						style={{
		// 							textAlign: 'center',
		// 							display: 'flex',
		// 							height: '100vh',
		// 							flexDirection: 'column',
		// 							justifyContent: 'center',
		// 							alignItems: 'center',
		// 							paddingBottom: '50%',
		// 						}}
		// 					>
		// 						<h3 style={{ textAlign: 'center' }}>
		// 							हम आपके एरिया की बेस्ट पोस्ट लोड <br></br> कर रहे है।
		// 						</h3>
		// 						<CircularProgress size={60} style={{ color: '#F16943' }} />
		// 					</div>
		// 				)}
		// 			</div>
		// 		);
		// 	case 1:
				return (
					<div
						style={{
							backgroundColor: '#F2F2F2',
							paddingTop: !buyProducts?.length ? 0 : 15,
							marginTop: 100,
						}}
					>
						{/* <h4 style={{ fontSize: 18, marginLeft: 18 }}>आपके एरिया मैं 4 दुकानदार है...</h4> */}
						{!buyProductsLoading ? (
							<RateList
								NoData={NoKhareedData}
								uid={getUserId()}
								prodList={buyProducts}
								getRates={getRates}
								isBuy
							/>
						) : (
							<div
								style={{
									textAlign: 'center',
									display: 'flex',
									height: '100vh',
									flexDirection: 'column',
									justifyContent: 'center',
									alignItems: 'center',
									paddingBottom: '50%',
								}}
							>
								<h3 style={{ textAlign: 'center' }}>
									हम आपके एरिया की मौजूदा <br></br>रिक्वायरमेंट लोड कर रहे है।
								</h3>
								<CircularProgress size={60} style={{ color: '#F16943' }} />
							</div>
						)}
					</div>
				);

		// 	default:
		// 		return <p>No tab found</p>;
		// }
	}, [getRates, buyProducts, buyProductsLoading]);

	const renderTabContentSingleScrollElement = useMemo(() => {
		return (
			<div
				style={{
					backgroundColor: '#F2F2F2',
					paddingTop: (tab === 0 ? !sellProducts?.length : !buyProducts?.length) ? 0 : 15,
					marginTop: 100,
				}}
			>
				{tab === 0 && (
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							padding: 18,
							paddingTop: 20,
						}}
					>
						<img src={bannerPhoto} width={'100%'} height={94} alt="bannerPhoto" />
					</div>
				)}
				{sellProductsLoading || buyProductsLoading ? (
					tab === 0 ? (
						<div
							style={{
								textAlign: 'center',
								display: 'flex',
								height: '100vh',
								flexDirection: 'column',
								justifyContent: 'center',
								alignItems: 'center',
								paddingBottom: '50%',
							}}
						>
							<h3 style={{ textAlign: 'center' }}>
								हम आपके एरिया की बेस्ट पोस्ट लोड <br></br> कर रहे है।
							</h3>
							<CircularProgress size={60} style={{ color: '#F16943' }} />
						</div>
					) : (
						<div
							style={{
								textAlign: 'center',
								display: 'flex',
								height: '100vh',
								flexDirection: 'column',
								justifyContent: 'center',
								alignItems: 'center',
								paddingBottom: '50%',
							}}
						>
							<h3 style={{ textAlign: 'center' }}>
								हम आपके एरिया की मौजूदा <br></br>रिक्वायरमेंट लोड कर रहे है।
							</h3>
							<CircularProgress size={60} style={{ color: '#F16943' }} />
						</div>
					)
				) : (
					<RateList
						NoData={tab === 0 ? NoBecheData : NoKhareedData}
						uid={getUserId()}
						prodList={tab === 0 ? sellProducts : buyProducts}
						getRates={getRates}
						isBuy={!!tab}
					/>
				)}
			</div>
		);
	}, [buyProducts, buyProductsLoading, getRates, sellProducts, sellProductsLoading, tab]);

	return (
		<div style={{ position: 'relative', backgroundColor: '#F2F2F2' }}>
			<div style={{ position: 'fixed', top: 0, zIndex: 2, width: '100%' }}>
				<Link
					to={'/marketPlace/postCategory'}
					onClick={() => {
						if (mixpanel?.get_distinct_id) {
							mixpanel.track('Clicked on Plus Button in KBC', {});
						}
						window.webengage.track('Clicked on Plus Button in KBC', {});
					}}
				>
					<div style={{ position: 'fixed', bottom: '40px', right: '20px', zIndex: 3 }}>
						<img src={KharidBetchBtn} width={'150px'} />
					</div>
				</Link>

				<div
					style={{
						display: 'flex',
						width: '100%',
						backgroundColor: '#D45339',
						padding: 15,
						alignItems: 'center',
						justifyContent: 'space-between',
					}}
				>
					<div style={{ display: 'flex' }}>
						<BsArrowLeft onClick={()=>{
							if (!window.history.state) {
								window.ReactNativeWebView.postMessage(
									JSON.stringify({ type: 'navigation', nav: 'NewsScreen' })
								);
							} else {
								  navigate(-1);
							}
						}} size={20} color="white" style={{ marginRight: 10 }} />
						<h3 style={{ fontSize: 18, fontWeight: 'bold', color: 'white', margin: 0 }}>
							खरीद बेच
						</h3>
					</div>
					<a
						href="https://kiranaclub.notion.site/kiranaclub/FAQ-81fd627e04204979b51e293748b44b5b"
						onClick={() => {
							if (mixpanel?.get_distinct_id) {
								mixpanel.track('Clicked on Help Icon in KBC', {});
							}
							window?.webengage?.track('Clicked on Help Icon in KBC', {});
						}}
						style={{ textDecoration: 'none' }}
					>
						<img src={FAQ} alt="faq" />
					</a>
				</div>

				<div className="flex bg-[#fff] z-50">
					<div
						onClick={() => {
							if (mixpanel?.get_distinct_id) {
								mixpanel.track('Clicked on Kharid Bech Tab in KBC');
							}
							window?.webengage?.track('licked on Kharid Bech Tab in KB', {});
                            navigate(-1);
						}}
						className="flex flex-1 justify-center py-[1rem]"
						style={{
							color: tab === 0 ? '#D45339' : '#000',
							borderBottom: tab === 0 ? '2px solid #D45339' : '',
							fontWeight: 'bold',
							fontSize: 16,
						}}
					>
						सामान खरीदें
					</div>
					<div
						onClick={() => {
							if (mixpanel?.get_distinct_id) {
								mixpanel?.track('Clicked on Requirement Tab in KBC');
							}
							window?.webengage?.track('Clicked on Requirement Tab in KBC', {});
							// handleTabChange(null, 1);
						}}
						style={{
							color: tab === 1 ? '#D45339' : '#000',
							borderBottom: tab === 1 ? '2px solid #D45339' : '',
							fontWeight: 'bold',
							fontSize: 16,
						}}
						className="flex flex-1 justify-center py-[1rem]"
					>
						रिक़्वायरमेंट देखें
					</div>
				</div>
			</div>
			<div>{renderTabContent}</div>
			{/* {renderTabContentSingleScrollElement} */}
		</div>
	);
}

export default MarketPlaceReq;
