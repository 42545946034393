import React from 'react'
import PercentageLoader from '../assests/svg/PercentageLoader'

function UploadLoader({percentage}) {
  return (
    <div className="top-0 left-0 w-full min-h-full right-0 bottom-0 fixed bg-[#000000] bg-opacity-70 z-50 flex flex-1 justify-center items-center">
    <div className="w-44 h-44 flex flex-col justify-center items-center">
        <PercentageLoader progress={percentage} size={250} strokeColor="#D45339" />
        <div className="flex  absolute flex-col justify-center items-center h-full bg-transparent">
            <div className="text-2xl text-[#D45339] font-bold mb-1">
                {percentage.toFixed(2)} %
            </div>
            <div className="text-lg text-[#D45339]">
              {percentage === 100 ? "Uploaded" : "Uploading"}
            </div>
        </div>
    </div>
</div>
  )
}

export default UploadLoader