import React from 'react';

function Coin({ width = 20, height = 20, className }) {
	return (
		<svg width={width} height={height} className={className} viewBox="0 0 20 20" fill="none">
			<circle cx={10.419} cy={9.815} r={9.813} fill="#FFE34D" />
			<path
				d="M12.7.269a9.812 9.812 0 0 1 3.39 1.535l-1.197 1.692a7.74 7.74 0 0 0-2.675-1.212L12.7.27Z"
				fill="url(#a)"
				fillOpacity={0.65}
			/>
			<path
				d="M6.693 18.891a9.813 9.813 0 0 1-3.113-2.04l1.445-1.487a7.74 7.74 0 0 0 2.455 1.61l-.787 1.917Z"
				fill="url(#b)"
				fillOpacity={0.65}
			/>
			<path
				d="M10.427 19.628c-.897 0-1.79-.122-2.653-.364l.558-1.996c.682.191 1.386.288 2.093.287l.002 2.073Z"
				fill="url(#c)"
				fillOpacity={0.65}
			/>
			<path
				d="M16.778 2.337a9.812 9.812 0 0 1 1.784 1.997l-1.72 1.157a7.738 7.738 0 0 0-1.407-1.576l1.343-1.578Z"
				fill="url(#d)"
				fillOpacity={0.65}
			/>
			<g filter="url(#e)">
				<circle cx={10.42} cy={9.813} r={8.263} fill="url(#f)" />
			</g>
			<g filter="url(#g)">
				<path
					d="M10.37 6.943 8.693 8.3a.673.673 0 0 0 .837 1.055l1.72-1.337a.278.278 0 0 1 .342.438l-.363.28 2.41 2.219V7.684h-.013l-.068-.043-1.258-.806a1.654 1.654 0 0 0-1.93.108Zm.395 2.155-.896.697A1.227 1.227 0 0 1 8.342 7.87l1.442-1.166a1.637 1.637 0 0 0-.637-.128 1.64 1.64 0 0 0-.915.275l-1.248.832v3.882h.489l1.583 1.445a.83.83 0 0 0 1.368-.41l.294.27a.832.832 0 0 0 1.347-.337.832.832 0 0 0 1.026-1.305L10.764 9.1ZM5.043 7.684a.278.278 0 0 0-.277.277v3.605c0 .307.248.554.554.554h.555a.554.554 0 0 0 .554-.554V7.684H5.043Zm.555 3.327a.277.277 0 1 1 0 .555.277.277 0 0 1 0-.555Zm8.595-3.327v3.882c0 .307.247.554.554.554h.555a.554.554 0 0 0 .554-.554V7.96a.278.278 0 0 0-.277-.277h-1.386Zm.554 3.605a.277.277 0 1 1 .555 0 .277.277 0 0 1-.555 0Z"
					fill="#000"
				/>
				<path
					d="M10.37 6.943 8.693 8.3a.673.673 0 0 0 .837 1.055l1.72-1.337a.278.278 0 0 1 .342.438l-.363.28 2.41 2.219V7.684h-.013l-.068-.043-1.258-.806a1.654 1.654 0 0 0-1.93.108Zm.395 2.155-.896.697A1.227 1.227 0 0 1 8.342 7.87l1.442-1.166a1.637 1.637 0 0 0-.637-.128 1.64 1.64 0 0 0-.915.275l-1.248.832v3.882h.489l1.583 1.445a.83.83 0 0 0 1.368-.41l.294.27a.832.832 0 0 0 1.347-.337.832.832 0 0 0 1.026-1.305L10.764 9.1ZM5.043 7.684a.278.278 0 0 0-.277.277v3.605c0 .307.248.554.554.554h.555a.554.554 0 0 0 .554-.554V7.684H5.043Zm.555 3.327a.277.277 0 1 1 0 .555.277.277 0 0 1 0-.555Zm8.595-3.327v3.882c0 .307.247.554.554.554h.555a.554.554 0 0 0 .554-.554V7.96a.278.278 0 0 0-.277-.277h-1.386Zm.554 3.605a.277.277 0 1 1 .555 0 .277.277 0 0 1-.555 0Z"
					fill="url(#h)"
				/>
			</g>
			<defs>
				<linearGradient
					id="a"
					x1={13.002}
					y1={0.259}
					x2={15.068}
					y2={8.006}
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#fff" />
					<stop offset={1} stopColor="#fff" stopOpacity={0} />
				</linearGradient>
				<linearGradient
					id="b"
					x1={5.513}
					y1={18.594}
					x2={6.546}
					y2={15.237}
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#fff" />
					<stop offset={1} stopColor="#fff" stopOpacity={0} />
				</linearGradient>
				<linearGradient
					id="c"
					x1={9.466}
					y1={19.447}
					x2={9.387}
					y2={16.013}
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#fff" />
					<stop offset={1} stopColor="#fff" stopOpacity={0} />
				</linearGradient>
				<linearGradient
					id="d"
					x1={17.392}
					y1={3.098}
					x2={15.017}
					y2={7.878}
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#fff" />
					<stop offset={1} stopColor="#fff" stopOpacity={0} />
				</linearGradient>
				<linearGradient
					id="h"
					x1={10.311}
					y1={6.575}
					x2={10.311}
					y2={13.229}
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#EDFF7C" />
					<stop offset={0.464} stopColor="#FAFF00" />
					<stop offset={1} stopColor="#FF8B00" />
				</linearGradient>
				<filter
					id="e"
					x={1.721}
					y={1.115}
					width={17.397}
					height={17.396}
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity={0} result="BackgroundImageFix" />
					<feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
					<feColorMatrix
						in="SourceAlpha"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dx={0.435} dy={0.435} />
					<feGaussianBlur stdDeviation={0.217} />
					<feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
					<feColorMatrix values="0 0 0 0 0.6 0 0 0 0 0.356184 0 0 0 0 0.00749999 0 0 0 1 0" />
					<feBlend in2="shape" result="effect1_innerShadow_20020_46677" />
					<feColorMatrix
						in="SourceAlpha"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dx={-0.775} dy={-0.775} />
					<feGaussianBlur stdDeviation={0.217} />
					<feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
					<feColorMatrix values="0 0 0 0 0.633333 0 0 0 0 0.393722 0 0 0 0 0.0343056 0 0 0 1 0" />
					<feBlend in2="effect1_innerShadow_20020_46677" result="effect2_innerShadow_20020_46677" />
				</filter>
				<filter
					id="g"
					x={4.331}
					y={6.575}
					width={11.96}
					height={7.741}
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity={0} result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dy={0.652} />
					<feGaussianBlur stdDeviation={0.217} />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.65 0" />
					<feBlend in2="BackgroundImageFix" result="effect1_dropShadow_20020_46677" />
					<feBlend in="SourceGraphic" in2="effect1_dropShadow_20020_46677" result="shape" />
				</filter>
				<radialGradient
					id="f"
					cx={0}
					cy={0}
					r={1}
					gradientUnits="userSpaceOnUse"
					gradientTransform="rotate(90 .303 10.116) scale(8.26337)"
				>
					<stop stopColor="#F4BA14" />
					<stop offset={0.172} stopColor="#C57429" />
					<stop offset={1} stopColor="#F4BA14" />
				</radialGradient>
			</defs>
		</svg>
	);
}

export default React.memo(Coin);
