import { createSlice } from '@reduxjs/toolkit';
import {
	submitKharidBecheForm,
	submitRequirementForm,
	getSellProductsInitial,
	getSellProductsExtra,
	getBuyProductsInitial,
	getBuyProductsExtra,
	getSellProductDetail,
	userIdFetch,
	getFeedBackStatus
} from './thunk';

const initialState = {
	userId: null,
	formState: 1,
	formBody: null,
	formSubmitLoading: false,
	requirementFormLoading: false,
	isFeedBack: false,
	availableDeliveryRanges: [
		{ id: 1, text: 'डिलीवरी नहीं करवा सकते' },
		{ id: 2, text: 'सिर्फ़ लोकल में डिलीवरी करवा सकते' },
		{ id: 3, text: 'कूरियर / ट्रांसपोर्टर से शिप करवा देंगे' },
		{ id: 4, text: 'पूरे भारत में' },
	],

	sellProductOffset: 0,
	sellProductsLoading: false,
	sellProductsExtraLoading: false,
	sellProducts: [],

	buyProductOffset: 0,
	buyProductsLoading: false,
	buyProductsExtraLoading: false,
	buyProducts: [],

	sellProductDetailLoading: false,
	sellProductDetail: null,

	buyProductDetailLoading: false,
	buyProductDetail: null,

	tab: 0,
};

const kharidBetchSlice = createSlice({
	name: 'kharidBetch',
	initialState,
	reducers: {
		incrementFormState(state) {
			state.formState = state.formState + 1;
		},
		decrementFormState(state) {
			state.formState -= 1;
		},
		fillForm(state, { payload }) {
			state.formBody = { ...state.formBody, ...payload };
			state.formState = state.formState + 1;
		},
		removeState(state) {
			state.formState = 1;
		},
		changeTab(state, { payload }) {
			state.tab = payload;
		},
		removeDetail(state) {
			state.sellProductDetail = null;
		},
		setUserId(state, { payload }) {
			state.userId = payload;
		},
		removeFeedBack(state, { payload }) {
			const id = state?.sellProducts?.findIndex((item) => item?.id === payload)
			state.sellProducts[id].feedBack = true
		},
		toggleBottomsheet(state, { payload }) {
			state.isFeedBack = payload
		},
		updateUpvoteDownVoteProduct(state, { payload }) {
			console.log("payload", payload)
			console.log(state.sellProducts)
			// state.sellProducts[payload.keey][payload.type] = state.sellProducts[payload.keey][payload.type] + 1
		}
	},
	extraReducers: (builder) => {
		builder.addCase(submitKharidBecheForm.pending, (state, action) => {
			state.formSubmitLoading = true;
		});
		builder.addCase(submitKharidBecheForm.fulfilled, (state, action) => {
			state.formSubmitLoading = false;
			state.formState = state.formState + 1;
		});
		builder.addCase(submitKharidBecheForm.rejected, (state, action) => {
			state.formSubmitLoading = false;
		});

		builder.addCase(submitRequirementForm.pending, (state, action) => {
			state.requirementFormLoading = true;
		});
		builder.addCase(submitRequirementForm.fulfilled, (state, action) => {
			state.formState = state.formState + 1;
			state.requirementFormLoading = false;
		});
		builder.addCase(submitRequirementForm.rejected, (state, action) => {
			state.requirementFormLoading = false;
		});

		// get sell products
		builder.addCase(getSellProductsInitial.pending, (state, { payload }) => {
			state.sellProductsLoading = true;
		});
		builder.addCase(getSellProductsInitial.fulfilled, (state, { payload }) => {
			const newArray = payload?.map(item => {
				return { ...item, feedBack: false }
			})
			state.sellProducts = newArray;
			state.sellProductOffset += 15;
			state.sellProductsLoading = false;
		});
		builder.addCase(getSellProductsInitial.rejected, (state, { payload }) => {
			state.sellProductsLoading = false;
		});

		builder.addCase(getSellProductsExtra.pending, (state, { payload }) => {
			state.sellProductsExtraLoading = true;
		});
		builder.addCase(getSellProductsExtra.fulfilled, (state, { payload }) => {
			const newArray = payload.data?.map(item => {
				return { ...item, feedBack: false }
			})
			state.sellProducts = [...state.sellProducts, ...newArray];
			state.sellProductOffset = payload.offset;
			state.sellProductsExtraLoading = false;
		});
		builder.addCase(getSellProductsExtra.rejected, (state, { payload }) => {
			state.sellProductsExtraLoading = false;
		});

		// get buy products
		builder.addCase(getBuyProductsInitial.pending, (state, { payload }) => {
			state.buyProductsLoading = true;
		});
		builder.addCase(getBuyProductsInitial.fulfilled, (state, { payload }) => {
			state.buyProducts = payload;
			state.buyProductOffset += 15;
			state.buyProductsLoading = false;
		});
		builder.addCase(getBuyProductsInitial.rejected, (state, { payload }) => {
			state.buyProductsLoading = false;
		});

		builder.addCase(getBuyProductsExtra.pending, (state, { payload }) => {
			state.buyProductsExtraLoading = true;
		});
		builder.addCase(getBuyProductsExtra.fulfilled, (state, { payload }) => {
			state.buyProducts = [...state.buyProducts, ...payload.data];
			state.buyProductOffset = payload.offset;
			state.buyProductsExtraLoading = false;
		});
		builder.addCase(getBuyProductsExtra.rejected, (state, { payload }) => {
			state.buyProductsExtraLoading = false;
		});

		builder.addCase(getSellProductDetail.pending, (state, { payload }) => {
			state.sellProductDetailLoading = true;
		});
		builder.addCase(getSellProductDetail.fulfilled, (state, { payload }) => {
			state.sellProductDetail = payload[0] ? payload[0] : null;
			state.sellProductDetailLoading = false;
		});
		builder.addCase(getSellProductDetail.rejected, (state, { payload }) => {
			state.sellProductDetailLoading = false;
		});

		builder.addCase(userIdFetch.pending, (state, { payload }) => { });
		builder.addCase(userIdFetch.fulfilled, (state, { payload }) => {
			state.userId = payload;
		});
		builder.addCase(userIdFetch.rejected, (state, { payload }) => { });
		builder.addCase(getFeedBackStatus.pending, (state, { payload }) => { });
		builder.addCase(getFeedBackStatus.fulfilled, (state, { payload }) => {
			state.isFeedBack = payload?.data?.show_bottom_sheet;
		});
		builder.addCase(getFeedBackStatus.rejected, (state, { payload }) => { });
	},
});

export const {
	incrementFormState,
	decrementFormState,
	fillForm,
	removeState,
	changeTab,
	removeDetail,
	setUserId,
	toggleBottomsheet,
	removeFeedBack,
	updateUpvoteDownVoteProduct
} = kharidBetchSlice.actions;

export default kharidBetchSlice.reducer;
