const { v4 } = require('uuid');

const getStorageName = ({ folderName, userId }) => {
	const today = new Date();
	const uuid = v4();
	// we will concat the fileName on App side as we dont have access to the exact fileName on web
	return `${folderName}/${userId}/${String(today.toISOString().split('T')[0])}/${uuid}`;
};

export default getStorageName;
