import { createSlice } from "@reduxjs/toolkit";
import { CATEGORY } from "../../components/brandsPage/utils";

const initialState = {
  headers: [],
  selectedHeader: "",
  products: [],
  prices: null,
  productDetail: null,
  productDetailRecommendation: [],
  headerType: CATEGORY
};

export const brandSlice = createSlice({
  name: "brand",
  initialState,
  reducers: {
    setSelectedHeader(state, { payload: selectedHeader }) {
      state.selectedHeader = selectedHeader;
    },
    setProducts(state, { payload: { productList, headers, prices } }) {
      state.products = productList;
      state.headers = headers;
      state.prices = prices;
    },
    setProductDetailByFetch(state, { payload: product }) {
      if (!product) return;

      state.productDetail = { ...product, ...state.prices?.[product.id] };
    },
    setProductDetailRecommendationByFetch(
      state,
      { payload: productRecommendation }
    ) {
      if (!productRecommendation) return;

      state.productDetailRecommendation = productRecommendation;
    },
    setHeaderType(state, {payload}) {
      state.headerType = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setSelectedHeader,
  setProducts,
  setProductDetailByFetch,
  setProductDetailRecommendationByFetch,
  setHeaderType,
} = brandSlice.actions;

export default brandSlice.reducer;
