import React, { useEffect, useRef } from 'react'
import { BigPlayButton, LoadingSpinner, Player } from 'video-react';


const defaultAspectRatio = '4:3';

function VideoPlayer({media, location, thumbnail,aspectRatio=defaultAspectRatio}) {
  const playerRef=useRef();

  useEffect(() => {
    if(location.hash==='')
    playerRef?.current?.actions?.pause();

  }, [location.hash])
  
  return (
    <Player aspectRatio={aspectRatio} 
    poster={thumbnail} ref={playerRef} className='mb-4' src={media} preload='auto'>
      <LoadingSpinner />
    </Player>

  )
}

export default React.memo(VideoPlayer);