// let baseURL = "https://asia-south1-op-d2r.cloudfunctions.net/topProducts/";

const baseURL = {
	prod: 'https://asia-south1-op-d2r.cloudfunctions.net/',
	stage: 'https://asia-south1-kc-stage-rp.cloudfunctions.net/',
	uploadStage: 'https://d2r-dashboard-stage.azurewebsites.net/api/',
  	uploadProd: 'https://kc.retailpulse.ai/api/',
};

export default baseURL;
