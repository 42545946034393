import React from 'react';

function Download({ width = 24, height = 24, className, color = '#00AA35' }) {
	return (
		<svg width={width} height={height} className={className} viewBox="0 0 24 24" fill="none">
			<path fill={color} d="M19 9.5h-4v-6H9v6H5l7 7 7-7Zm-14 9v2h14v-2H5Z" />
		</svg>
	);
}

export default React.memo(Download);
