import React, { useCallback, useState } from 'react';
import '../product-card/Product.css';
import BottomSheetForSampark from './bottomSheetSampark';
import Slider from 'react-slick';
import { useMutation } from '@tanstack/react-query';
import { fetchFunction } from '../../hooks/useQueryHook';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { FaSearchLocation, FaLocationArrow } from 'react-icons/fa';
import { MdLocationPin } from 'react-icons/md';
import { navigateToScreen } from '../../utils/postMessage';
import { Card, CardBody, Row, Col, Badge, Button } from 'reactstrap';
import { getUserIDFromSessionStorage } from '../../utils/getUserID';
import './styles.css';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import message from '../../assests/Message.png';
import whatsappcall from '../../assests/whatsappCall.svg';
import CommentGif from '../../assests/CommentGif.gif';
import mixpanel from '../../utils/mixpanel';
import { FaPhoneVolume } from 'react-icons/fa6';
import { IoMdThumbsUp } from 'react-icons/io';
import { IoMdThumbsDown } from 'react-icons/io';
import { FaThumbsDown } from 'react-icons/fa';
import wp from '../../assests/wpshare.svg';

const userDefault = require('../../assests/userDefault.webp');
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const settings = {
	dots: true,
	infinite: true,
	speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1,
};

const RateCard2 = ({
	uid,
	keyy,
	content,
	distance,
	id,
	elementData,
	isBuy,
	onClickFeedback,
	setHeadline,
	feedBack,
	dynamicLink,
}) => {
	const navigate = useNavigate();

	const location = useLocation();
	const [bottomSheet, setBottomSheet] = useState();
	const [threeDotOptions, setThreeDotOptions] = useState(false);

	const toggleThreeDotOptions = (event) => {
		event.stopPropagation();
		setThreeDotOptions(true);
	};

	const { mutate } = useMutation({
		mutationFn: (data) => {
			let params = {
				post_id: id,
				user_id: uid,
			};
			fetchFunction('https://kc.retailpulse.ai/api/calledWholeSaler', params);
		},
		onError: (res) => {},
	});

	const reportPost = useCallback(() => {
		if (mixpanel?.get_distinct_id()) {
			mixpanel.track('KBC Reported', {
				content,
				post_id: id,
				posted_by_user_id: elementData?.user?.id,
			});
		}
		// window.webengage.track('KBC Reported', {
		// 	content,
		// 	id,
		// 	posted_by_user_id: elementData?.user?.id,
		// });
		toast.error('Reported');
		setThreeDotOptions(false);
	}, [content, elementData?.user?.id, id]);
	const ThreeDotComponent = useCallback(() => {
		return (
			threeDotOptions && (
				<Card
					onClick={reportPost}
					className="px-1 py-1"
					style={{ position: 'absolute', top: '20px', right: '30px', zIndex: 1 }}
				>
					⚠️ रिपोर्ट करें
				</Card>
			)
		);
	}, [reportPost, threeDotOptions]);

	const handleBottomSheet = useCallback(() => {
		if (mixpanel?.get_distinct_id()) {
			mixpanel.track('Clicked on Sampark Kare in KBC', {
				screen_name: elementData?.quantity ? 'KBC Requirements' : 'KBC Posts',
				post_id: id,
				post_type: elementData?.quantity ? 'KBC Requirement Post' : 'KBC Sell Post',
			});
		}
		// window.webengage.track('Clicked on Sampark Kare in KBC', {
		// 	screen_name: elementData?.quantity ? 'KBC Requirements' : 'KBC Posts',
		// 	post_id: id,
		// 	user_id: uid,
		// 	post_type: elementData?.quantity ? 'KBC Requirement Post' : 'KBC Sell Post',
		// });
		setBottomSheet((prev) => !prev);
	}, [elementData?.quantity, id, uid]);
	const handleOnPostClick = useCallback(() => {
		mixpanel.track('Clicked on Comment in Kharid bech');
		navigateToScreen('NewsComments', {
			newsId: id,
			title: content,
			index: 0,
			news_type: elementData?.quantity ? 'KBC Requirement Post' : 'KBC Sell Post',
			screen_name: 'KBC Post Details',
			suggestedCommentsForNews: [],
			isKeypadOpen: false,
			profile_id: elementData?.user?.id,
			widget_type: elementData?.quantity ? 'KBC Requirement Post' : 'KBC Sell Post',
			source: null,
			tag: 'KBC',
			post_type: 'kharid_bech',
		});
	}, [content, elementData?.quantity, elementData?.user?.id, id]);

	return (
		<div
			onClick={() => {
				setThreeDotOptions(false);
			}}
			key={keyy}
		>
			{elementData?.media_urls?.length > 0 && (
				<Card outline={false} color="dark" className="mx-3 mt-3 card1 shadow">
					<div>
						<ThreeDotComponent />
						<div>
							<BsThreeDotsVertical
								onClick={toggleThreeDotOptions}
								style={{
									color: 'white',
									position: 'absolute',
									width: '24px',
									height: '24px',
									top: '12px',
									right: '6px',
									zIndex: 0,
								}}
							/>
						</div>
					</div>
					<Row className="mx-2">
						<div>
							<Slider {...settings}>
								{elementData?.media_urls?.map((url, idx) => {
									if (url.includes('.mp4')) {
										return (
											<div key={idx}>
												<video className="center" controls>
													<source src={url} type="video/mp4" />
												</video>
											</div>
										);
									} else {
										return (
											<div
												onClick={() => {
													if (mixpanel?.get_distinct_id()) {
														mixpanel.track('Clicked on Post in KBC', {
															screen_name: elementData?.quantity ? 'KBC Requirements' : 'KBC Posts',
															post_id: id,
															post_type: elementData?.quantity
																? 'KBC Requirement Post'
																: 'KBC Sell Post',
														});
													}
													// window.webengage.track('Clicked on Post in KBC', {
													// 	screen_name: elementData?.quantity ? 'KBC Requirements' : 'KBC Posts',
													// 	post_id: id,
													// 	user_id: uid,
													// 	post_type: elementData?.quantity ? 'KBC Requirement Post' : 'KBC Sell Post',
													// });
													navigate(`/marketPlace2/detail/?postId=${id}&isBuy=${isBuy}`);
												}}
												key={idx}
											>
												<img className="center" src={url} alt="1" />
											</div>
										);
									}
								})}
							</Slider>
						</div>
					</Row>
				</Card>
			)}
			<Card
				outline={false}
				className={`mx-3 mb-3 ${
					elementData?.media_urls?.length > 0 ? 'card2 shadow' : 'card3 shadow'
				}`}
			>
				<div style={{ display: 'flex', whiteSpace: 'pre-line' }}>
					{(!elementData?.media_urls || elementData?.media_urls?.length === 0) && (
						<div>
							<ThreeDotComponent />
							<BsThreeDotsVertical
								onClick={toggleThreeDotOptions}
								style={{
									color: 'black',
									position: 'absolute',
									top: '12px',
									right: '6px',
									width: '24px',
									height: '24px',
									zIndex: 0,
								}}
							/>
						</div>
					)}
					<Row className="mx-3 mt-2">
						<div
							className="px-2 py-1"
							style={{
								display: 'flex',
								backgroundColor: '#FAFAFA',
								color: 'black',
								maxWidth: 'fit-content',
								borderRadius: '20px',
							}}
						>
							<div style={{ alignSelf: 'center' }}>
								<MdLocationPin />
							</div>
							<div style={{ alignSelf: 'center' }}>{`${distance} km`}</div>
						</div>
					</Row>
				</div>
				<Row className="mx-1">
					<Link
						onClick={() => {
							if (mixpanel?.get_distinct_id()) {
								mixpanel.track('Clicked on Post in KBC', {
									screen_name: elementData?.quantity ? 'KBC Requirements' : 'KBC Posts',
									post_id: id,
									post_type: elementData?.quantity ? 'KBC Requirement Post' : 'KBC Sell Post',
								});
							}
							// window.webengage.track('Clicked on Post in KBC', {
							// 	screen_name: elementData?.quantity ? 'KBC Requirements' : 'KBC Posts',
							// 	post_id: id,
							// 	user_id: uid,
							// 	post_type: elementData?.quantity ? 'KBC Requirement Post' : 'KBC Sell Post',
							// });
						}}
						style={{ textDecoration: 'none' }}
						key={location.pathname}
						to={`/marketPlace2/detail/?postId=${id}&isBuy=${isBuy}`}
					>
						<div className="my-3 description" style={{ whiteSpace: 'pre-line' }}>
							{elementData?.quantity ? (
								<li style={{ fontSize: 18, marginTop: 8 }}>
									<b>क्या खरीदना है?: </b>
									{`${elementData?.text}`}{' '}
								</li>
							) : content.length > 100 ? (
								`${content?.substring(0, 100)}.... `
							) : (
								content
							)}
							{elementData?.quantity && (
								<li style={{ fontSize: 18, marginTop: 8 }}>
									<b>मात्रा:</b> {`${elementData?.quantity} ${elementData?.param}`}
								</li>
							)}
							{/* {elementData?.otherInfo && (
								<li style={{ fontSize: 18, marginTop: 8 }}>
									<b>अतिरिक्त जानकारी:</b> {elementData?.otherInfo}
								</li>
							)} */}
							{content.length > 100 ? (
								<div
									className="my-1"
									style={{ color: '#0169BB', textDecoration: 'underline', textAlign: 'left' }}
								>
									{' '}
									पूरी जानकारी देखें
								</div>
							) : null}
						</div>
					</Link>
				</Row>
				{!feedBack && (
					<div style={{ display: 'flex', width: '100%', paddingLeft: 16, marginBottom: 10 }}>
						<div
							onClick={() => {
								mixpanel.track('Clicked on kam rate hai', {
									post_id: id,
									content,
								});
								setHeadline('आपको किस चीज़ की रेट कम लगी? ');
								onClickFeedback();
							}}
							style={{ borderWidth: 1, borderColor: '#00981A' }}
							className="feedBackBtn"
						>
							<IoMdThumbsUp color="#00981A" size={16} />
							<h4 style={{ color: '#00981A', fontSize: 12 }}>कम रेट है</h4>
						</div>
						<div
							onClick={() => {
								mixpanel.track('Clicked on jada rate hai', {
									post_id: id,
									content,
								});
								setHeadline('आपको किस चीज़ की रेट ज्यादा लगी?');
								onClickFeedback();
							}}
							style={{ borderWidth: 1, borderColor: '#A50000', marginLeft: 10 }}
							className="feedBackBtn"
						>
							<IoMdThumbsDown color="#A50000" size={16} />
							<h4 style={{ color: '#A50000', fontSize: 12 }}>ज्यादा रेट है</h4>
						</div>
					</div>
				)}
				<div
					style={{
						backgroundColor: '#FAFAFA',
						padding: 16,
						borderBottomRightRadius: 20,
						borderBottomLeftRadius: 20,
					}}
				>
					<div
						onClick={() => {
							if (mixpanel?.get_distinct_id()) {
								mixpanel.track('Clicked on Post from User Icon in KBC', {
									screen_name: elementData?.quantity ? 'KBC Requirements' : 'KBC Posts',
									post_id: id,
									post_type: elementData?.quantity ? 'KBC Requirement Post' : 'KBC Sell Post',
								});
							}
							// window.webengage.track('Clicked on Post User Icon in KBC', {
							// 	screen_name: elementData?.quantity ? 'KBC Requirements' : 'KBC Posts',
							// 	post_id: id,
							// 	user_id: uid,
							// 	post_type: elementData?.quantity ? 'KBC Requirement Post' : 'KBC Sell Post',
							// });
							navigate(`/marketPlace2/detail/?postId=${id}&isBuy=${isBuy}`);
						}}
						className="mt-1"
						style={{ display: 'flex', justifyContent: 'space-between' }}
					>
						<div style={{ flexB: 0.7, display: 'flex' }}>
							<img
								style={{ borderRadius: '100%', width: '40px', height: '40px' }}
								alt="profileImage"
								src={elementData?.user?.profile_image_url ?? userDefault}
							></img>
							<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
								<div className="mx-2 user-name">
									{elementData?.user?.store_name?.split('')?.length > 30
										? elementData?.user?.store_name.substring(0, 20) + '...'
										: elementData?.user?.store_name}
								</div>
								<p className="mx-2 user-city">
									{elementData?.user?.store_name?.split('')?.length > 30
										? elementData?.user?.name.substring(0, 20) + '...'
										: elementData?.user?.name}
								</p>
							</div>
						</div>
					</div>
					<div style={{ flexDirection: 'row' }}>
						<div
							style={{ display: 'flex', justifyContent: 'space-between' }}
							// onClick={handleOnPostClick}
						>
							<div
								onClick={() => {
									mixpanel.track('I am interested in kbc', {
										post_text: content,
										post_id: id,
									});
									toast.success('धन्यवाद। आपका इंट्रेस्ट नोट कर लिया गया है 😊', {
										toastId: "interested in kbc2"
									});
								}}
								style={{
									borderRadius: 8,
									borderWidth: 1,
									borderColor: '#D45339',
									width: '80%',
									padding: 15,
									height: 38,
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
								}}
							>
								<h3
									style={{
										color: '#D45339',
										fontSize: 16,
										margin: 0,
										textAlign: 'center',
										fontWeight: 'bold',
									}}
								>
									खरीदने में इच्छुक हु
								</h3>
							</div>
							<div
								onClick={() => {
									mixpanel.track('Clicked on Whatsapp in KBC', {
										post_text: content,
										post_id: id,
									});
									window.open(
										`https://api.whatsapp.com/send?phone=${
											elementData?.user?.phone_number
										}&text=${encodeURIComponent(
											`में आपसे प्रोडक्ट खरीदने में इच्छुक हूँ कृपया विस्तार में जानकारी दे 
${dynamicLink}`
										)}`
									);
								}}
							>
								<img src={wp} />
							</div>
						</div>
					</div>
				</div>
			</Card>
			<BottomSheetForSampark
				uid={uid}
				mixpanel={mixpanel}
				postID={id}
				bottomSheet={bottomSheet}
				type={elementData?.quantity ? 'sell' : 'buy'}
				handleBottomSheet={handleBottomSheet}
				contact={elementData?.user?.phone_number}
			/>
		</div>
	);
};

export default RateCard2;
