import React from "react";

function Edit({
  width = 14,
  height = 14,
  color = "#000",
  background = "#fff",
  className,
}) {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 14 14"
      fill="none"
    >
      <mask
        id="a"
        style={{
          maskType: "alpha",
        }}
        maskUnits="userSpaceOnUse"
        x={1}
        y={1}
        width={14}
        height={14}
      >
        <path
          d="M1.665 14.099h2.652l9.722-9.723-2.652-2.651-9.722 9.722V14.1Z"
          fill={background}
          stroke={background}
          strokeWidth={1.25}
          strokeLinejoin="round"
        />
        <path
          d="m8.736 4.376 2.651 2.652"
          stroke={background}
          strokeWidth={1.25}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </mask>
      <g mask="url(#a)">
        <path d="M0 .502h15v15H0v-15Z" fill={color} fillOpacity={0.5} />
      </g>
    </svg>
  );
}

export default React.memo(Edit);
