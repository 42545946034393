export const fetchTopUGCPosts = async (setData) => {
    try {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({
            "data": {
                "api_type": "topUGCPost"
            }
        });

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        const resp = await fetch("https://asia-south1-op-d2r.cloudfunctions.net/userPost", requestOptions);
        const response = await resp.json();
        setData(response?.result ?? []);
    } catch (e) {
        console.log('error fetching top posts = ', e)
    }
    return [];
}
