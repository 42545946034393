import React from 'react';

function TickCircle({
	width = 20,
	height = 20,
	className,
	color = '#fff',
	background = '#2C99FF',
}) {
	return (
		<svg width={width} height={height} className={className} viewBox="0 0 20 20" fill="none">
			<path
				fill={background}
				d="M9.798 2.438c-4.373 0-7.95 3.577-7.95 7.949s3.577 7.95 7.95 7.95c4.372 0 7.949-3.578 7.949-7.95s-3.577-7.95-7.95-7.95Zm-1.59 11.924-3.975-3.975 1.12-1.12 2.855 2.845 6.033-6.034 1.121 1.13-7.154 7.154Z"
			/>
			<path stroke={color} strokeWidth={1.987} d="m4.63 9.594 3.578 3.577 6.757-6.757" />
		</svg>
	);
}

export default React.memo(TickCircle);
