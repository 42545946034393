import React from "react";

const { PI } = Math;
const PercentageLoader = ({
  progress,
  strokeWidth = 8,
  size = 180,
  innerStrokeColor = "#FFF",
  strokeColor = "#2196F3",
}) => {
  const edgeDistance = Math.ceil(strokeWidth);
  const radius = (size - edgeDistance * 2) / 2;
  const circumference = 2 * PI * radius;
  return (
    <div
      style={{
        position: "relative",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      }}
    >
      <svg height={size} width={size}>
        <g origin={`${size / 2}, ${size / 2}`}>
          <circle
            cx={size / 2}
            cy={size / 2}
            r={radius}
            fill="none"
            strokeWidth={strokeWidth.toString()}
            stroke={innerStrokeColor}
            strokeDasharray={circumference}
            strokeLinecap="round"
          />
          <circle
            cx={size / 2}
            cy={size / 2}
            r={radius}
            fill="none"
            strokeWidth={strokeWidth.toString()}
            stroke={strokeColor}
            strokeDasharray={circumference}
            strokeDashoffset={circumference * (1 - progress / 100)}
            strokeLinecap="round"
          />
        </g>
      </svg>
    </div>
  );
};
export default PercentageLoader;
