import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiAxios } from '../../utils/axios';
import { studyDemoQuestions } from '../../constant/constant';
import { getUserId } from '../../utils/userData';

export const pngSurveyQuestionFetch = createAsyncThunk(
	'/pngQuestions',
	(payload, { dispatch, getState }) => {
		return new Promise(async (resolve, reject) => {
			try {
				const { survey_id, studyDemo } = payload;
				if (studyDemo) {
					resolve(studyDemoQuestions?.data);
				} else {
					const userId = getUserId();
					const res = await apiAxios.post(
						'https://darkarts.retailpulse.ai/api/v1/survey/questions',
						{
							user_id: userId,
							survey_id,
						}
					);
					resolve(res?.data?.data);
				}
			} catch (err) {
				reject(err);
			}
		});
	}
);

export const surveyQuestionFetch = createAsyncThunk(
	'/questions',
	(payload, { dispatch, getState }) => {
		return new Promise(async (resolve, reject) => {
			try {
				const { survey_id, node_id, response } = payload;
				const userId = getUserId();
				const res = await apiAxios.post(
					'https://darkarts.retailpulse.ai/api/v2/survey/question',
					{
						user_id: userId,
						survey_id: parseInt(survey_id),
						node_id: node_id ?? null,
						response: response ?? [],
					}
				);
				resolve(res?.data?.data);
			} catch (err) {
				reject(err);
			}
		});
	}
);


export const getSurveyMetaDetails = createAsyncThunk(
	'/getSurveyMetaDetails',
	(payload, { dispatch, getState }) => {
		return new Promise(async (resolve, reject) => {
			try {
				const { survey_id } = payload;
				const res = await apiAxios.get(
					'https://darkarts.retailpulse.ai/api/v1/survey/screens/'+survey_id,
				);
				resolve(res?.data?.data);
			} catch (err) {
				reject(err);
			}
		});
	}
);


export const submitpngQuestion = createAsyncThunk(
	'/submitPngQuestion',
	(payload, { dispatch, getState }) => {
		return new Promise(async (resolve, reject) => {
			try {
				const {
					question_id,
					response,
					survey_id,
					next_question_id,
					question_context,
					setImagesEmpty,
					survey_name,
					selected_options,
					studyDemo,
					node_id,
				} = payload;
				if (studyDemo) {
					resolve({ next_question_id, question_context, response, question_id });
				} else {
					const userId = getUserId();
					if (survey_id >= 6){
						const res = await apiAxios.post(
							'https://darkarts.retailpulse.ai/api/v2/survey/submit/response',
							{
								user_id: userId,
								question_id,
								response,
								survey_id,
								selected_options,
								survey_name,
								node_id
							}
						);
						resolve(res?.data?.data);
					} else{
						const res = await apiAxios.post(
							'https://darkarts.retailpulse.ai/api/v1/survey/submit/reponse',
							{
								user_id: userId,
								question_id,
								response,
								survey_id,
							}
						);
						resolve(res?.data?.data);
					}
				}
			} catch (err) {
				console.log(err);
				reject(err);
			}
		});
	}
);
