import React from 'react';
import Image from '../components/ui/Image';
import RippleButton from '../components/RippleButton';
import { toast } from 'react-toastify';

const personIcon = require('../assests/personIcon.png');

function UserDetailHeader({ profileImage, name, shopName, district, state }) {
	return (
		<div className="flex items-center mx-3 mb-8">
			<Image
				alt="user"
				src={profileImage ? profileImage : personIcon}
				className="w-11 h-11 rounded-full"
			/>
			<div className="flex-1 mx-3 justify-center">
				{name && <div className="text-sm font-bold ">{name}</div>}
				{shopName && <div className="text-xs">{shopName}</div>}
				{district || state ? (
					<div className="text-xs">
						{district}
						{state && `, ${state}`}
					</div>
				) : null}
			</div>
		</div>
	)
}

export default React.memo(UserDetailHeader);