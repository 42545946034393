const getUserId = () =>{
    return window.sessionStorage.getItem('data') ?? "jWjeLoO8C3NG9fmFm1icAq1c33E2";
}

const getUserMode = () =>{
    return window.sessionStorage.getItem('internal');
}


export {
    getUserId,
    getUserMode
}
