import { WEBVIEW_EVENTS, RN_TO_WEBVIEW_EVENTS } from '../constant/constant';

export function sendPostMessage(type, data) {
	window.ReactNativeWebView?.postMessage(JSON.stringify({ type, ...data }));
}

export function navigateToScreen(name, params) {
	sendPostMessage(WEBVIEW_EVENTS.NAVIGATION, {
		nav: {
			name,
			params,
		},
	});
}

export function openMediaUploader(data) {
	sendPostMessage(WEBVIEW_EVENTS.OPEN_GALLERY, {
		data
	});
}

export function getCameraPermission() {
	sendPostMessage(WEBVIEW_EVENTS.GET_CAMERA_PERMISSION);
}
