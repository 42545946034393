import React from 'react';
import primaryBtn from '../../../assests/quizBtn.svg';
import secondaryBtn from '../../../assests/quizBtnSecondary.svg';
import primaryOutlineBtn from '../../../assests/quizBtnOutline.svg';
import secondaryOutlineBtn from '../../../assests/quizBtnSecondaryOutline.svg';
import './styles.css';

function CustomQuizButton({
	type,
	text,
	icon = null,
	onPress,
	color = 'white',
	width = '100%',
	height = '100%',
	margin
}) {
	let backgroundColor;
	if (type === 'primary') {
		backgroundColor = "#021438";
	} else if (type === 'secondary') {
		backgroundColor ="#D69F03" ;
	} else if (type === 'primaryOutline') {
		backgroundColor = primaryOutlineBtn;
	} else if (type === 'secondaryOutline') {
		backgroundColor = secondaryOutlineBtn;
	}
	return (
		<div style={{ backgroundColor,margin }} className="Quizbtn" onClick={onPress}>
			<h4 style={{ color: 'white', textAlign: 'center', marginRight: icon ? 10 : 0 }}>{text}</h4>
			{icon && icon}
		</div>
	);
}

export default CustomQuizButton;
