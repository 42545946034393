import React from "react";

function CrossCircle({ width = 16, height = 17, color = "#6D6C6C", background= "#fff", className }) {
  return (
    <svg width={width} height={height} className={className} viewBox="0 0 16 17" fill="none">
      <path
        d="M8 14.75a6.25 6.25 0 1 0 0-12.5 6.25 6.25 0 0 0 0 12.5Z"
        fill={color}
        stroke={color}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m10.25 6.25-4.5 4.5 4.5-4.5Zm-4.5 0 4.5 4.5-4.5-4.5Z"
        fill={color}
      />
      <path
        d="m10.25 6.25-4.5 4.5m0-4.5 4.5 4.5"
        stroke={background}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default React.memo(CrossCircle);
