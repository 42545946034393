import React, { useEffect, useState } from 'react';
import CustomQuizButton from '../quizCustomBtn';
import { useNavigate } from 'react-router-dom';
import Poonji from '../../../assests/poonji.svg';
import Cup from '../../../assests/Cup.svg';
import { BsFillShareFill } from 'react-icons/bs';
import { AiOutlineArrowRight } from 'react-icons/ai';
import mixpanel from '../../../utils/mixpanel';
import { useDispatch, useSelector } from 'react-redux';
import {
	noOfCorrectQuestionSelector,
	quizQuestionSelector,
	sessionPointsSelector,
	zoneSelector,
} from '../../../redux/quiz/selector';
import { getLeaderBoard } from '../../../redux/quiz/thunk';
import { navigateToScreen } from '../../../utils/postMessage';
import { dynamicLinkSelector, dynamicLinkLoadingSelector } from '../../../redux/quiz/selector';
import { getDynamicLink } from '../../../redux/quiz/thunk';
import { CircularProgress } from '@mui/material';
import '../styles.css';
import { persistor } from '../../../redux/store';
import { emptyQuizData } from '../../../redux/quiz/slice';

function toDataURL(url, callback) {
	var xhr = new XMLHttpRequest();
	xhr.onload = function () {
		var reader = new FileReader();
		reader.onloadend = function () {
			callback(reader.result);
			return reader.result;
		};
		reader.readAsDataURL(xhr.response);
	};
	xhr.open('GET', url);
	xhr.responseType = 'blob';
	xhr.send();
}

function QuizCompleteScreen() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const correct_ans = useSelector(noOfCorrectQuestionSelector);
	const total_ques = useSelector(quizQuestionSelector);
	const zone = useSelector(zoneSelector);
	const sessionPoints = useSelector(sessionPointsSelector);
	const dynamicLink = useSelector(dynamicLinkSelector);
	const dynamicLinkLoading = useSelector(dynamicLinkLoadingSelector);
	const [dataFetching, setDataFetching] = useState(false);

	useEffect(() => {
		if (dynamicLink && !dynamicLinkLoading) {
			// Fetching data...
			toDataURL(
				'https://d2rstorage1.blob.core.windows.net/widget/October/16/cc91d414-cc14-4500-b6d7-edc68d7031e9/Frame%206112492%20(1).webp',
				function (dataUrl) {
					// Data fetching completed
					setDataFetching(false);

					const shareOptions = {
						url: dataUrl,
						title: 'Clicked on Share IKC Leaderboard',
						message: `साथियों, मैंने **किराना कप** में खेला क्विज़, और जीते ${sessionPoints} पॉइंट्स! 🎉📜✨
						
आप भी **किराना क्लब ऐप** पर "किराना कप" प्रतियोगिता में खेलकर जीतें हर सप्ताह कैश इनाम! 🏆💰🎁

सिर्फ किराना दुकानदारों के लिए, ज़रूर जुड़ें!
*अभी खेलें* 👉 : ${dynamicLink}
				\n`,
					};

					navigateToScreen('SharePopupComponent', {
						onShare: null,
						newsId: null,
						title: '',
						navObj: null,
						mode: 'New',
						media:
							'https://d2rstorage.blob.core.windows.net/widget/September/13/97660cf7-fc9d-4524-baae-7012bb96f5a5/Share.webp',
						shareOptions,
						mixpanelEventName: 'Clicked on Share IKC Leaderboard',
						mixpanelEventProps: null,
						webengageEventName: 'Clicked on Share IKC Leaderboard',
						webengageEventProps: null,
					});
				}
			);
		}
	}, [dynamicLink, dynamicLinkLoading]);

	const onClickShare = () => {
		setDataFetching(true); // Set dataFetching to true before starting data fetching

		dispatch(
			getDynamicLink({
				screenName: 'NewsScreen',
				nav_type: 'Redirect to Screen',
			})
		);
		mixpanel.track('Clicked on Share IKC Quiz Results');
	};

	useEffect(() => {
		window.addEventListener('popstate', (event) => {
			dispatch(emptyQuizData());
			navigateToScreen('NewsScreen');
		});

		return () => {
			window.removeEventListener('popstate', (event) => {});
		};
	}, [dispatch]);

	useEffect(() => {
		dispatch(getLeaderBoard('my'));
		mixpanel.track('ScreenView', { screen_name: 'IKC Quiz Result'});
		window.webengage.track('ScreenView', { screen_name: 'IKC Quiz Result'});
		// mixpanel.track('IKL Quiz Submitted', {
		// 	points_won: totalPoints,
		// 	correct_ans,
		// 	total_ques: total_ques?.length,
		// 	zone,
		// });
		// window.webengage.track('IKL Quiz Submitted', {
		// 	points_won: sessionPoints,
		// 	correct_ans,
		// 	total_ques: total_ques?.length,
		// 	zone,
		// });
	}, [correct_ans, dispatch, sessionPoints, total_ques?.length, zone]);
	useEffect(() => {
		dispatch(emptyQuizData());
		persistor.purge();
	}, [dispatch]);
	return (
		<div className="completed">
			<div className="completedCard">
				<h3 style={{marginBottom:"6%"}}>आपका इस क्विज का स्कोर </h3>
				<div className="score">
					<h4 style={{ fontSize: 20 }}>SCORE</h4>
					<div style={{ borderWidth: 0, }} className="headerPoints">
						<h2 style={{ fontSize: 28 }}>{sessionPoints}</h2>
						<img style={{ height: 24, aspectRatio: 1 }} src={Poonji} />
					</div>
				</div>
				<img style={{ height: 300, width: 300, marginTop: 10 }} src={Cup} />
			</div>
			{/* <div className="upperDiv">
        <div className="clapDiv">
            <img src={Clap} />
            <h4>बहुत खूब </h4>
        </div>
        <div style={{ marginTop: '18%' }}>
            <h4 style={{ color: '#9F9F9F', fontSize: 20, textAlign: 'center', margin: 0 }}>
                आपका स्कोर
            </h4>
            <div className="result">
                <h4>
                    {`${noOfCorrectQuestions} / ${questions?.length}`}{' '}
                    <span style={{ fontWeight: 400 }}>सही जवाब</span>
                </h4>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img width={28} height={28} src={coin} />
                    <h4 style={{ marginLeft: 10 }}>{100}</h4>
                </div>
            </div>
        </div>
        <img
            src={Cup}
            style={{
                position: 'relative',
                height: 245,
                aspectRatio: 1,
                bottom: '-10%',
                marginLeft: 'auto',
                marginRight: 'auto',
            }}
        />
    </div> */}
			<div style={{ marginTop: 10,width:"100%" }}>
				<CustomQuizButton
					onPress={() => {
						mixpanel.track('Clicked on Check Leaderboard button on Quiz Results');

						localStorage.setItem('userBack', 0);
						navigate('/leaderBoard');
					}}
					icon={<AiOutlineArrowRight size={24} color="white" />}
					text={'लीडरबोर्ड देखें'}
					type={'secondary'}
				/>
				<div style={{width:"100%",margin:20}}></div>
				{!dynamicLinkLoading ? (
					<CustomQuizButton
						onPress={onClickShare}
						icon={<BsFillShareFill size={14} color="white" />}
						color="#D7A004"
						text={'शेयर करें'}
						type={'primaryOutline'}
						disabled={dataFetching}
					/>
				) : (
					<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
						<CircularProgress size={24} />
					</div>
				)}
			</div>
		</div>
	);
}

export default QuizCompleteScreen;
