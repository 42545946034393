import React from 'react';
import { Col, Button } from 'reactstrap';

function CustomButton({
	isDisabled,
	title,
	onPress,
	backgroundColor = '#B92B0F',
	disableColor = '#D9D9D9',
	fontSize = 16,
	fontWeight = 400,
	height = 44,
	style,
}) {
	return (
		<div
			onClick={onPress}
			style={{
				display: 'flex',
				justifyContent: 'center',
				cursor: isDisabled ? 'pointer' : 'default',
			}}
		>
			<Button
				type="submit"
				style={{
					color: 'white',
					backgroundColor: !isDisabled ? backgroundColor : disableColor,
					borderColor: !isDisabled ? backgroundColor : disableColor,
					width: '100%',
					marginTop: 10,
					height,
					fontSize,
					fontWeight,
					...style,
				}}
			>
				{title}
			</Button>
		</div>
	);
}

export default CustomButton;
