import axios from "axios";

import baseURL from "../shared/baseUrl";
import { getUserId } from "./userData";

const baseAxios = async ({ endPoint, data, baseUrl= baseURL.prod}) => {
  try {
    const body = {
      data: {
        env: "prod",
        selfUserId: getUserId(),
        uid: getUserId(),
        ...data,
      },
    };
    const finalUrl=`${baseUrl}${endPoint}`;
    const res = await axios.post(finalUrl, body, {
      headers: {
        "Content-Type": "application/json",
        "User-Agent":
          "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/59.0.3071.115 Safari/537.36",
        "Accept-Encoding": "gzip",
      },
    });

    if (res.data?.result?.status === "ERR")
      return Promise.reject(res.data?.result?.data?.code);
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export default baseAxios;
