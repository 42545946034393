import React, { useCallback, useEffect, useRef, useState } from 'react';
import Sheet from 'react-modal-sheet';
import { RxCross2 } from 'react-icons/rx';
import { CircularProgress, TextField } from '@mui/material';
import { Button } from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup'
import { useMutation } from '@tanstack/react-query';
import baseAxios from '../../utils/baseAxios';
import { getUserId } from '../../utils/userData';
import CustomButton from '../Button';
import { fetchFunction } from '../../hooks/useQueryHook';
import { toast } from 'react-toastify';
import mixpanel from '../../utils/mixpanel';
import { useDispatch } from 'react-redux';
import { removeFeedBack } from '../../redux/kharidBetch/slice';

const userDefault = require('../../assests/userDefault.webp');
const wp = require('../../assests/wp.png');
const phone = require('../../assests/phone.png');
const copy = require('../../assests/copy image.png');
const { Row, Col } = require('reactstrap');

const DEFAULT_SPRING = {
    stiffness: 500,
    damping: 10,
    mass: 0.01,
};

const validationSchema = Yup.object().shape({
    feedBack: Yup.string()
        .trim()
});

function RateFeedback({
    uid,
    bottomSheet,
    setOpen,
    handleBottomSheet,
    type,
    postID,
    content,
    contact,
    detail,
    headline,
    id
}) {
    const ref = useRef();
    const dispatch = useDispatch()
    const handleBackPropogation = useCallback(
        (evt) => {
            evt.stopPropagation();
            handleBottomSheet();
        },
        [handleBottomSheet]
    );

    const formik = useFormik({
        initialValues: {
            whatsappNumber: '',
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            const obj = { ...values };
            formik.resetForm()
            dispatch(removeFeedBack(id))
            mixpanel.track('Rate feedback submitted')
            toast.success('Thank you for the feedback!')
            setOpen(false)
        },
    });
    return (
        <>
            <Sheet
                springConfig={DEFAULT_SPRING}
                snapPoints={[0.6]}
                ref={ref}
                isOpen={bottomSheet}
                onClose={() => setOpen(false)}
                style={{
                    zIndex: bottomSheet ? 1000 : -100,
                }}
            >
                <Sheet.Backdrop
                    onClick={handleBackPropogation}
                    style={{ backgroundColor: 'rgba(0,0,0,0.4)' }}
                />
                <Sheet.Container style={{ backgroundColor: 'rgba(0,0,0,0.4)' }}>
                    <div style={{ flex: 1, display: "flex", width: "100%", backgroundColor: "white", borderTopLeftRadius: 20, borderTopRightRadius: 20, flexDirection: "column", padding: 33 }}>
                        <div onClick={() => setOpen(false)} style={{ display: 'flex', justifyContent: 'center', alignItems: "center", width: 22, height: 22, borderRadius: 11, backgroundColor: "black", position: "absolute", top: -30, right: 10 }}>
                            <RxCross2 size={20} color='white' />
                        </div>
                        <div>
                            <div style={{ height: 130, width: "100%", backgroundColor: "#EEEEEE", borderRadius: 10, padding: 10 }}>
                                <h4 className='content' style={{ margin: 0, fontSize: 18 }}>{content?.length > 100 ? content?.substr(0, 100) + "..." : content}</h4>
                            </div>
                            <h5 style={{ fontSize: 18, fontWeight: 500, marginTop: 15 }}>{headline}</h5>
                            <TextField
                                className="my-2"
                                fullWidth
                                id="feedBack"
                                name="feedBack"
                                type="text"
                                rows={4}
                                style={{ fontSize: 14 }}
                                placeholder="यहाँ लिखे"
                                multiline={true}
                                onBlur={formik.handleBlur}
                                value={formik.values.feedBack}
                                onChange={formik.handleChange}
                                error={formik.touched.feedBack && Boolean(formik.errors.feedBack)}
                                helperText={formik.touched.feedBack && formik.errors.feedBack}
                            />
                            <div style={{ width: '100%' }}>
                                <CustomButton
                                    onPress={
                                        formik.handleSubmit
                                    }
                                    isDisabled={false}
                                    title={'जमा करें'}
                                />
                            </div>

                        </div>

                    </div>
                </Sheet.Container>
            </Sheet>
        </>
    );
}

export default RateFeedback;
