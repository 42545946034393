import React from 'react';

function Alert({ width = 29, height = 29, color = '#fff', background = '#000', className }) {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 29 29"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle cx="14.5" cy="14.5" r="14.5" fill="#FFE4E4" />
			<circle cx="14.4983" cy="14.5003" r="12.5667" fill="#FFBBBB" />
			<circle cx="14.4966" cy="14.3951" r="10.6333" fill="#FF0000" />
			<path
				d="M13.746 19.1084H15.6631V20.9023H13.746V19.1084ZM13.746 12.548V8.23891H15.6631V12.548L15.3817 17.8772H14.0274L13.746 12.548Z"
				fill="white"
			/>
		</svg>
	);
}

export default React.memo(Alert);
