import React from 'react';

function Cross({ width = 24, height = 24, color = '#fff', className }) {
	return (
		<svg width={width} height={height} className={className} viewBox="0 0 24 24" fill="none">
			<path d="M20 20 4 4m16 0L4 20" stroke={color} strokeWidth={2} strokeLinecap="round" />
		</svg>
	);
}

export default React.memo(Cross);
