import React, { useState } from 'react';

function TextInput({
	editorContent,
	setEditorContent,
	placeholder,
	setFocussedTextArea = () => { },
	specific,
	handlePaste,
	containerStyle = null,
	textareaContainerStyle = null,
}) {
	const [showInput, setShowInput] = useState(false);

	return showInput ? (
		<textarea
			rows="7"
			autoFocus
			onFocus={() => {
				setFocussedTextArea(true);
			}}
			className={
				textareaContainerStyle
					? textareaContainerStyle
					: `mb-4 bg-[#F5F5F5] 
                  rounded-xl 
                  h-48 text-base
				  outline-none
				  border-[1px] border-[#2C99FF]
                  py-1 px-2 w-[90%] 
				  self-center`
			}
			value={editorContent}
			onChange={(e) => {
				e.preventDefault();
				setEditorContent(e.target.value);
			}}
			onPaste={handlePaste}
		/>
	) : (
		<div
			onClick={() => {
				setShowInput(true);
			}}
			className={
				containerStyle
					? containerStyle
					: `
                  flex bg-[#F5F5F5] border-[1px] 
                 border-[rgba(0,0,0,0.2)] border-solid text-[1.5rem] h-48 
                 text-[rgba(0,0,0,0.4)] text-center mx-3 rounded-xl justify-center
                  items-center mb-8`
			}
		>
			{specific === 'mandi' ? (
				<div style={{ fontSize: 16 }}>
					मंडी भाव बातये (प्रति आइटम 10 पूंजी जीतें)
					{/* <ul style={{ listStyleType: 'initial' }}>
						<li>मात्रा के बिना रेट अप्रूव नहीं होगी</li>
						<li>प्रति आइटम 10 पूंजी जीतें</li>
					</ul> */}
				</div>
			) : specific === 'scheme' ? (
				<div style={{ fontSize: 16 }}>
					मंच चॉकलेट 5 rs वाली के 100 चॉकलेट के जार पर मिल रही है 5 चॉकलेट एक्स्ट्रा
				</div>
			) : (
				<div dangerouslySetInnerHTML={{ __html: placeholder }} />
			)}
		</div>
	);
}

export default React.memo(TextInput);
