import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { apiAxios } from '../../utils/axios';
import baseURL from '../../shared/baseUrl';

export const quizQuestionFetch = createAsyncThunk('/quiz', (payload, { dispatch, getState }) => {
	return new Promise(async (resolve, reject) => {
		try {
			const {
				quiz: { quizId },
			} = getState();
			const userId = await window.sessionStorage.getItem('data');
			const res = await apiAxios.post('api/v1/quiz/questions', {
				user_id: userId ?? '4LxZmTlDV4cegawViw8cQtaadpF2',
				quiz_id: quizId,
			});
			if (res?.data?.data?.questions.length > 0) resolve(res?.data?.data?.questions);
		} catch (err) {
			reject(err);
		}
	});
});

export const getDynamicLink = createAsyncThunk(
	'/dynamicLink',
	(payload, { dispatch, getState }) => {
		return new Promise(async (resolve, reject) => {
			try {
				const { screenName, nav_type, subScreen } = payload;
				const userId = await window.sessionStorage.getItem('data');
				const res = await axios.post(`${baseURL.uploadProd}createDynamicLink`, {
					navObj: {
						name: screenName,
						nav_type: nav_type,
						params: {
							// screenName: subScreen,
							acquisition_referrer_id: userId ?? '4LxZmTlDV4cegawViw8cQtaadpF2',
						},
					},
				});
				resolve(res?.data);
			} catch (err) {
				reject(err);
			}
		});
	}
);

export const quizQuestionSubmit = createAsyncThunk(
	'/quizSubmit',
	(payload, { dispatch, getState }) => {
		return new Promise(async (resolve, reject) => {
			try {
				const userId = await window.sessionStorage.getItem('data');
				const { question_id, answer, zone, index } = payload;
				const {
					quiz: { quizId },
				} = getState();
				const res = await apiAxios.post('api/v1/quiz/submit/answer', {
					user_id: userId ?? '4LxZmTlDV4cegawViw8cQtaadpF2',
					question_id,
					answer,
					zone,
					index,
					quiz_id: 1,
				});
				resolve(res);
			} catch (err) {
				reject(err);
			}
		});
	}
);

export const getLeaderBoard = createAsyncThunk(
	'/getLeaderBoard',
	(payload, { dispatch, getState }) => {
		return new Promise(async (resolve, reject) => {
			try {
				const userId = await window.sessionStorage.getItem('data');
				const {
					quiz: { quizId },
				} = getState();
				const { quizIdfromParam } = payload;
				const data = {
					user_id: userId ?? '4LxZmTlDV4cegawViw8cQtaadpF2',
					is_weekly: true,
					quiz_id: 1,
				};

				const res = await apiAxios.post('/api/v1/quiz/leaderboard', data);
				resolve(res?.data?.data);
			} catch (err) {
				reject(err);
			}
		});
	}
);

export const getZoneLeaderBoard = createAsyncThunk(
	'/getZoneLeaderBoard',
	(payload, { dispatch, getState }) => {
		return new Promise(async (resolve, reject) => {
			try {
				const userId = await window.sessionStorage.getItem('data');
				const {
					quiz: { quizId },
				} = getState();
				const { quizIdfromParam } = payload;
				const data = {
					user_id: userId ?? '4LxZmTlDV4cegawViw8cQtaadpF2',
					quiz_id: 1,
					is_weekly: false,
				};
				const res = await apiAxios.post('/api/v1/quiz/leaderboard', data);
				resolve(res?.data?.data);
			} catch (err) {
				reject(err);
			}
		});
	}
);
