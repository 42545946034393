import React, { createContext, useContext, useState } from 'react'

const UserContext = createContext();
const useUserContext = () => useContext(UserContext);

const UserContextProvider = ({children}) => {

    const [userDetails, setUserDetails] = useState(null)

    const userDetailsFunc = (uid) => {
        setUserDetails({
          userId : uid
        })
  }

    return (
        <UserContext.Provider value={{userDetails, setUserDetails, userDetailsFunc}}>
            {children}
        </UserContext.Provider>
    )
}

export {useUserContext, UserContextProvider}
