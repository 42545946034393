import React from 'react';

function DoubleArrowDown({ width = 26, height = 26, color = '#494949', className }) {
	return (
		<svg width={width} height={height} className={className} viewBox="0 0 26 26" fill="none">
			<path
				d="M13.001 13.597c-.138 0-.268-.022-.389-.065a.898.898 0 0 1-.337-.22L7.505 8.54c-.19-.19-.29-.428-.299-.714a.96.96 0 0 1 .299-.738.983.983 0 0 1 .726-.285c.294 0 .536.095.726.285L13 11.108l4.045-4.019c.19-.19.428-.29.713-.298a.966.966 0 0 1 .739.298c.19.19.285.432.285.726a.983.983 0 0 1-.285.726l-4.77 4.77a.914.914 0 0 1-.338.221c-.12.044-.25.065-.389.065Zm0 6.222c-.138 0-.268-.022-.389-.064a.9.9 0 0 1-.337-.221l-4.77-4.77c-.19-.19-.29-.428-.299-.713a.965.965 0 0 1 .299-.74.983.983 0 0 1 .726-.285c.294 0 .536.095.726.285L13 17.33l4.045-4.019c.19-.19.428-.29.713-.298a.96.96 0 0 1 .739.298c.19.19.285.432.285.726a.983.983 0 0 1-.285.726l-4.77 4.77a.916.916 0 0 1-.338.221c-.12.044-.25.065-.389.065Z"
				fill={color}
			/>
		</svg>
	);
}

export default React.memo(DoubleArrowDown);
