import React from 'react';

function Bulb({ width = 44, height = 44, color = '#fff', background = '#000', className }) {
	return (
		<svg width={width} height={height} className={className} viewBox="0 0 26 26" fill="none">
			<g clip-path="url(#clip0_23000_52677)">
				<path
					d="M13 19.8984C16.8142 19.8984 19.9062 16.8064 19.9062 12.9922C19.9062 9.17797 16.8142 6.08594 13 6.08594C9.18578 6.08594 6.09375 9.17797 6.09375 12.9922C6.09375 16.8064 9.18578 19.8984 13 19.8984Z"
					stroke="#25B39E"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M12.9993 4.0534V1.6159M11.8504 2.5129L12.9993 1.36328L14.1482 2.5129M21.9295 12.9837H24.367M23.47 11.8347L24.6197 12.9837L23.47 14.1325M4.0691 12.9837H1.6316M2.52778 11.8347L1.37891 12.9837L2.52778 14.1325M19.314 6.66883L21.0373 4.94554M19.5911 4.76682H21.2161V6.39182M6.68456 19.2984L4.96124 21.0217M4.78249 19.5754V21.2004H6.40749M19.314 19.2984L21.0373 21.0217M21.2161 19.5754V21.2004H19.5911M6.68456 6.66883L4.96124 4.94554M6.40749 4.76682H4.78249V6.39182"
					stroke="#25B39E"
				/>
				<path
					d="M13.082 7.29767C11.5736 7.29767 10.127 7.89692 9.06036 8.96353C7.99375 10.0301 7.39453 11.4767 7.39453 12.9852C7.39568 13.1479 7.40381 13.3106 7.41891 13.4726C7.66428 17.1525 10.6193 18.0137 10.6193 20.374H11.2669C11.091 20.4139 10.9337 20.512 10.8207 20.6526C10.7077 20.7932 10.6456 20.968 10.6445 21.1484V24.3984C10.6445 24.8485 11.0069 25.2109 11.457 25.2109H14.707C15.1572 25.2109 15.5195 24.8485 15.5195 24.3984V21.1484C15.5185 20.968 15.4564 20.7932 15.3434 20.6526C15.2304 20.512 15.0731 20.4139 14.8972 20.374H15.5447C15.5447 18.0308 18.4575 17.1646 18.7411 13.5531C18.7601 13.3641 18.7696 13.1743 18.7695 12.9844C18.7695 11.476 18.1703 10.0294 17.1037 8.96274C16.0371 7.89612 14.5904 7.29767 13.082 7.29767Z"
					fill="#FCD062"
				/>
				<path
					d="M10.6445 20.3711V24.3954C10.6445 24.8455 11.0069 25.2079 11.457 25.2079H14.707C15.1572 25.2079 15.5195 24.8455 15.5195 24.3954V20.3711H10.6445Z"
					fill="#576D7E"
				/>
				<path
					d="M10.2441 20.7422H15.9186M10.2441 22.3672H15.9186M10.2441 23.9922H15.9186"
					stroke="#2B4255"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
			</g>
			<defs>
				<clipPath id="clip0_23000_52677">
					<rect width="26" height="26" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
}

export default React.memo(Bulb);
