import { createAsyncThunk } from '@reduxjs/toolkit';
import baseAxios from '../../utils/baseAxios';
import mixpanel from '../../utils/mixpanel';
import { getUserIDFromSessionStorage } from '../../utils/getUserID';

const fireMixpanelEvents = async (isBuy, userId) => {
	mixpanel.identify(userId);
	mixpanel.track('Post Not Loaded in KBC', {
		screen_name: isBuy ? 'KBC Requirements' : 'KBC Posts',
		post_type: isBuy ? 'KBC Requirement Post' : 'KBC Sell Post',
	});
	window.webengage.track('Post Not Loaded in KBC', {
		screen_name: isBuy ? 'KBC Requirements' : 'KBC Posts',
		post_type: isBuy ? 'KBC Requirement Post' : 'KBC Sell Post',
	});
};

export const getSellProductsInitial = createAsyncThunk(
	'/getSellProducts',
	(payload, { dispatch, getState }) => {
		return new Promise(async (resolve, reject) => {
			try {
				const userId = await getUserIDFromSessionStorage();
				const myHeaders = new Headers();
				myHeaders.append('Content-Type', 'application/json');

				const raw = JSON.stringify({
					uid: userId ?? 'wMn4a578ChXPCC34Vl2Qn7KZFHV2',
					limit: 15,
					offset: 0,
				});
				let resp;
				const requestOptions = {
					method: 'POST',
					headers: myHeaders,
					body: raw,
					redirect: 'follow',
				};
				resp = await fetch('https://kc.retailpulse.ai/api/getSellProducts', requestOptions);

				const data = await resp.json();
				if (data?.result?.rates?.length === 0) {
					fireMixpanelEvents(false, userId);
				}
				resolve(data?.result?.rates);
			} catch (err) {
				console.log('Market Place Webview Crashing', {
					end: '/getSellProducts',
					err: JSON.stringify(err),
				})
				mixpanel.track('Market Place Webview Crashing', {
					end: '/getSellProducts',
					err: JSON.stringify(err),
				});
				reject(err);
			}
		});
	}
);

export const getFeedBackStatus = createAsyncThunk(
	'/getFeedback',
	(payload, { dispatch, getState }) => {
		return new Promise(async (resolve, reject) => {
			try {
				const userId = await getUserIDFromSessionStorage();
				const myHeaders = new Headers();
				myHeaders.append('Content-Type', 'application/json');
				const { post_id } = payload
				const raw = JSON.stringify({
					user_id: userId ?? 'wMn4a578ChXPCC34Vl2Qn7KZFHV2',
					post_id,
				});
				let resp;
				const requestOptions = {
					method: 'POST',
					headers: myHeaders,
					body: raw,
					redirect: 'follow',
				};
				resp = await fetch('https://kc.retailpulse.ai/api/shouldShowReviewBottomSheet', requestOptions);

				const data = await resp.json();
				resolve(data);
			} catch (err) {
				reject(err);
			}
		});
	}
);


export const getSellProductsExtra = createAsyncThunk(
	'/getSellProductsExtra',
	(payload, { dispatch, getState }) => {
		return new Promise(async (resolve, reject) => {
			try {
				const userId = await getUserIDFromSessionStorage();
				const sellProductOffset = getState()?.kharidBetch?.sellProductOffset;
				const myHeaders = new Headers();
				myHeaders.append('Content-Type', 'application/json');

				const raw = JSON.stringify({
					uid: userId ?? 'wMn4a578ChXPCC34Vl2Qn7KZFHV2',
					limit: 15,
					offset: sellProductOffset,
				});
				let resp;
				const requestOptions = {
					method: 'POST',
					headers: myHeaders,
					body: raw,
					redirect: 'follow',
				};
				resp = await fetch('https://kc.retailpulse.ai/api/getSellProducts', requestOptions);
				const data = await resp.json();
				mixpanel.track('Market place getSellProductsExtra api response', {
					status: resp.status,
				});
				resolve({ data: data?.result?.rates, offset: sellProductOffset + 15 });
			} catch (err) {
				console.log('Market Place Webview Crashing', {
					end: '/getSellProductsExtra',
					err: JSON.stringify(err),
				})
				mixpanel.track('Market Place Webview Crashing', {
					end: '/getSellProductsExtra',
					err: JSON.stringify(err),
				});
				reject(err);
			}
		});
	}
);

export const getBuyProductsInitial = createAsyncThunk(
	'/getBuyProducts',
	(payload, { dispatch, getState }) => {
		return new Promise(async (resolve, reject) => {
			try {
				const userId = await getUserIDFromSessionStorage();
				const myHeaders = new Headers();
				myHeaders.append('Content-Type', 'application/json');

				const raw = JSON.stringify({
					uid: userId ?? 'wMn4a578ChXPCC34Vl2Qn7KZFHV2',
					limit: 15,
					offset: 0,
				});
				let resp;
				const requestOptions = {
					method: 'POST',
					headers: myHeaders,
					body: raw,
					redirect: 'follow',
				};
				resp = await fetch('https://kc.retailpulse.ai/api/getBuyProducts', requestOptions);
				const data = await resp?.json();
				mixpanel.track('Market place getBuyProductsInitial api response', {
					status: resp.status,
				});
				if (data?.result?.rates?.length === 0) {
					fireMixpanelEvents(true, userId);
				}
				resolve(data?.result?.rates);
			} catch (err) {
				console.log('Market Place Webview Crashing', {
					end: '/getBuyProducts',
					err: JSON.stringify(err),
				})
				mixpanel.track('Market Place Webview Crashing', {
					end: '/getBuyProducts',
					err: JSON.stringify(err),
				});
				reject(err);
			}
		});
	}
);

export const getBuyProductsExtra = createAsyncThunk(
	'/getBuyProductsExtra',
	(payload, { dispatch, getState }) => {
		return new Promise(async (resolve, reject) => {
			try {
				const userId = await getUserIDFromSessionStorage();
				const buyProductOffset = getState()?.kharidBetch?.buyProductOffset;
				const myHeaders = new Headers();
				myHeaders.append('Content-Type', 'application/json');

				const raw = JSON.stringify({
					uid: userId ?? 'wMn4a578ChXPCC34Vl2Qn7KZFHV2',
					limit: 15,
					offset: buyProductOffset,
				});
				let resp;
				const requestOptions = {
					method: 'POST',
					headers: myHeaders,
					body: raw,
					redirect: 'follow',
				};
				resp = await fetch('https://kc.retailpulse.ai/api/getBuyProducts', requestOptions);
				const data = await resp?.json();
				mixpanel.track('Market place getBuyProductsExtra api response', {
					status: resp.status,
				});
				resolve({ data: data?.result?.rates, offset: buyProductOffset + 15 });
			} catch (err) {
				console.log('Market Place Webview Crashing', {
					end: '/getBuyProductsExtra',
					err: JSON.stringify(err),
				})
				mixpanel.track('Market Place Webview Crashing', {
					end: '/getBuyProductsExtra',
					err: JSON.stringify(err),
				});
				reject(err);
			}
		});
	}
);

export const submitKharidBecheForm = createAsyncThunk(
	'/kharidBecho',
	(payload, { dispatch, getState }) => {
		return new Promise(async (resolve, reject) => {
			try {
				const userId = await getUserIDFromSessionStorage();
				const formBody = getState()?.kharidBetch?.formBody ?? {};

				const body = { ...payload, ...formBody };
				console.log("body =>", body)
				if (!body?.uid) {
					body.uid = 'wMn4a578ChXPCC34Vl2Qn7KZFHV2';
				}
				const res = await baseAxios({
					endPoint: 'userSubmissions',
					data: body,
				});
				mixpanel.track('Market place submitKharidBecheForm api response', {
					status: res.status,
				});
				resolve(res);
			} catch (err) {
				console.log('Market Place Webview Crashing', {
					end: '/kharidBecho',
					err: JSON.stringify(err),
				})
				mixpanel.track('Market Place Webview Crashing', {
					end: '/kharidBecho',
					err: JSON.stringify(err),
				});
				reject(err);
			}
		});
	}
);

export const submitRequirementForm = createAsyncThunk(
	'/requirementForm',
	(payload, { dispatch, getState }) => {
		return new Promise(async (resolve, reject) => {
			try {
				const userId = await getUserIDFromSessionStorage();
				if (!payload?.uid) {
					payload.uid = userId ?? 'wMn4a578ChXPCC34Vl2Qn7KZFHV2';
				}
				const res = await baseAxios({
					endPoint: 'userSubmissions',
					data: {
						campaign: 'KBC_REQUIREMENTS',
						...payload,
						type: 'general',
					},
				});
				mixpanel.track('Market place submitRequirementForm api response', {
					status: res.status,
				});
				resolve(res);
			} catch (err) {
				console.log('Market Place Webview Crashing', {
					end: '/requirementForm',
					err: JSON.stringify(err),
				})
				mixpanel.track('Market Place Webview Crashing', {
					end: '/requirementForm',
					err: JSON.stringify(err),
				});

				reject(err);
			}
		});
	}
);

export const getSellProductDetail = createAsyncThunk(
	'/getSellProductsDetail',
	(payload, { dispatch, getState }) => {
		return new Promise(async (resolve, reject) => {
			try {
				const userId = await getUserIDFromSessionStorage();
				const { postId, isBuy } = payload;
				const myHeaders = new Headers();
				myHeaders.append('Content-Type', 'application/json');
				const raw = JSON.stringify({
					uid: userId ?? 'wMn4a578ChXPCC34Vl2Qn7KZFHV2',
					id: postId,
				});

				let resp;
				let data;
				const requestOptions = {
					method: 'POST',
					headers: myHeaders,
					body: raw,
					redirect: 'follow',
				};

				if (!!isBuy) {
					resp = await fetch('https://kc.retailpulse.ai/api/getSpecificBuyProduct', requestOptions);
					mixpanel.track('Market place getSpecificBuyProduct api response', {
						status: resp.status,
					});
					data = await resp.json();
				} else {
					resp = await fetch(
						'https://kc.retailpulse.ai/api/getSpecificSellProduct',
						requestOptions
					);
					mixpanel.track('Market place getSpecificSellProduct api response', {
						status: resp.status,
					});
					data = await resp.json();
				}

				resolve(data?.result?.rates);
			} catch (err) {
				console.log('Market Place Webview Crashing', {
					end: '/getSellProductsDetail',
					err: JSON.stringify(err),
				})
				mixpanel.track('Market Place Webview Crashing', {
					end: '/getSellProductsDetail',
					err: JSON.stringify(err),
				});
				reject(err);
			}
		});
	}
);

export const userIdFetch = createAsyncThunk('/userIdFetch', (payload, { dispatch, getState }) => {
	return new Promise(async (resolve, reject) => {
		try {
			const userId = await getUserIDFromSessionStorage();
			resolve(userId);
		} catch (err) {
			console.log('userIdFetch', {
				end: '/userIdFetch',
				err: JSON.stringify(err),
			})
			mixpanel.track('userIdFetch', {
				end: '/userIdFetch',
				err: JSON.stringify(err),
			});

			reject(err);
		}
	});
});
