import React, { useCallback, useEffect, useRef, useState } from 'react';
import Sheet from 'react-modal-sheet';
import { RxCross2 } from 'react-icons/rx';
import { getUserIDFromSessionStorage } from '../../utils/getUserID';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import mixpanel from '../../utils/mixpanel';
import { FaPhoneVolume } from "react-icons/fa6";
import BlueTick from '../../assests/blueTick.png';
import { Button } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { toggleBottomsheet } from '../../redux/kharidBetch/slice';
const userDefault = require('../../assests/userDefault.webp');
const wp = require('../../assests/wp.png');
const phone = require('../../assests/phone.png');
const copy = require('../../assests/copy image.png');
const { Row, Col } = require('reactstrap');

const DEFAULT_SPRING = {
	stiffness: 500,
	damping: 10,
	mass: 0.01,
};

function BottomSheetForSampark({
	uid,
	bottomSheet,
	setOpen,
	handleBottomSheet,
	setSurveyBottomSheet,
	type,
	postID,
	contact,
	detail,
	toggleSheet,
	setreviewType
}) {
	const ref = useRef();
	const dispatch = useDispatch()
	const handleBackPropogation = useCallback(
		(evt) => {
			evt.stopPropagation();
			dispatch(toggleBottomsheet(false))
		},
		[handleBottomSheet]
	);

	return (
		<>
			<Sheet
				springConfig={DEFAULT_SPRING}
				snapPoints={[0.60]}
				ref={ref}
				isOpen={bottomSheet}
				onClose={() => dispatch(toggleBottomsheet(false))}
				style={{
					zIndex: bottomSheet ? 1000 : -100,
				}}
			>
				<Sheet.Backdrop
					onClick={handleBackPropogation}
					style={{ backgroundColor: 'rgba(0,0,0,0.4)' }}
				/>
				<Sheet.Container style={{ backgroundColor: 'rgba(0,0,0,0.4)' }}>
					<div style={{ flex: 1, display: "flex", width: "100%", backgroundColor: "white", borderTopLeftRadius: 20, borderTopRightRadius: 20, flexDirection: "column", padding: 33 }}>
						<div onClick={() => dispatch(toggleBottomsheet(false))} style={{ display: 'flex', justifyContent: 'center', alignItems: "center", width: 22, height: 22, borderRadius: 11, backgroundColor: "black", position: "absolute", top: -30, right: 10 }}>
							<RxCross2 size={20} color='white' />
						</div>
						<div style={{ width: "100%", display: 'flex', justifyContent: "center" }}>
							<div style={{ width: 72, height: 72, borderRadius: 36, display: 'flex', justifyContent: "center", alignItems: "center", backgroundColor: "white", position: "absolute", justifySelf: "center", top: -30 }}>
								<FaPhoneVolume size={40} color='#2C99FF' />
							</div>
						</div>
						<h4 style={{ textAlign: "center", paddingTop: "10%" }}>
							आपका <strong>{detail?.user?.name}</strong> के साथ कॉल का अनुभव कैसा रहा ?
						</h4>
						<div style={{ backgroundColor: "#F5F5F5", padding: '1rem', borderRadius: 18, marginTop: 10 }}>
							<h4 style={{ fontSize: 16 }}>
								{detail?.content.length > 80 ? detail?.content.substring(0, 80) + "..." : detail?.content}
							</h4>
							<div style={{ display: "flex" }}>
								<img src={detail?.elementData?.user?.profile_image_url ?? userDefault} style={{ borderRadius: 19, height: 38, width: 38 }} alt="ii" />
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									<h4 style={{ fontSize: 16, fontWeight: 'bold', margin: 0, marginLeft: 10 }}>
										{detail?.user?.store_name.length > 18 ? detail?.user?.store_name.substring(0, 18) + "..." : detail?.user?.store_name}
									</h4>
									<p style={{ fontSize: 14, marginLeft: 10, marginBottom: 0 }}>
										{detail?.user?.name}
									</p>
								</div>
								{detail?.user?.isverified ? (
									<img
										src={BlueTick}
										style={{ width: 18, height: 18, }}
										alt="jj"
									/>
								) : null}
							</div>
						</div>
						<div style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: 35 }}>
							<Button
								onClick={() => {
									setreviewType('positive')
									dispatch(toggleBottomsheet(false))
									setSurveyBottomSheet(true)
								}}
								style={{
									color: 'white',
									backgroundColor: '#1FAF38',
									borderColor: '#1FAF38',
									width: '100%',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									fontWeight: 'bold',
									height: 44,
								}}
							>
								😃 अच्छा रहा
							</Button>
							<Button
								onClick={() => {
									setreviewType('negative')
									dispatch(toggleBottomsheet(false))
									setSurveyBottomSheet(true)
								}}
								style={{
									color: 'white',
									backgroundColor: '#B92B0F',
									borderColor: '#B92B0F',
									width: "100%",
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									fontWeight: 'bold',
									height: 44,
									marginTop: 12
								}}
							>
								😫 ख़राब रहा
							</Button>
						</div>
						<h4 onClick={()=>{
							mixpanel.track('Clicked On Baat Nahi Ho Payi')
							dispatch(toggleBottomsheet(false))
							toast.success('धन्यवाद 🙏 हमे बताने के लिए ')
						}} style={{ textDecoration: 'underline', fontSize: 16, color: '#F16943', textAlign: "center", marginTop: 30 }}>बात नहीं हो पायी </h4>
					</div>
				</Sheet.Container>
			</Sheet>
		</>
	);
}

export default BottomSheetForSampark;
