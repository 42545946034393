import React from "react";

function Camera({ width = 29, height = 29, color = "#757474", className }) {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 29 29"
      fill="none"
    >
      <path
        d="M14.095 20.555c1.468 0 2.716-.514 3.745-1.541 1.027-1.028 1.54-2.276 1.54-3.745 0-1.468-.513-2.716-1.54-3.744-1.029-1.028-2.277-1.541-3.745-1.541s-2.716.513-3.745 1.54c-1.027 1.029-1.54 2.277-1.54 3.745 0 1.469.513 2.717 1.54 3.745 1.029 1.027 2.277 1.54 3.745 1.54Zm-9.397 4.111c-.646 0-1.199-.23-1.658-.69a2.263 2.263 0 0 1-.69-1.66V8.223c0-.646.23-1.2.69-1.659a2.26 2.26 0 0 1 1.658-.69h3.7l2.173-2.35h7.048l2.173 2.35h3.7c.646 0 1.199.23 1.66.69.459.46.689 1.013.689 1.659v14.095c0 .646-.23 1.2-.69 1.66-.46.46-1.013.689-1.66.689H4.699Z"
        fill={color}
      />
    </svg>
  );
}

export default React.memo(Camera);
