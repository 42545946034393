import React from 'react';

function Emoji({ width = 24, height = 24, color = '#B4B4B4', className }) {
	return (
		<svg width={width} height={height} className={className} viewBox="0 0 24 24" fill="none">
			<path
				d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2ZM8.5 8c.83 0 1.5.67 1.5 1.5S9.33 11 8.5 11 7 10.33 7 9.5 7.67 8 8.5 8Zm8.21 6.72C15.8 16.67 14.04 18 12 18s-3.8-1.33-4.71-3.28c-.16-.33.08-.72.45-.72h8.52c.37 0 .61.39.45.72ZM15.5 11c-.83 0-1.5-.67-1.5-1.5S14.67 8 15.5 8s1.5.67 1.5 1.5-.67 1.5-1.5 1.5Z"
				fill={color}
			/>
		</svg>
	);
}

export default React.memo(Emoji);
