import React from 'react';

function BellOn({ width = 44, height = 44, color = '#fff', background = '#000', className }) {
	return (
		<svg width={width} height={height} className={className} viewBox="0 0 44 44" fill="none">
			<circle cx={22} cy={22} r={20} fill={background} fillOpacity={0.25} />
			<path
				d="M31 29v1H13v-1l2-2v-6c0-3.1 2.03-5.83 5-6.71V14a2 2 0 0 1 4 0v.29c2.97.88 5 3.61 5 6.71v6l2 2Zm-7 2a2 2 0 0 1-4 0m9.75-17.81-1.42 1.42A8.98 8.98 0 0 1 31 21h2c0-2.93-1.16-5.75-3.25-7.81ZM11 21h2c0-2.4.96-4.7 2.67-6.39l-1.42-1.42A10.96 10.96 0 0 0 11 21Z"
				fill={color}
			/>
		</svg>
	);
}

export default React.memo(BellOn);
