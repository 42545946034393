import { createSlice } from '@reduxjs/toolkit';
import {
	quizQuestionFetch,
	quizQuestionSubmit,
	getLeaderBoard,
	getDynamicLink,
	getZoneLeaderBoard,
} from './thunk';

const initialState = {
	questions: [],
	questionLoading: false,
	questionSubmitLoading: false,
	initialQuestion: 0,
	totalPoints: 0,
	leaderBoard: [],
	leaderBoardPoints: 0,
	selfRank: 0,
	overAllRank:0,
	overAllScore:0,
	zone: null,
	zoneName: null,
	userPageState: 0,
	leaderBoardLoading: false,
	dynamicLinkLoading: false,
	dynamicLink: null,
	noOfCorrectQuestion: 0,
	sessionPoints: 0,
	quizId: null,
	error: '',
};

const quizSlice = createSlice({
	name: 'quiz',
	initialState,
	reducers: {
		setCurrentQuestime(state, { payload }) {
			state.questions = state.questions.map((question, index) => {
				if (question.id === payload.id) {
					return {
						...question,
						time: payload.time,
					};
				}
				return question;
			});
		},
		setZone(state, { payload }) {
			state.zone = payload;
		},
		answerQuestion(state, { payload }) {
			const { answer, id } = payload;
			if (answer) {
				state.questions = state.questions.map((question, index) => {
					if (index === id) {
						return {
							...question,
							userAnswer: answer,
						};
					}
					return question;
				});
				if (state.questions[id]?.correct_ans === answer) {
					state.noOfCorrectQuestion += 1;
					state.totalPoints += state.questions[id]?.positive_points;
				} else if (state.questions[id]?.correct_ans !== answer) {
					state.totalPoints -= state.questions[id]?.negative_points;
				}
			}
		},
		increaseUserPageState(state, { payload }) {
			state.userPageState += 1;
		},
		timerComplete(state, { payload }) {
			const { answer, id } = payload;
			if (state.questions[id]) {
				state.questions[id].userAnswer = answer;
			}
		},
		nextQuestion(state, { payload }) {
			state.initialQuestion += 1;
		},
		emptyQuizData(state, { payload }) {
			state.questions = [];
			state.questionLoading = false;
			state.questionSubmitLoading = false;
			state.initialQuestion = 0;
			state.totalPoints = 0;
			state.selfRank = 0;
			//state.zone = null;
			state.userPageState = 0;
			state.noOfCorrectQuestion = 0;
			state.error = '';
		},
		setQuizId(state, { payload }) {
			state.quizId = payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(quizQuestionFetch.pending, (state, action) => {
			state.questionLoading = true;
		});
		builder.addCase(quizQuestionFetch.fulfilled, (state, { payload }) => {
			state.questionLoading = false;
			state.zone = payload[0]?.zone;
			state.zoneName = payload[0]?.zone_name;
			payload.forEach((element) => {
				element.userAnswer = null;
			});
			state.questions = payload;
		});
		builder.addCase(quizQuestionFetch.rejected, (state, action) => {
			state.questionLoading = false;
		});
		builder.addCase(quizQuestionSubmit.pending, (state, action) => {
			state.questionSubmitLoading = true;
		});
		builder.addCase(quizQuestionSubmit.fulfilled, (state, { payload }) => {
			state.questionSubmitLoading = false;
		});
		builder.addCase(quizQuestionSubmit.rejected, (state, action) => {
			state.questionSubmitLoading = false;
		});

		builder.addCase(getLeaderBoard.pending, (state, action) => {
			state.leaderBoardLoading = true;
		});
		builder.addCase(getLeaderBoard.fulfilled, (state, { payload }) => {
			state.leaderBoard = payload?.others ?? [];
			state.selfRank = payload?.self;
			state.zone = payload?.self_zone;
			state.zoneName = payload?.self_zone_name;
			state.sessionPoints = payload?.session_points;
			state.leaderBoardPoints = payload?.self_points;
			state.leaderBoardLoading = false;
		});
		builder.addCase(getLeaderBoard.rejected, (state, { payload }) => {
			state.error = payload;
			state.leaderBoardLoading = false;
		});
		builder.addCase(getZoneLeaderBoard.pending, (state, action) => {
			state.leaderBoardLoading = true;
		});
		builder.addCase(getZoneLeaderBoard.fulfilled, (state, { payload }) => {
			state.leaderBoard = payload?.others ?? [];
			state.overAllRank = payload?.self
			state.overAllScore = payload?.self_points
			state.leaderBoardLoading = false;
		});
		builder.addCase(getZoneLeaderBoard.rejected, (state, { payload }) => {
			state.error = payload;
			state.leaderBoardLoading = false;
		});

		builder.addCase(getDynamicLink.pending, (state, action) => {
			state.dynamicLinkLoading = true;
		});
		builder.addCase(getDynamicLink.fulfilled, (state, { payload }) => {
			state.dynamicLink = payload;
			state.dynamicLinkLoading = false;
		});
		builder.addCase(getDynamicLink.rejected, (state, { payload }) => {
			state.error = payload;
			state.dynamicLinkLoading = false;
		});
	},
});

export const {
	answerQuestion,
	nextQuestion,
	timerComplete,
	increaseUserPageState,
	emptyQuizData,
	setCurrentQuestime,
	setZone,
	setQuizId
} = quizSlice.actions;

export default quizSlice.reducer;
