import React, { useEffect, useRef } from 'react';

const post1 = require('../assests/mandiPost1.webp');
const post2 = require('../assests/mandiPost2.webp');

function ShowSampleMandiPosts() {
	const contentRef = useRef();

	useEffect(() => {
		contentRef?.current?.scrollIntoView(true);
	}, [])
	
	
	return (
	
			<div ref={contentRef} className="flex flex-col justify-between bg-[#F5F5F5]">
				<img alt="user" src={post1} className="object-contain w-[90%] bg-[#F5F5F5]  mb-2 self-center " />
				<img alt="user" src={post2} className="object-contain w-[90%] bg-[#F5F5F5]  mb-3 self-center " />
			</div>

	);
}

export default ShowSampleMandiPosts;
