import { apiAxios } from '../utils/axios';
import get from 'lodash/get';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import baseAxios from '../utils/baseAxios';

export const fetchFunction = async (url, params, isBaseUrl = false, type = 'POST') => {
	return new Promise(async (resolve, reject) => {
		try {
			let res;
			if (type === 'GET') {
				res = await apiAxios.get(url, params);
				resolve(res?.data);
			}
			else if (type === 'PUT') {
				res = await apiAxios.put(url, params);
				resolve(res?.data);
			}
			else {
				if (isBaseUrl) {
					res = await baseAxios({
						endPoint:url,
						data: params,
					});
					resolve(res?.result);
				} else {
					res = await apiAxios.post(url, params);
					resolve(res?.data);
				}
			}
		} catch (err) {
			reject({ message: get(err, 'response.data.error.message', err.message) });
		}
	});
};

// export const updateFunction = async (url, params) => {
// 	return new Promise(async (resolve, reject) => {
// 		try {
// 			const res = await apiAxios.put(url, params);
// 			resolve(res?.data);
// 		} catch (err) {
// 			reject({ message: get(err, 'response.data.error.message', err.message) });
// 		}
// 	})
// }

export const useQueryFetch = ({
	url,
	params = {},
	queryKey = [],
	isBaseUrl = false,
	enabled = true,
	type,
}) => {
	// This makes a unique key for every unique url and params to store data in cache
	const { data, isLoading, refetch, error, isPreviousData, isFetching} = useQuery({
		queryKey: queryKey,
		queryFn: () => fetchFunction(url, params, isBaseUrl, type),
		enabled,
	});

	return {
		data,
		isLoading,
		error,
		refetch,
		isPreviousData,
		isFetching,
	};
};


export const useInfiniteQueryFetch = ({
	url,
	params = {},
	queryKey = [],
	isBaseUrl = false,
	enabled = true,
	type,
}) => {
	const {
		data,
		fetchNextPage,
		fetchPreviousPage,
		hasNextPage,
		hasPreviousPage,
		isFetchingNextPage,
		isFetchingPreviousPage,
		isLoading,
		isError,
	} = useInfiniteQuery({
		queryKey,
		queryFn: ({ pageParam = -1 }) => fetchFunction(url, { ...params, lastItem:pageParam }, isBaseUrl, type),
		getNextPageParam: (lastPage) => {
			if (lastPage?.data?.length < 10) {
			  return null;
			}
			return lastPage?.last_item;
		  },
		enabled,
	});


	const allData = data?.pages.flatMap((page) => page.data);

	return {
		data:allData,
		fetchNextPage,
		fetchPreviousPage,
		hasNextPage,
		hasPreviousPage,
		isFetchingNextPage,
		isFetchingPreviousPage,
		isLoading,
		isError,
	};
};