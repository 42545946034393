const text = 'text';
const video = 'video';
const specificMandi = 'mandi';
const accepted = 'approved';
const rejected = 'rejected';
const pending = 'pending';

const isPostTypeVideo = (type) => type === video;
const isPostTypeText = (type) => type === text;

const isPostTypeMandiRate = (type, specific) => type === text && specific === specificMandi;

const isPostAccepted = (status) => status === accepted;
const isPostRejected = (status) => status === rejected;
const isPostPending = (status) => status === pending;

export { isPostTypeVideo, isPostTypeText, isPostTypeMandiRate, isPostAccepted, isPostRejected, isPostPending };
