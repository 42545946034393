import React from 'react';
import { Modal } from 'react-bootstrap';
import RippleButton from '../components/RippleButton';
import Lottie from 'react-lottie';
import { AiOutlineClose } from 'react-icons/ai';
import * as animationData from '../assests/reviewAnim.json';
import * as animationData2 from '../assests/lottie/successLottie.json';
import Image from '../components/ui/Image';

const defaultOptions = {
	loop: true,
	autoplay: true,
	animationData: animationData,
	rendererSettings: {
		preserveAspectRatio: 'xMidYMid slice',
	},
};

function SubmitPostModal({ showModal, handlePostSubmitModal, from = '', thanksIcon = null,title,desc,success=false }) {
	return (
		<Modal show={showModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
			<Modal.Body className="flex flex-col text-align">
				{from === 'userSuggestion' ? (
					<div className="flex flex-col flex-1 self-center items-center">
						<div className="flex font-bold text-[1.2rem] mt-2">
							जानकारी देने के लिए आपका धन्यवाद
							<AiOutlineClose
								onClick={handlePostSubmitModal}
								className=" relative -top-[0.7rem] -right-6"
							/>
						</div>
						<Image
							alt="thanksIcon"
							src={thanksIcon}
							className="flex w-[6rem] object-fill bg-[#fff] mt-4"
						/>
					</div>
				) : (
					<>
						<AiOutlineClose onClick={handlePostSubmitModal} className="self-end" />
						{
							success ? <Lottie
							options={{
								...defaultOptions,
								animationData:animationData2
							}}
							height={100}
							width={100}
							isStopped={showModal ? false : true}
							isPaused={showModal ? false : true}
						/>:(<Lottie
							options={defaultOptions}
							height={200}
							width={200}
							isStopped={showModal ? false : true}
							isPaused={showModal ? false : true}
						/>)
						}
						<h4 className="text-center"> {title ?? "जानकारी रिव्यु हो रही है "} </h4>
						{desc ? <div className="text-center">
							{desc}
						</div>:<div className="text-center">
							हम आपकी पोस्ट जल्द रिव्यु करेंगे और यह
							<br /> किराना दोस्तों के लिए कितनी मददगार है उसके
							<br /> आधार पर एप्रूव करेंगे
						</div>}
						<RippleButton
							onSubmit={handlePostSubmitModal}
							// isDisabled={}
							title="ठीक हैं"
							className="flex 
						flex-col 
						font-bold bg-[#D45339] 
						text-[#fff] 
						h-10 
						justify-center
						text-center
						mt-3 
						px-10
						self-center 
						rounded-2xl
						text-lg"
							rippleColor="#003310"
						/>
					</>
				)}
			</Modal.Body>
		</Modal>
	);
}

export default React.memo(SubmitPostModal);
