import React, { useCallback, useEffect, useRef, useState } from 'react';
import Sheet from 'react-modal-sheet';
import ShowSamplePosts from './ShowSamplePosts';
import ShowSampleVideos from './ShowSampleVideos';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import mixpanel from '../utils/mixpanel';
import { isPostTypeMandiRate } from '../utils/newPostRevamp/utilFunction';
import ShowSampleMandiPosts from './ShowMandiSamplePosts';

const DEFAULT_SPRING = {
	stiffness: 300,
	damping: 30,
	mass: 0.2,
};

function BottomSheetForSamplePosts({
	isOpen,
	setOpen,
	type,
	setType,
	location,
	removeHash,
	specific = '',
	postType = '',
}) {
	const ref = useRef();

    const [swipePosPrev, setSwipePosPrev] = useState();
	const [swipePosFinal, setSwipePosFinal] = useState();

	useEffect(() => {
	  mixpanel.track('Bottomsheet View for Learning Screen');
	}, [])
	
	const handleBackPropogation = useCallback((evt) => {
		evt.stopPropagation();
		removeHash();
	}, [removeHash]);

	const handleTouchStart=useCallback((evt)=>{
	   setSwipePosPrev(evt?.changedTouches['0']?.clientX);
	},[])

	const handleTouchMove=useCallback((evt)=>{
		setSwipePosFinal(evt?.changedTouches['0']?.clientX)
	 },[])

	 const handleTouchEnd=useCallback(()=>{
		if(swipePosFinal-swipePosPrev>70)
		setType(0);
		if(swipePosFinal-swipePosPrev<-70)
		setType(1);

	 },[setType, swipePosFinal, swipePosPrev])

	return (
		<>
			<Sheet
				springConfig={DEFAULT_SPRING}
				snapPoints={[isOpen ? (0.75) : 0]}
				ref={ref}
				isOpen={isOpen}
				onClose={() => setOpen(false)}
				style={{
					zIndex: isOpen ? 1000 : -100,
				}}
				onTouchStart={handleTouchStart}
				onTouchMove={handleTouchMove}
				onTouchEnd={handleTouchEnd}
			>
				<Sheet.Backdrop onClick={handleBackPropogation} style={{backgroundColor:'rgba(0,0,0,0.8)'}} />
				<Sheet.Container style={{ backgroundColor: '#F5F5F5' }}>
					<AiOutlineCloseCircle
						onClick={handleBackPropogation}
						className="w-8 h-8 absolute -top-[2.4rem] right-2 bg-black rounded-2xl"
						style={{ color: '#fff' }}
					/>
					<Sheet.Header disableDrag={true}>
						<div className="p-2 mx-10 text-center font-semibold mt-2 mb-1 text-[1.4rem] text-black leading-9">
							{isPostTypeMandiRate(postType, specific)
								? 'आप भी इनकी तरह बढ़िया मंडी रेट पोस्ट कर सकते हैं 👇'
								: 'आप भी इनकी तरह बढ़िया पोस्ट कर सकते है 👇'}
						</div>
						{!isPostTypeMandiRate(postType, specific) && (
							<div
								className="flex justify-between  
							 bg-[#e5e5e5] text-black sticky 
							top-0 font-bold rounded-md mx-4 mb-2"
							>
								<div
									className={`text-[${!type ? '#D45339' : '#000'}] w-[50%] text-center py-3`}
									style={{ borderColor: '#D45339', borderBottomWidth: !type ? '0.15rem' : '0px' }}
									onClick={() => {
										setType(0);
									}}
								>
									वीडियो पोस्ट
								</div>
								<div
									className={`text-[${type ? '#D45339' : '#000'}] w-[50%] text-center py-3`}
									style={{ borderColor: '#D45339', borderBottomWidth: type ? '0.15rem' : '0px' }}
									onClick={() => {
										setType(1);
									}}
								>
									लिखित पोस्ट
								</div>
							</div>
						)}
					</Sheet.Header>
					<Sheet.Content disableDrag={true}>
						{isPostTypeMandiRate(postType, specific) ? (
							<div>
								<ShowSampleMandiPosts />
							</div>
						) : (
							<div>
								{!type ? (
									<div>
										<ShowSampleVideos isOpen={isOpen} location={location} />
									</div>
								) : (
									<div>
										<ShowSamplePosts />
									</div>
								)}
							</div>
						)}
					</Sheet.Content>
				</Sheet.Container>
			</Sheet>
		</>
	);
}

export default React.memo(BottomSheetForSamplePosts);
