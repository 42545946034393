import React from 'react';

const Tick = (props) => {
	return (
		<svg
			{...props}
			width="140"
			height="140"
			viewBox="0 0 140 140"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g id="tick">
				<path
					id="Vector"
					d="M70 13.125C38.582 13.125 13.125 38.582 13.125 70C13.125 101.418 38.582 126.875 70 126.875C101.418 126.875 126.875 101.418 126.875 70C126.875 38.582 101.418 13.125 70 13.125ZM61.2227 90.1523C60.5664 90.8086 59.6367 91.3555 58.8164 91.3555C57.9961 91.3555 57.0664 90.7812 56.3828 90.125L41.0703 74.8125L45.9375 69.9453L58.8438 82.8516L92.9688 48.4805L97.7539 53.4297L61.2227 90.1523Z"
					fill="#00AA35"
				/>
			</g>
		</svg>
	);
};

export default Tick;
