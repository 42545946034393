import React, { useEffect, useRef } from 'react';
import VideoPlayer from './VideoPlayer';

const thumbnail1= require('../assests/thumbnail1.webp');
const thumbnail2= require('../assests/thumbnail2.webp');

function ShowSampleVideos({isOpen,location}) {
	const contentRef = useRef();

	useEffect(() => {
		
		contentRef?.current?.scrollIntoView(true);

	  }, [])

	return (
		isOpen?<div ref={contentRef} className='flex flex-col py-2 mx-6 ' style={{zIndex:-1}}>
			<VideoPlayer thumbnail={thumbnail1} location={location}  media={isOpen?"https://rpunipulse.blob.core.windows.net/temp/humdarad'.mp4":null}/>
			<VideoPlayer thumbnail={thumbnail2} location={location}  media={isOpen?"https://rpunipulse.blob.core.windows.net/temp/WhatsApp Video 2023-04-05 at 11.19.51.mp4":null}/>
		</div>:<div/>
	);
}

export default React.memo(ShowSampleVideos);
