import { v4 } from 'uuid';

export const uploadFileToBlobFromApi = async (
    file,
    cmp,
    userID,
    containerName
) => {
    try {
        if (!file) {
            return [];
        }
        const id = v4();
        let ur;

        const formData = new FormData();
        formData.append('file', file);
        formData.append('campaigns', cmp);
        formData.append('userID', userID);
        formData.append('id', id);
        formData.append('containerName', containerName)
        formData.append('name', file?.name)
        const options = {
            method: "POST",
            headers: {
                contentType: "multipart/form-data",
            },
            body: formData,
        };

        try {
            const res = await fetch(`https://kc.retailpulse.ai/api/ams/upload/file`, options);
            if (res.status === 200) {
                const resp = await res.json();
                ur = resp?.results.url;
                return ur;
            } else {
                console.log("error ", res)
            }
        } catch (e) {
            console.error(e);
        }
        return ur;
    } catch (e) {
        console.log(e);
    }
};

export default uploadFileToBlobFromApi;
