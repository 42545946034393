import React, { useCallback, useEffect, useRef, useState } from 'react'
import * as Yup from 'yup'
import Sheet from 'react-modal-sheet';
import { RxCross2 } from 'react-icons/rx';
import { CircularProgress, TextField } from '@mui/material';
import { FaPhoneVolume } from "react-icons/fa6";
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { Button, Input } from 'reactstrap';
import { useDispatch } from 'react-redux';
import CustomButton from '../Button';
import { fetchFunction } from '../../hooks/useQueryHook';
import { removeFeedBack } from '../../redux/kharidBetch/slice';
import { toggleBottomsheet } from '../../redux/kharidBetch/slice';
const userDefault = require('../../assests/userDefault.webp');
import "./bottomSheetSampark2.css"
import phoneRound from "../../assests/phoneRound.svg"

const DEFAULT_SPRING = {
    stiffness: 500,
    damping: 10,
    mass: 0.01,
};


const validationSchema = Yup.object().shape({
    feedBack: Yup.string()
        .trim()
});

const BottomSheetSampark2 = ({
    uid,
    bottomSheet,
    setOpen,
    handleBottomSheet,
    type,
    postID,
    content,
    contact,
    detail,
    headline,
    mixpanel,
    id
}) => {
    const ref = useRef();
    const dispatch = useDispatch()
    const [formScreenShow, setFormScreenShow] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const handleBackPropogation = useCallback(
        (evt) => {
            evt.stopPropagation();
            dispatch(toggleBottomsheet(false))
        },
        [handleBottomSheet]
    );

    const formik = useFormik({
        initialValues: {
            whatsappNumber: '',
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            const obj = { ...values };
            formik.resetForm()
            dispatch(removeFeedBack(id))
            mixpanel.track('Rate feedback submitted')
            toast.success('Thank you for the feedback!')
            setOpen(false)
        },
    });

    return (
        <>
            <Sheet
                springConfig={DEFAULT_SPRING}
                snapPoints={[0.60]}
                ref={ref}
                isOpen={bottomSheet}
                onClose={() => dispatch(toggleBottomsheet(false))}
                style={{
                    zIndex: bottomSheet ? 1000 : -100,
                }}
            >
                <Sheet.Backdrop
                    onClick={handleBackPropogation}
                    style={{ backgroundColor: 'rgba(0,0,0,0.4)' }}
                />
                <Sheet.Container className='custom-sheet-container' style={{ backgroundColor: 'rgba(0,0,0,0.4)' }}>
                    <div style={{ flex: 1, display: "flex", width: "100%", backgroundColor: "white", borderTopLeftRadius: 20, borderTopRightRadius: 20, flexDirection: "column", padding: 20 }}>
                        <div onClick={() => {
                            dispatch(toggleBottomsheet(false));
                            setOpen(false);
                            setFormScreenShow(false);
                        }} style={{ display: 'flex', justifyContent: 'center', alignItems: "center", width: 22, height: 22, borderRadius: 11, backgroundColor: "black", position: "absolute", top: -30, right: 10 }}>
                            <RxCross2 size={20} color='white' />
                        </div>
                        <div className='w-full py-2 px-2'>
                            <>
                                <h1 className="text-xl font-semibold w-full text-left text-gray-950">
                                    आपको क्या और कितना खरीदना है
                                </h1>
                                <Input
                                    id="userNeeds"
                                    style={{
                                        background: "linear-gradient(0deg, #E2E2E2, #E2E2E2),linear-gradient(0deg, #F2F2F2, #F2F2F2)",
                                        border: "1px solid rgba(226, 226, 226, 1)"
                                    }}
                                    name="userNeeds"
                                    placeholder="उदाहरण २० किलो शक्कर "
                                    type="text"
                                    onChange={(e) => setInputValue(e.target.value)}
                                    className="mt-4 border-none focus:outline-none focus:border-none outline-none rounded-lg px-2 py-3"
                                />
                                <button disabled={inputValue.trim().length === 0} className={`${inputValue.trim().length === 0 ? "bg-slate-400" : "bg-[#d94f29d9]"} mt-4 text-white shadow-lg flex justify-center items-center px-2 py-3 rounded-lg w-full`}>
                                    <span className='text-xl font-bold'>सबमिट करें</span>
                                </button>
                            </>
                            {/* {!formScreenShow ? (
                                <>
                                    <h1 className="text-2xl w-full text-center text-gray-950 font-bold">
                                        {headline} से संपर्क करें
                                    </h1>

                                    <button onClick={() => setFormScreenShow(true)} className="bg-[#00aa35d9] mt-4 text-white shadow-lg flex justify-center items-center px-2 py-3 rounded-lg w-full">
                                        <div className="block">
                                            <img src={phoneRound} alt=""/>
                                        </div>
                                        <span className='text-xl font-bold'>विक्रेता से कॉल पाएं </span>
                                    </button>
                                    <button onClick={(e) => {
                                        e.stopPropagation();
                                        contact && window.open(`tel:${contact}`)
                                    }}
                                        className="bg-[#2c99ffd9] mt-4 text-white shadow-lg flex justify-center items-center px-2 py-3 rounded-lg w-full">
                                        <div className="block">
                                            <FaPhoneVolume className='mr-2' size={25} />
                                        </div>
                                        <span className='text-xl font-bold'>अभी कॉल करे</span>
                                    </button>
                                </>
                            ) : (
                                <>
                                    <h1 className="text-xl font-semibold w-full text-left text-gray-950">
                                        आपको क्या और कितना खरीदना है
                                    </h1>
                                    <Input
                                        id="userNeeds"
                                        style={{
                                            background: "linear-gradient(0deg, #E2E2E2, #E2E2E2),linear-gradient(0deg, #F2F2F2, #F2F2F2)",
                                            border: "1px solid rgba(226, 226, 226, 1)"
                                        }}
                                        name="userNeeds"
                                        placeholder="उदाहरण २० किलो शक्कर "
                                        type="text"
                                        onChange={(e) => setInputValue(e.target.value)}
                                        className="mt-4 border-none focus:outline-none focus:border-none outline-none rounded-lg px-2 py-3"
                                    />
                                    <button disabled={inputValue.trim().length === 0} className={`${inputValue.trim().length === 0 ? "bg-slate-400" : "bg-[#d94f29d9]"} mt-4 text-white shadow-lg flex justify-center items-center px-2 py-3 rounded-lg w-full`}>
                                        <span className='text-xl font-bold'>सबमिट करें</span>
                                    </button>
                                </>
                            )} */}

                        </div>
                    </div>
                </Sheet.Container>
            </Sheet>
        </>
    )
}

export default BottomSheetSampark2