import React, { useCallback, useEffect, useState } from 'react'
import { Card } from 'reactstrap'
import { BsShop } from "react-icons/bs";
import { IoLocationOutline } from "react-icons/io5";
import { TbTruckDelivery } from "react-icons/tb";
import { IoMdThumbsUp, IoMdThumbsDown } from "react-icons/io";
import { FaPhoneVolume } from "react-icons/fa6";
import { useMutation } from '@tanstack/react-query';
import BottomSheetSampark2 from './bottomSheetSampark2';
import mixpanel from '../../utils/mixpanel';
import { toast } from 'react-toastify';
import { useNavigate, Link } from 'react-router-dom';
import { fetchFunction, updateFunction } from '../../hooks/useQueryHook';
import { sellProductsSelector } from '../../redux/kharidBetch/selector';
import { useDispatch, useSelector } from 'react-redux';
import { updateUpvoteDownVoteProduct } from '../../redux/kharidBetch/slice';
import { FaWhatsapp } from "react-icons/fa";
import truck from "../../assests/truck.svg"
import baseAxios from '../../utils/baseAxios';
import { useInView } from "react-intersection-observer";

const packingDetails = [
    {
        "packingSize": "1 Kg.",
        "packingImage": "https://craftypixels.com/placeholder-image/400x500/29bd00/fff&text=Bhujia-1KG!",
        "packingPrice": "120"
    },
    {
        "packingSize": "5 Kg.",
        "packingImage": "https://craftypixels.com/placeholder-image/400x500/29ab00/fff&text=Bhujia-5Kg!",
        "packingPrice": "110"
    },
    {
        "packingSize": "10 Kg.",
        "packingImage": "https://craftypixels.com/placeholder-image/400x500/33bd00/fff&text=Bhujia-10Kg!",
        "packingPrice": "100"
    }
]

const deliveryOptions = {
    cantDeliver: "डिलीवरी नहीं करवा सकते",
    localDeliver: "सिर्फ़ लोकल में डिलीवरी करवा सकता",
    courierDeliver: "कूरियर / ट्रांसपोर्टर से शिप करवा देंगे",
    allIndiaDeliver: "पूरे भारत में"
}

const cache = {
    post_id : {}
}

const RateCard3 = ({
    dynamicLink,
    user,
    uid,
    keyy,
    content,
    distance,
    id,
    elementData,
    isBuy,
    onClickFeedback,
    setHeadline,
    feedBack,
}) => {
    const [selectedPacking, setSelectedPacking] = useState(0);
    const [hideCard, setHideCard] = useState(true)
    const dispatch = useDispatch();
    const [bottomSheet, setBottomSheet] = useState(false);
    const [deliveryStatus, setDeliveryStatus] = useState(false);
    const [upvoteDownvotePost, setUpvoteDownvotePost] = useState({ interacted: false, upvoted: false, downvoted: false });
    const sellProducts = useSelector(sellProductsSelector);
    const [visitingUser, setVisitingUser] = useState(null)
    const [upvoteDownvoteCount, setUpvoteDownvoteCount] = useState({
        upvote: elementData?.up_vote ?? 0,
        downvote: elementData?.down_vote ?? 0
    })

    const navigate = useNavigate()
    const { ref, inView, entry } = useInView({
		threshold: 1,
	});
    const { isLoading, mutate } = useMutation({
			mutationFn: ({ upvote, downvote }) => {
				let params = {
					id: id,
					uid: uid,
					upvote: upvote ?? false,
					downvote: downvote ?? false,
				};

				// https://kc.retailpulse.ai/api/postInteraction
				fetchFunction('https://kc.retailpulse.ai/api/postInteraction', params, false, 'PUT');
				mixpanel.track('Upvote/Downvote Interaction Submitted', {
					post_id: id,
					post_headline:
						elementData?.headline && elementData.headline !== '' ? elementData?.headline : '',
					content:
						elementData?.packingDetails && elementData?.packingDetails[selectedPacking]?.content
							? elementData?.packingDetails[selectedPacking]?.content
							: content,
				});
			},
			onSuccess: (res) => {
				// toast.success("हमें जानकारी देने के लिए धन्यवाद", {
				//     position: "bottom-right",
				// })
			},
			onError: (res) => {
				console.log('error =>', res);
			},
		});

		useEffect(() => {
			const fetchUser = async () => {
				try {
					const userId = window.sessionStorage.getItem('data');

					mixpanel.identify(userId);
					window.webengage?.user?.login(userId);

					const res = await baseAxios({
						endPoint: 'userProfile',
						data: {
							type: 'getUserProfile',
							selfUserId: userId ?? 'jWjeLoO8C3NG9fmFm1icAq1c33E2',
						},
					});

					setVisitingUser(res?.result?.data);
				} catch (err) {
					console.log('error in fetching the user: ', err);
				}
			};

			fetchUser();
		}, []);

    const handleBottomSheet = useCallback(() => {
        if (mixpanel?.get_distinct_id()) {
            mixpanel.track('Clicked on Sampark Kare in KBC', {
                screen_name: elementData?.quantity ? 'KBC Requirements' : 'KBC Posts',
                post_id: id,
                post_headline: elementData?.headline && elementData.headline !== "" ? elementData?.headline : "",
                post_type: elementData?.quantity ? 'KBC Requirement Post' : 'KBC Sell Post',
            });
        }
        // window.webengage.track('Clicked on Sampark Kare in KBC', {
        // 	screen_name: elementData?.quantity ? 'KBC Requirements' : 'KBC Posts',
        // 	post_id: id,
        // 	user_id: uid,
        // 	post_type: elementData?.quantity ? 'KBC Requirement Post' : 'KBC Sell Post',
        // });
        setBottomSheet((prev) => !prev);
    }, [elementData?.quantity, id, uid]);

    const handleLikeDisLikePost = (type) => {
        if (upvoteDownvotePost.interacted) return;
        if (type === "up_vote") {
            // increment post like API call

            setUpvoteDownvotePost({ interacted: true, upvoted: true, downvoted: false });
            setUpvoteDownvoteCount(prev => {
                return {
                    ...prev, upvote: prev.upvote + 1
                }
            })
            // dispatch(updateUpvoteDownVoteProduct({ keyy, type }))
            mixpanel.track('Clicked on kam rate hai', {
                post_id: id,
                count: upvoteDownvoteCount.upvote,
                post_headline: elementData?.headline && elementData.headline !== "" ? elementData?.headline : "",
                content: elementData?.packingDetails && elementData?.packingDetails[selectedPacking]?.content ?
                    elementData?.packingDetails[selectedPacking]?.content :
                    content,
            });
            mutate({ upvote: true, downvote: false })
        }
        else if (type === "down_vote") {
            // decrement post like API call
            setUpvoteDownvotePost({ interacted: true, upvoted: false, downvoted: true });
            setUpvoteDownvoteCount(prev => {
                return {
                    ...prev, downvote: prev.downvote + 1
                }
            })
            // dispatch(updateUpvoteDownVoteProduct({ keyy, type }))
            mixpanel.track('Clicked on jada rate hai', {
                post_id: id,
                count: upvoteDownvoteCount.downvote,
                post_headline: elementData?.headline && elementData.headline !== "" ? elementData?.headline : "",
                content: elementData?.packingDetails && elementData?.packingDetails[selectedPacking]?.content ?
                    elementData?.packingDetails[selectedPacking]?.content :
                    content,
            });
            mutate({ upvote: false, downvote: true })
        }
    }

    useEffect(() => {
        if (elementData?.deliveryRange === deliveryOptions.localDeliver ||
            elementData?.deliveryRange === 'सिर्फ़ लोकल में डिलीवरी करवा सकते') {
            if ((distance <= elementData?.deliveryKm + 10) || (!elementData?.deliveryKm && distance <= 20)) {
                setDeliveryStatus(true);
            } else {
                setDeliveryStatus(false)
            }
        }

        else if (elementData?.deliveryRange === deliveryOptions.cantDeliver && distance <= 10) {
            setDeliveryStatus(true);
        }

        else if (elementData?.deliveryRange === deliveryOptions.allIndiaDeliver
            || elementData?.deliveryRange === 'पूरे भारत मे'
            || elementData?.deliveryRange === 'फोन पर बात करके व्यवस्था हो जाएगी'
            || elementData?.deliveryRange === deliveryOptions.courierDeliver) {
            setDeliveryStatus(true);
        }

        else if ((!elementData?.packingDetails || elementData?.packingDetails?.length) &&
            !Object.values(deliveryOptions).includes(elementData?.deliveryRange)) {
            setDeliveryStatus(true);
        }

    }, [elementData, distance])


    useEffect(() => {
        // show hide card
        if (elementData?.deliveryRange === deliveryOptions.allIndiaDeliver
            || elementData?.deliveryRange === 'पूरे भारत मे'
            || elementData?.deliveryRange === 'फोन पर बात करके व्यवस्था हो जाएगी'
            || elementData?.deliveryRange === deliveryOptions.courierDeliver) {
            setHideCard(false)
        }
        else if (elementData?.deliveryRange === deliveryOptions.cantDeliver && distance <= 10) {
            setHideCard(false);
        }
        else if ((elementData?.deliveryRange === deliveryOptions.localDeliver ||
            elementData?.deliveryRange === 'सिर्फ़ लोकल में डिलीवरी करवा सकते'
        )
            && (distance <= elementData?.deliveryKm + 10 || (!elementData?.deliveryKm && distance <= 20))) {
            setHideCard(false)
        }
    }, [elementData, distance])

    const redirectToPostHandler = () => {
        if (mixpanel?.get_distinct_id()) {
            mixpanel.track('Clicked on Post in KBC', {
                screen_name: elementData?.quantity ? 'KBC Requirements' : 'KBC Posts',
                post_id: id,
                post_type: elementData?.quantity ? 'KBC Requirement Post' : 'KBC Sell Post',
                post_headline: elementData?.headline && elementData.headline !== "" ? elementData?.headline : "",
                post_text: elementData?.text
            });
        }

        if (window.webengage) {
            window.webengage.track('Clicked on Post in KBC', {
                screen_name: elementData?.quantity ? 'KBC Requirements' : 'KBC Posts',
                post_id: id,
                post_headline: elementData?.headline && elementData.headline !== "" ? elementData?.headline : "",
                post_type: elementData?.quantity ? 'KBC Requirement Post' : 'KBC Sell Post',
                post_text: elementData?.text
            });
        }
        navigate(`/marketPlace2/detail/?postId=${id}&isBuy=${isBuy}&name=${visitingUser?.name}&scrollPosition=${window.scrollY}`)
    }

    const sendImpressionEvent = (id, uid, elementData) => {
			if (cache?.post_id[id]) return;

			mixpanel.track('kbc card viewed', {
				post_id: id,
				post_headline:
					elementData?.headline && elementData.headline !== '' ? elementData?.headline : '',
				content:
					elementData?.packingDetails && elementData?.packingDetails[selectedPacking]?.content
						? elementData?.packingDetails[selectedPacking]?.content
						: content,
				user_id: uid,
			});
			cache.post_id[id] = id;
		};

    useEffect(() => {
        if (inView) {
            sendImpressionEvent(id, uid, elementData);
        }
    }, [inView]);

    return (
        <>
            {!hideCard || uid === 'XkOExufEktP25tJlsZ1Cr5jFpD93' ? <div
                className='mb-3' ref={ref}>
                <Card outline={false} className="px-3 pt-3 pb-4 bg-white text-gray-950 rounded-none shadow">
                    <h1 onClick={() => {
                        redirectToPostHandler()
                    }}
                        style={{ fontFamily: "hind" }} className="text-[20px] mb-0">
                        {elementData?.headline && elementData.headline !== "" ? elementData?.headline : "-"}
                    </h1>
                    <div
                        onClick={() => {
                            redirectToPostHandler()
                        }}
                        className="flex justify-start items-center bg-white w-full pb-1">
                        <div className={`flex text-[12px] text-left`}>
                            <p className='w-full text-gray-950 flex items-center'>
                                <div className="block">
                                    <BsShop className='pr-1' size={15} />
                                </div>
                                <span>
                                    {elementData?.user?.store_name?.split('')?.length > 30
                                        ? elementData?.user?.store_name.substring(0, 20) + '...'
                                        : elementData?.user?.store_name}
                                </span>
                                <span className='px-1 text-gray-400 text-xl'>|</span>
                            </p>
                        </div>


                        {
                            deliveryStatus ?
                                (<>

                                    <div className="flex-grow flex-shrink w-[8rem] min-w-[8rem] text-[12px] text-left">
                                        <p className='w-full flex items-center text-left text-gray-950 justify-start'>
                                            <div className="block">
                                                <img src={truck} alt="" className='pr-1' />
                                            </div>
                                            <span>
                                                डिलीवरी करवा सकते है
                                            </span>
                                        </p>
                                    </div>
                                </>)
                                :
                                <></>
                        }
                    </div>

                    <div className="mx-1 justify-start flex text-[#293c4bd9]">

                        {elementData?.packingDetails && elementData?.packingDetails?.length > 0
                            && elementData?.packingDetails[selectedPacking].packingImage !== "" ?
                            <div
                                onClick={() => {
                                    redirectToPostHandler()
                                }}
                                className="w-[40%]">

                                <Card outline={false} color="light" className="border-none mr-3 overflow-hidden bg-black w-[110px] max-w-[110px] h-[170px] max-h-[170px] rounded-xl">
                                    <img
                                        className='mx-auto my-auto object-contain'
                                        src={elementData?.packingDetails && elementData?.packingDetails?.length > 0 ?
                                            elementData?.packingDetails[selectedPacking].packingImage :
                                            elementData?.media_urls && elementData?.media_urls?.length > 0 ? elementData?.media_urls[0] :
                                                ""
                                        }
                                        alt="" />
                                </Card>
                            </div> :
                            elementData?.media_urls && elementData?.media_urls?.length > 0 ?
                                <div
                                    onClick={() => {
                                        redirectToPostHandler()
                                    }}
                                    className="w-[40%]">

                                    <Card outline={false} color="light" className="border-none mr-3 overflow-hidden bg-black w-[110px] max-w-[110px] h-[170px] max-h-[170px] rounded-xl">
                                        <img
                                            className='mx-auto my-auto object-contain'
                                            src={elementData?.packingDetails && elementData?.packingDetails?.length > 0 ?
                                                elementData?.packingDetails[selectedPacking].packingImage :
                                                elementData?.media_urls && elementData?.media_urls?.length > 0 ? elementData?.media_urls[0] :
                                                    ""
                                            }
                                            alt="" />
                                    </Card>
                                </div> :
                                <></>
                        }
                        {/* <div
                            onClick={() => {
                                redirectToPostHandler()
                            }}
                            className="w-[40%]">

                            <Card outline={false} color="light" className="border-none overflow-hidden bg-black w-[110px] max-w-[110px] h-[170px] max-h-[170px] rounded-xl">
                                <img
                                    className='mx-auto my-auto object-contain'
                                    src={elementData?.packingDetails && elementData?.packingDetails?.length > 0 ?
                                        elementData?.packingDetails[selectedPacking].packingImage :
                                        elementData?.media_urls && elementData?.media_urls?.length > 0 ? elementData?.media_urls[0] :
                                            ""
                                    }
                                    alt="" />
                            </Card>
                        </div> */}
                        <div className="w-full">
                            <div className="flex flex-wrap justify-start items-center">
                                {elementData?.packingDetails?.length > 0 ?
                                    <div
                                        onClick={() => {
                                            redirectToPostHandler()
                                        }}
                                        className='min-w-[20%] flex items-center'>
                                        <h1 style={{ fontSize: "1rem" }} className="font-bold text-center text-[18px] text-gray-950">
                                            {/* ₹198/- */}
                                            {elementData?.packingDetails[selectedPacking].packingPrice}
                                        </h1>
                                    </div>
                                    :
                                    <></>
                                }

                                <div className='flex pl-2 justify-end gap-2'>
                                    <button onClick={() => {
                                        handleLikeDisLikePost("up_vote")
                                    }} style={{
                                        border: upvoteDownvotePost.upvoted ? "1.2px solid rgba(0, 152, 26, 1)" : "1px solid rgba(227, 227, 227, 1)",
                                        background: upvoteDownvotePost.upvoted ? "rgba(229, 255, 234, 1)" : "white"
                                    }} className={`rounded-lg flex text-green-600 h-12 justify-center items-center p-1`}>
                                        <div>
                                            <IoMdThumbsUp size={20} />
                                            <span className='text-[12px]'>{upvoteDownvoteCount?.upvote}</span>
                                        </div>
                                    </button>
                                    <button onClick={() => {
                                        handleLikeDisLikePost("down_vote")
                                    }} style={{
                                        border: upvoteDownvotePost.downvoted ? "1.2px solid rgba(165, 0, 0, 1)" : "1px solid rgba(227, 227, 227, 1)",
                                        background: upvoteDownvotePost.downvoted ? "rgba(255, 236, 236, 1)" : "white"
                                    }} className='rounded-lg flex text-red-900 h-12 justify-center items-center p-1'>
                                        <div>
                                            <IoMdThumbsDown size={20} />
                                            <span className='text-[12px]'>{upvoteDownvoteCount?.downvote}</span>
                                        </div>
                                    </button>
                                </div>
                            </div>


                            {/* dropdown */}
                            <div class="flex w-full justify-start items-center my-2 text-left">
                                {elementData?.packingDetails?.filter(pp => pp.packingSize !== "")?.map((item, idx) => {
                                    return <button
                                        key={idx}
                                        onClick={() => {
                                            setSelectedPacking(idx)
                                            mixpanel.track('Post size clicked', {
                                                post_id: id,
                                                post_headline: elementData?.headline && elementData.headline !== "" ? elementData?.headline : "",
                                                content: elementData?.packingDetails && elementData?.packingDetails[selectedPacking]?.content ?
                                                    elementData?.packingDetails[selectedPacking]?.content :
                                                    content,
                                            });
                                        }}
                                        style={{
                                            fontFamily: "hind",
                                            border: selectedPacking === idx ? "1px solid rgba(209, 155, 0, 1)" : "1px solid rgba(227, 227, 227, 1)"
                                        }} className={`${selectedPacking === idx ? "bg-[#fbe194d9]" : "bg-white"} p-1 mr-1 text-[10px] font-[500] rounded-lg w-full min-w-[3rem]`}>
                                        {item?.packingSize}
                                    </button>
                                })}

                            </div>

                            <div
                                onClick={() => {
                                    redirectToPostHandler()
                                }}


                                className='text-[1rem] w-full max-w-full no-underline text-gray-950'>

                                {elementData?.packingDetails?.length > 0 ?
                                    (elementData?.packingDetails[selectedPacking]?.content?.length > 100
                                        ? <>{elementData?.packingDetails[selectedPacking]?.content?.substring(0, 100)?.split('\n')?.filter(lit => lit !== "")?.map(str => <>{str}<br /></>)}...</> :
                                        <>{elementData?.packingDetails[selectedPacking]?.content?.substring(0, 100)?.split('\n')?.filter(lit => lit !== "")?.map(str => <>{str}<br /></>)}</>
                                    ) :
                                    ""
                                }
                                {!elementData?.packingDetails?.length ? content?.length > 100 ?
                                    `${content?.substring(0, 100)}.... ` :
                                    content : ""
                                }

                                {!elementData?.packingDetails?.length ? content.length > 100 ? (
                                    <div
                                        className="my-1"
                                        style={{ color: '#0169BB', textDecoration: 'underline', textAlign: 'left' }}
                                    >
                                        {' '}
                                        पूरी जानकारी देखें
                                    </div>
                                ) : "" : ""
                                }

                                {elementData?.packingDetails?.length &&
                                    elementData?.packingDetails[selectedPacking]?.content?.length > 100
                                    ? <div
                                        className="my-1"
                                        style={{ color: '#0169BB', textDecoration: 'underline', textAlign: 'left' }}
                                    >
                                        {' '}
                                        पूरी जानकारी देखें
                                    </div> : ""
                                }

                            </div>
                        </div>

                    </div>
                    <div className='w-full pt-3' style={{ flexDirection: 'row' }}>
                        <div
                            style={{ display: 'flex', justifyContent: 'space-between' }}
                        // onClick={handleOnPostClick}
                        >
                            <div
                                onClick={() => {
                                    mixpanel.track('Clicked on Whatsapp in KBC', {
                                        post_text: elementData?.text,
                                        post_id: id,
                                        post_headline: elementData?.headline && elementData.headline !== "" ? elementData?.headline : "",
                                        screen_name: 'KBC Posts',
                                    });
                                    if (window.webengage) {
                                        window.webengage.track('Clicked on Whatsapp in KBC', {
                                            post_text: elementData?.text,
                                            post_id: id,
                                            post_headline: elementData?.headline && elementData.headline !== "" ? elementData?.headline : "",
                                            screen_name: 'KBC Posts',
                                        });
                                    }
                                    window.open(
                                        `https://api.whatsapp.com/send?phone=91${elementData?.user?.phone_number}&text=${`नमस्ते जी, ${visitingUser?.name && visitingUser?.name !== '' ? `मैं ${visitingUser?.name} हूँ।` : ``} मुझे आपका नंबर KIRANA CLUB से मिला। में आपसे प्रोडक्ट खरीदने में इच्छुक हूँ।%0a
										*प्रोडक्ट - ${elementData?.headline}*%0a${dynamicLink ?? ""}`}`
                                    );
                                }}
                                style={{
                                    borderRadius: 8,
                                    borderWidth: 1,
                                    border: "1.2px solid rgba(0, 152, 26, 1)",
                                    width: '82%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    minWidth: "82%"
                                }}
                                className='py-3'
                            >
                                <h3
                                    style={{
                                        fontSize: 16,
                                        margin: 0,
                                        textAlign: 'center',
                                        fontWeight: 'bold',
                                    }}
                                    className='flex text-green-600 bg-white justify-center items-center'
                                >
                                    <FaWhatsapp size={20} className='mr-2' /> Whatsapp करें
                                </h3>
                            </div>
                            <button
                                style={{ maxWidth: "15%" }}
                                className='flex bg-[#D45339] text-white rounded-lg w-full ml-2 justify-center items-center p-2'
                                onClick={(e) => {
                                    if (mixpanel.get_distinct_id()) {
                                        mixpanel.track("Clicked Call Button on Khareed Screen", {
                                            post_text: elementData?.text,
                                            post_id: id,
                                            post_headline: elementData?.headline && elementData.headline !== "" ? elementData?.headline : "",
                                            screen_name: 'KBC Posts',
                                        });
                                    }

                                    if (window.webengage) {
                                        window.webengage.track("Clicked Call Button on Khareed Screen", {
                                            post_text: elementData?.text,
                                            post_id: id,
                                            post_headline: elementData?.headline && elementData.headline !== "" ? elementData?.headline : "",
                                            screen_name: 'KBC Posts',
                                        });
                                    }
                                    e.stopPropagation();

                                    if (elementData?.user?.phone_number) {
                                        window.open(`tel:${elementData?.user?.phone_number}`)
                                    }
                                }}
                            >
                                <FaPhoneVolume size={25} />
                            </button>
                        </div>
                    </div>
                </Card>

                <BottomSheetSampark2
                    uid={uid}
                    mixpanel={mixpanel}
                    setOpen={setBottomSheet}
                    postID={id}
                    bottomSheet={bottomSheet}
                    type={elementData?.quantity ? 'sell' : 'buy'}
                    handleBottomSheet={handleBottomSheet}
                    contact={elementData?.user?.phone_number}
                    headline={elementData?.user?.store_name}
                    id={id}
                />
            </div> :
                <></>}
        </>
    )
}

export default RateCard3