export const quizQuestionSelector = (state) => state?.quiz?.questions;

export const questionLoadingnSelector = (state) => state?.quiz?.questionLoading;

export const totalPointsSelector = (state) => state?.quiz?.totalPoints;

export const noOfCorrectQuestionSelector = (state) => state?.quiz?.noOfCorrectQuestion;

export const activeQuestionSelector = (state) => state?.quiz?.initialQuestion;

export const leaderBoardSelector = (state) => state?.quiz?.leaderBoard;

export const leaderBoardPoints = (state) => state?.quiz?.leaderBoardPoints;

export const selfRankSelector = (state) => state?.quiz?.selfRank;

export const selfOverallRankSelector = (state) => state?.quiz?.overAllRank;

export const selfOverallPointsSelector = (state) => state?.quiz?.overAllScore;

export const leaderBoardLoadingSelector = (state) => state?.quiz?.leaderBoardLoading;

export const zoneSelector = (state) => state?.quiz?.zone;

export const zoneNameSelector = (state) => state?.quiz?.zoneName;

export const userPageStateSelector = (state) => state?.quiz?.userPageState;

export const dynamicLinkSelector = (state) => state?.quiz?.dynamicLink;

export const dynamicLinkLoadingSelector = (state) => state?.quiz?.dynamicLinkLoading;

export const sessionPointsSelector = (state) => state?.quiz?.sessionPoints;
