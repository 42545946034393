import { createSlice } from '@reduxjs/toolkit';
import { getSurveyMetaDetails, pngSurveyQuestionFetch, submitpngQuestion, surveyQuestionFetch } from './thunk';
import { findIndex } from 'lodash';

const initialState = {
	activeStep: 1,
	questionSolved: 0,
	activeQuestionId: 0,
	nextQuestion: 0,
	previousAnswers: null,
	pngQuestionFetchLoading: false,
	pngQuestions: [],
	survey_id: null,
	apke_baare_mei: 0,
	stock_ke_baare_mei: 0,
	question_context: 'आपके बारे में',
	survey_name: '',
	prushti: 0,
	activeQuestion: null,
	activeQuestionFetchLoading: false,
	questionsNodeLength: 0,
	surveyActiveQuestions: {},
	surveyMetaDetails: null,
	surveyMetaDetailsLoading: false,
	error: '',
	showEndText:false
};

const pngSurveySlice = createSlice({
	name: 'pngSurvey',
	initialState,
	reducers: {
		changeActiveState(state, { payload }) {
			state.activeStep = payload;
		},
		changeActiveQuestionId(state, { payload }) {
			state.activeQuestionId = payload;
		},
		setActiveQuestionNull(state) {
			state.activeQuestion = null;
			state.surveyActiveQuestions[state.survey_id] = null;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(pngSurveyQuestionFetch.pending, (state, action) => {
			state.pngQuestionFetchLoading = true;
		});
		builder.addCase(pngSurveyQuestionFetch.fulfilled, (state, { payload }) => {
			state.pngQuestions = payload?.data?.questions;
			state?.pngQuestions?.forEach((item) => {
				if (item?.question_context === 'आपके बारे में') {
					state.apke_baare_mei = state.apke_baare_mei + 1;
				} else if (item?.question_context === 'स्टॉक के बारे में') {
					state.stock_ke_baare_mei = state.stock_ke_baare_mei + 1;
				} else if (item?.question_context === 'पुष्टि') {
					state.prushti = state.prushti + 1;
				}
			});
			if (payload?.survey_id) {
				state.survey_id = payload?.survey_id;
			}
			state.survey_name = payload?.data?.questions[0]?.survey_name;
			if (!state.surveyActiveQuestions[payload?.survey_id]) {
				state.activeQuestion = payload?.data?.questions[payload?.data?.start_question];
				state.surveyActiveQuestions[payload?.survey_id] = payload?.data?.questions[payload?.data?.start_question];
			} else{
				state.activeQuestion = state.surveyActiveQuestions[payload?.survey_id]
			}
			state.pngQuestionFetchLoading = false;
		});
		builder.addCase(pngSurveyQuestionFetch.rejected, (state, { payload }) => {
			state.error = payload;
			state.pngQuestionFetchLoading = false;	
		});

		builder.addCase(getSurveyMetaDetails.pending, (state, action) => {
			state.surveyMetaDetailsLoading = true;
		});

		builder.addCase(getSurveyMetaDetails.fulfilled, (state, { payload }) => {
			state.surveyMetaDetails = payload;	
			if (payload?.survey_id) {
				state.survey_id = payload?.survey_id;
			}
			if(payload?.survey_name){
				state.survey_name = payload?.survey_name
			}
			state.surveyMetaDetailsLoading = false;
		});

		builder.addCase(getSurveyMetaDetails.rejected, (state, { payload }) => {
			state.error = payload;
			state.surveyMetaDetailsLoading = false;
		});

		builder.addCase(surveyQuestionFetch.pending, (state, action) => {
			state.activeQuestionFetchLoading = true;
		});

		builder.addCase(surveyQuestionFetch.fulfilled, (state, { payload }) => {
			if (!state.survey_name || state.survey_name === ''){
				state.survey_name = payload?.data?.question?.survey_name
			}
			if (!state.surveyActiveQuestions[payload?.survey_id]) {
				state.activeQuestion = {...payload?.data?.question, node_id: payload?.node_id};
				state.surveyActiveQuestions[payload?.survey_id] = {...payload?.data?.question, node_id: payload?.node_id};
			} else{
				state.questionSolved = payload?.data?.question?.id;
				state.activeQuestion = state.surveyActiveQuestions[payload?.survey_id]
			}
			state.questionsNodeLength = payload?.total_question;
			if (payload?.survey_id){
				state.survey_id = payload?.survey_id
			}
			state.activeQuestionFetchLoading = false;
		});

		builder.addCase(surveyQuestionFetch.rejected, (state, { payload }) => {
			state.error = payload;
			state.activeQuestionFetchLoading = false;
		});

		builder.addCase(submitpngQuestion.pending, (state, action) => {
			state.pngQuestionFetchLoading = true;
		});
		builder.addCase(submitpngQuestion.fulfilled, (state, { payload }) => {

			state.question_context = payload.question_context;

			if (state.survey_id >= 6) {
				state.activeQuestion = {...payload?.data?.question, node_id: payload?.node_id};
				state.surveyActiveQuestions[state.survey_id] = {...payload?.data?.question, node_id: payload?.node_id};
				state.questionSolved = state.questionSolved + 1;
				if(!payload?.node_id){
					state.showEndText = false
					state.nextQuestion = -1
				}
			}
			else if (payload?.question_id === 2 && state.survey_id === 5) {
				if (payload.response.includes('डिटर्जेंट पाउडर, कपड़े धोने का साबुन')) {
					let index = state.pngQuestions?.findIndex((item) => item?.id === 3);
					state.nextQuestion = 3
					if (index !== -1) {
						state.surveyActiveQuestions[state.survey_id] = state.pngQuestions[index];
						state.activeQuestion = state.pngQuestions[index];
					}
				} else {
					let index = state.pngQuestions?.findIndex((item) => item?.id === 10);
					state.nextQuestion = 10
					if (index !== -1) {
						state.surveyActiveQuestions[state.survey_id] = state.pngQuestions[index];
						state.activeQuestion = state.pngQuestions[index];
						state.showEndText = true
					}
				}
			}
			else if (payload?.question_id === 3 && state.survey_id === 5) {
				let indexof9thQues = state.pngQuestions?.findIndex((item) => item?.id === 8);
				let indexof8thQues = state.pngQuestions?.findIndex((item) => item?.id === 7);
				if (payload.response.includes('लिक्विड डिटर्जेंट')) {
					let index = state.pngQuestions?.findIndex((item) => item?.id === 4);
					state.nextQuestion = 4
					const quesArray = [...state.pngQuestions]
					quesArray[index].next_question = 8
					quesArray[indexof9thQues].next_question = 5
					quesArray[indexof8thQues].next_question = 9
					state.pngQuestions = quesArray
					if (index !== -1) {
						state.surveyActiveQuestions = state.pngQuestions[index];
						state.activeQuestion = state.pngQuestions[index];
					}
				} else {
					let index = state.pngQuestions?.findIndex((item) => item?.id === 5);
					state.nextQuestion = 5
					const quesArray = [...state.pngQuestions]
					quesArray[indexof8thQues].next_question = 9
					state.pngQuestions = quesArray
					if (index !== -1) {
						state.surveyActiveQuestions = state.pngQuestions[index];
						state.activeQuestion = state.pngQuestions[index];
					}
				}
			}
			else if (payload?.question_id === 6 && state.survey_id === 5) {
				const optionsMap = {
					"Previous": 6,
					"Ariel": 11,
					"Tide": 12,
					"Surf Excel": 13,
					"Rin": 14,
					"Wheel": 15,
					"Ghadi": 16,
				}
				const respArray = JSON.parse(payload?.response)
				const filteredArray = respArray.filter(item => optionsMap.hasOwnProperty(item));
				const parsedArray = filteredArray

				let initNextQuestionId = payload?.next_question_id
				if (Array.isArray(parsedArray)) {
					const sortedArray = parsedArray.sort((a, b) => {
							return (optionsMap[a] || 0) - (optionsMap[b] || 0);
					});
					sortedArray.unshift("Previous")

					let pngQuestions = [...state.pngQuestions]
					for (let i = 0; i < sortedArray?.length; i++) {
						let idx = state.pngQuestions?.findIndex((item) => item?.id === optionsMap[sortedArray[i]])
						if (optionsMap[sortedArray[i]]) {
							if (i === sortedArray?.length - 1) {
								pngQuestions[idx].next_question = initNextQuestionId
							} else {
								pngQuestions[idx].next_question = optionsMap[sortedArray[i + 1]]
							}

						}
					}
					state.pngQuestions = pngQuestions
					state.nextQuestion = sortedArray?.length >= 2 ? optionsMap[sortedArray[1]] : initNextQuestionId
					let index = sortedArray?.length >= 2 ? state.pngQuestions?.findIndex((item) => item?.id === optionsMap[sortedArray[1]]) : state.pngQuestions?.findIndex((item) => item?.id === initNextQuestionId)
					if (index !== -1) {
						state.surveyActiveQuestions = state.pngQuestions[index];
						state.activeQuestion = state.pngQuestions[index];
					}
				} else {
					console.error("The provided data is not an array.");
				}
				// const sortedArray = payload.response.sort((a, b) => {
				// 	return optionsMap[a] - optionsMap[b];
				// });
				// console.log('sortedArray',sortedArray)


			}
			else {
				state.nextQuestion = payload.next_question_id;
				let index = state.pngQuestions?.findIndex((item) => item?.id === payload?.next_question_id);
				if (index !== -1) {
					state.surveyActiveQuestions = state.pngQuestions[index];
					state.activeQuestion = state.pngQuestions[index];
				}
			}
			state.previousAnswers = {
				...state.previousAnswers,
				[payload?.question_id]: { answer: payload.response },
			};
			// state.questionSolved = state.questionSolved + 1;

			// if (payload.question_context === 'आपके बारे में') {
			// 	state.apke_baare_mei = state.apke_baare_mei - 1;
			// 	if (state.apke_baare_mei === 0) {
			// 		state.activeStep = 2;
			// 	}
			// } else if (payload.question_context === 'स्टॉक के बारे में') {
			// 	state.stock_ke_baare_mei = state.stock_ke_baare_mei - 1;
			// 	if (state.stock_ke_baare_mei === 0) {
			// 		state.activeStep = 3;
			// 	}
			// } else if (payload.question_context === 'पुष्टि') {
			// 	state.prushti = state.prushti - 1;
			// 	if (state.prushti === 0) {
			// 		state.activeStep = 4;
			// 	}
			// }

			state.pngQuestionFetchLoading = false;
		});
		builder.addCase(submitpngQuestion.rejected, (state, { payload }) => {
			state.error = payload;
			state.pngQuestionFetchLoading = false;
		});
	},
});

export const { changeActiveState, changeActiveQuestionId, setActiveQuestionNull } =
	pngSurveySlice.actions;

export default pngSurveySlice.reducer;
