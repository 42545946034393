import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import axios from 'axios';
import { ImCross } from 'react-icons/im';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Image from '../components/ui/Image';
import uploadFileToBlob from '../config/azureconfig';
import baseAxios from '../utils/baseAxios';
import Header from './Header';
import TextInput from './TextInput';
import mixpanel from '../utils/mixpanel';
import { toast } from 'react-toastify';
import RippleButton from '../components/RippleButton';
import BottomSheetForSamplePosts from './BottomSheetForSamplePosts';
import personIcon from '../assests/personIcon.png';

import './index.css';
import SubmitPostModal from './SubmitPostModal';
import UserDetailHeader from './UserDetailHeader';
import UploadLoader from './UploadLoader';
import {
    isPostTypeMandiRate,
    isPostTypeText,
    isPostTypeVideo,
} from '../utils/newPostRevamp/utilFunction';
import { IoMdWarning } from 'react-icons/io';
import { Col, Row } from 'reactstrap';
import { openMediaUploader } from '../utils/postMessage';
import getStorageName from '../utils/getStorageNameforAzure';
import { CircularProgress, MenuItem, Select, TextField } from '@mui/material';
import { Oval } from 'react-loader-spinner';

import Lottie from 'react-lottie';
import * as animationData from '../assests/lottie/successLottie.json';
import { navigateToScreen } from '../utils/postMessage';
import Carousel from 'react-multi-carousel';
import { IoMdCloseCircle } from 'react-icons/io';
import { Modal } from 'react-bootstrap';
import {
    AiOutlineClockCircle,
    AiOutlinePlayCircle,
    AiFillPlayCircle,
    AiFillLike,
} from 'react-icons/ai';
import 'react-multi-carousel/lib/styles.css';
import SVGIconSelector, { SVGIcons } from '../assests/svg/SVGIconSelector';
import uploadFileToBlobFromApi from '../config/azure';
import { SCHEME_TAG } from './utils';

const { fetchTopUGCPosts } = require('./FetchTopUGCPosts');

const imageUrls1 = [
    'https://d2rstorage1.blob.core.windows.net/news/December/21/81bde543-9b20-4842-8872-6e844dd752f3/banner/images/Card1.webp',
    'https://d2rstorage1.blob.core.windows.net/news/December/21/bbd4c15b-0ad8-44a5-8481-839f4f5cff0f/banner/images/Card2.webp',
];
const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        paritialVisibilityGutter: 60,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        paritialVisibilityGutter: 50,
    },
    mobile: {
        breakpoint: { max: 580, min: 0 },
        items: 1,
        paritialVisibilityGutter: 100,
    },
};
const responsive1 = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        paritialVisibilityGutter: 60,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        paritialVisibilityGutter: 50,
    },
    mobile: {
        breakpoint: { max: 580, min: 0 },
        items: 2,
        paritialVisibilityGutter: 10,
    },
};

async function checkMediaURL(url) {
    try {
        const response = await axios.head(url);
        const contentType = response?.headers['content-type'];

        // Check if the content type is a valid media type
        if (contentType?.startsWith('video/') || contentType?.startsWith('image/')) {
            return true;
        } else {
            return false;
        }
    } catch (err) {
        return false;
    }
}

let retryCount = 0;

const plus = require('../assests/plus.png');
const bulb = require('../assests/bulb.png');

const MAX_VIDEO_UPLOAD_RETRIES = 5;
const headerTxtForVideoPost = 'वीडियो में बताएं';
const headerTxtForTextPost = 'लिखित में बताएं';
const headerTxtForMandiPost = 'अपने एरिया का रेट बताये';
const headerTxtForSchemePost = 'स्कीम बताएं'
const headerTextForKiranaJankari = 'किराना जानकारी';
const SAMPLE_POSTS = '#sample-posts';
const asliNakliBanner = require('../assests/asliNakli.png');
const SPECIAL_USERS = ['qhhs2Q9TtnV2xLm4eewFUsY83U22'];

const PRODUCT_NAME_MAX_LENGTH_LIMIT = 100;
const SCHEME_DESCRIPTION_MAX_LENGTH_LIMIT = 300;
const SCHEME_POST = 'scheme';

const SCHEME_CATEGORIES = [
    'इस हफ्ते',
    'पिछले 15 दिन में',
    'पिछले 30 दिन में',
    '30 दिन से पहले'
]

const SCHEME_SOURCE_OPTIONS = [
    'होलसेलर से ',
    'डिस्ट्रीब्यूटर से',
    'ऑनलाइन एप से',
]

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
};

const placeholderValues = [
    `उदाहरण : दुकान में Sale
	<br /> बढ़ने के 5 tips . . .`,
    `उदाहरण : अच्छे Margin वाले<br/> नए प्रोडक्ट बताएं...`,
    `उदाहरण : नया Offer/ नयी <br/>Scheme बताएं...`,
    `उदाहरण : Mandi की जानकारी<br/> यहाँ दें...`,
    `उदाहरण : गर्मी में बिकने वाले<br/> Item के बारे में बताएं...`,
];
const postIdeas = [
    ` दुकान में Sale बढ़ने के 5 tips . . .`,
    ` अच्छे Margin वाले नए प्रोडक्ट बताएं...`,
    ` नया Offer/ नयी Scheme बताएं...`,
    ` Mandi की जानकारी यहाँ दें...`,
    ` गर्मी में बिकने वाले Item के बारे में बताएं...`,
];

export function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
}

const getQueryKeyValue = (str) => {
    const pos = str.indexOf('=');

    if (str.includes('specific'))
        return {
            specific: str.substring(pos + 1, str.length),
        };

    if (str.includes('type'))
        return {
            type: str.substring(pos + 1, str.length),
        };
    return null;
};

function SchemePostCreate() {
    const location = useLocation();
    const navigate = useNavigate();

    const [showModal, setShowModal] = useState(false);

    const userId = useMemo(
        () => window?.sessionStorage?.getItem('data') ?? location?.state?.user ?? '',
        [location?.state?.user]
    );

    const [searchParams, setSearchParams] = useSearchParams();
    const header = searchParams.get('header');
    const tag = SCHEME_TAG;

    const [user, setUser] = useState('');
    const [postType, setPostType] = useState('');
    const [specific, setSpecific] = useState(SCHEME_POST);

    const [isLoading, setIsLoading] = useState(false);

    const imageInputRef = useRef();
    const videoInputRef = useRef();
    const errorTxtRef = useRef(null);
    const videoEl = useRef(null);

    const productImageInputRef = useRef();

    const [selectedProductImage, setSelectedProductImage] = useState({});
    const [productImageUrl, setProductImageUrl] = useState(-2);
    const [schemeProductName, setScehemeProductName] = useState('');
    const [schemeCategory, setScehemeCategory] = useState('');
    const [schemeSource, setSchemeSource] = useState('')

    const [selectedImage, setSelectedImage] = useState({});
    const [editorContent, setEditorContent] = useState('');
    const [selectedVideo, setSelectedVideo] = useState({});
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [placeholder, setPlaceholder] = useState(placeholderValues[0]);
    const [bottomSheetToggle, setBottomSheetToggle] = useState(false);
    const [isVisible, setIsVisible] = useState(true);
    const [isSocialPostModalVisible, setIsSocialPostModalVisible] = useState(true);
    const [type, setType] = useState(0);
    const [percentage, setPercentage] = useState(0);
    const [showSuccessLottie, setShowSuccessLottie] = useState(false);
    const [pastedEditorContent, setPastedEditorContent] = useState([]);
    const [imageUrl, setImageUrl] = useState(-2);
    const [videoUrl, setVideoUrl] = useState(-2);
    const [loading, setLoading] = useState(null);
    const [imageUploadProgress, setImageUploadProgress] = useState(0);
    const [currPostIdea, setPostIdea] = useState(postIdeas[0]);
    const [focussedTextArea, setFocussedTextArea] = useState(false);
    const [imageUrls, setImageUrls] = useState([]);
    const [compressionLink, setcompressionLink] = useState(false);
    const [isPostUploaded, setIsPostUploaded] = useState(true);
    // useEffect(() => {
    // 	fetchTopUGCPosts(setImageUrls);
    // }, []);
    const fetchUser = useCallback(async () => {
        try {
            setIsLoading(true);
            mixpanel.track('ScreenView', {
                screen_name: location?.state?.specific === 'mandi' ? 'Mandi Rate Post' :
                    (location?.state?.specific === SCHEME_POST || tag === SCHEME_TAG) ? 'Scheme Content Post' : 'General Content Post',
            });
            window.webengage.track('ScreenView', {
                screen_name: location?.state?.specific === 'mandi' ? 'Mandi Rate Post' :
                    (location?.state?.specific === SCHEME_POST || tag === SCHEME_TAG) ? 'Scheme Content Post' : 'General Content Post',
            });

            const res = await baseAxios({
                endPoint: 'userProfile',
                data: {
                    type: 'getUserProfile',
                    selfUserId: userId ?? 'jWjeLoO8C3NG9fmFm1icAq1c33E2',
                    uid: userId ?? 'jWjeLoO8C3NG9fmFm1icAq1c33E2',
                },
            });
            setUser(res?.result?.data);
        } catch (err) {
            console.log('error in fetching the user: ', err);
        } finally {
            setIsLoading(false);
        }
    }, [userId]);

    const toggleModal = () => setIsVisible(!isVisible);
    const toggleSocialModal = () => {
        setIsSocialPostModalVisible(!isSocialPostModalVisible);
    };

    useEffect(() => {
        setPostType(location?.state?.postType ?? 'text');
        fetchUser();
        const obj = getQueryKeyValue(location?.search);
        if (obj) {
            if (obj?.type) setPostType(obj?.type ?? 'text');
        }
    }, [fetchUser, location?.search, location?.state?.postType, location?.state?.specific]);

    const shuffle = useCallback(() => {
        const index = Math.floor(Math.random() * 5);

        setPostIdea(postIdeas[index]);
    }, []);

    // added a event listner from react native to setLoading to false whenever the compression and uploading get completed
    useEffect(() => {
        window.addEventListener('message', (message) => {
            setLoading(message?.data?.data?.mediaData?.loading);
            if (message?.data?.data?.mediaData?.mediaUrl) {
                if (
                    window.sessionStorage.getItem('type') === 'photo' ||
                    location?.state?.postType === 'mandi'
                ) {
                    setImageUrl(message?.data?.data?.mediaData?.mediaUrl);
                }
                if (
                    location?.state?.postType === 'video' ||
                    window.sessionStorage.getItem('type') === 'video'
                ) {
                    setVideoUrl(message?.data?.data?.mediaData?.mediaUrl);
                }
            }
        });
        return () => {
            window.removeEventListener('message', () => { });
        };
    }, []);

    useEffect(() => {
        const intervalID = setInterval(shuffle, 2500);

        return () => clearInterval(intervalID);
    }, [shuffle]);

    useEffect(() => {
        const index = Math.floor(Math.random() * 5);
        setPlaceholder(placeholderValues[index]);
    }, []);

    useEffect(() => {
        if (isPostTypeVideo(postType)) {
            mixpanel.track('Video Card Screenview');
        } else {
            mixpanel.track('Text Card Screenview');
        }
    }, [postType]);

    const handeSamplePostView = useCallback(() => {
        navigate(SAMPLE_POSTS, { state: { user, postType, specific }, replace: false });
    }, [navigate, postType, user, specific]);

    const removeHash = useCallback(() => {
        if (location.hash === SAMPLE_POSTS) navigate(-1);
    }, [location.hash, navigate]);

    useEffect(() => {
        if (location.hash === '') {
            setBottomSheetToggle(false);
        } else if (location.hash === SAMPLE_POSTS) {
            setBottomSheetToggle(true);
        }
    }, [location.hash]);

    const handleSetPercentage = (value) => {
        setPercentage(prev => value > prev ? value : prev);
    }

    const handleSubmit = useCallback(
        async (position) => {
            var videoUploadRetryCount = 1;
            var productImageUploadRetryCount = 1;
            var videoImageUploaded = true
            var mediaUploadFromAPi = false;
            try {
                setDisableSubmit(true);
                let attachmentType;
                let attachment;
                let video_size;
                let productImageAttachment;

                let url;
                let purl;
                const uid = window.sessionStorage.getItem('data') ?? 'jWjeLoO8C3NG9fmFm1icAq1c33E2';

                if ((specific === SCHEME_POST || tag === SCHEME_TAG) && selectedProductImage?.url) {
                    purl = await uploadFileToBlob(
                        selectedProductImage?.data,
                        'userPost',
                        uid,
                        setPercentage,
                        'user-posts'
                    );
                    while (!purl && productImageUploadRetryCount < MAX_VIDEO_UPLOAD_RETRIES) {
                        purl = await uploadFileToBlob(
                            selectedProductImage?.data,
                            'userPost',
                            uid,
                            handleSetPercentage,
                            'user-posts'
                        );
                        productImageUploadRetryCount++;
                        retryCount++
                    }
                    if (!purl || purl === "") {
                        purl = await uploadFileToBlobFromApi(
                            selectedProductImage?.data,
                            'userPost',
                            uid,
                            'user-posts'
                        )
                        mediaUploadFromAPi = true;
                    }
                    if (purl && purl !== '') {
                        const isValidUrl = await checkMediaURL(purl)
                        if (!isValidUrl) {
                            if (!purl?.includes('d2rstorage1')) {
                                purl = await uploadFileToBlobFromApi(
                                    selectedProductImage?.data,
                                    'userPost',
                                    uid,
                                    'user-posts'
                                )
                                mediaUploadFromAPi = true;
                                // check again for valid url from API upload
                                const isValidUrl2 = await checkMediaURL(purl)
                                if (!isValidUrl2) {
                                    setIsPostUploaded(false)
                                    videoImageUploaded = false;
                                    toast.error('आपकी पोस्ट अपलोड नहीं हो पायी है, दोबारा प्रयास करें।')
                                    return
                                }
                            } else {
                                setIsPostUploaded(false)
                                videoImageUploaded = false;
                                toast.error('आपकी पोस्ट अपलोड नहीं हो पायी है, दोबारा प्रयास करें।')
                                return
                            }

                        }
                    }
                    if ((!purl || purl === "") && videoUploadRetryCount >= MAX_VIDEO_UPLOAD_RETRIES) {
                        setIsPostUploaded(false)
                        videoImageUploaded = false;
                        toast.error("आपकी पोस्ट अपलोड नहीं हो पायी है, दोबारा प्रयास करें।")
                        return
                    }
                }

                if (selectedVideo?.url) {
                    retryCount++
                    url = await uploadFileToBlob(
                        selectedVideo?.data,
                        'userPost',
                        uid,
                        setPercentage,
                        'user-posts'
                    );
                    while (!url && videoUploadRetryCount < MAX_VIDEO_UPLOAD_RETRIES) {
                        url = await uploadFileToBlob(
                            selectedVideo?.data,
                            'userPost',
                            uid,
                            handleSetPercentage,
                            'user-posts'
                        );
                        videoUploadRetryCount++;
                        retryCount++
                    }
                    if (!url || url === "") {
                        url = await uploadFileToBlobFromApi(
                            selectedVideo?.data,
                            'userPost',
                            uid,
                            'user-posts'
                        )
                        mediaUploadFromAPi = true;
                    }
                    if (url && url !== '') {
                        const isValidUrl = await checkMediaURL(url)
                        if (!isValidUrl) {
                            if (!url?.includes('d2rstorage1')) {
                                url = await uploadFileToBlobFromApi(
                                    selectedVideo?.data,
                                    'userPost',
                                    uid,
                                    'user-posts'
                                )
                                mediaUploadFromAPi = true;
                                // check again for valid url from API upload
                                const isValidUrl2 = await checkMediaURL(url)
                                if (!isValidUrl2) {
                                    setIsPostUploaded(false)
                                    videoImageUploaded = false;
                                    toast.error('आपकी पोस्ट अपलोड नहीं हो पायी है, दोबारा प्रयास करें।')
                                    return
                                }
                            } else {
                                setIsPostUploaded(false)
                                videoImageUploaded = false;
                                toast.error('आपकी पोस्ट अपलोड नहीं हो पायी है, दोबारा प्रयास करें।')
                                return
                            }

                        }
                    }
                    if ((!url || url === "") && videoUploadRetryCount >= MAX_VIDEO_UPLOAD_RETRIES) {
                        setIsPostUploaded(false)
                        videoImageUploaded = false;
                        toast.error("आपकी पोस्ट अपलोड नहीं हो पायी है, दोबारा प्रयास करें।")
                        return
                    }
                }
                if (selectedImage?.url) {
                    retryCount++
                    url = await uploadFileToBlob(
                        selectedImage?.data,
                        'userPost',
                        uid,
                        setPercentage,
                        'user-posts'
                    );

                    while (!url && videoUploadRetryCount < MAX_VIDEO_UPLOAD_RETRIES) {
                        url = await uploadFileToBlob(
                            selectedImage?.data,
                            'userPost',
                            uid,
                            handleSetPercentage,
                            'user-posts'
                        );
                        videoUploadRetryCount++;
                        retryCount++;
                    }
                    if (!url || url === "") {
                        url = await uploadFileToBlobFromApi(
                            selectedImage?.data,
                            'userPost',
                            uid,
                            'user-posts'
                        )
                        mediaUploadFromAPi = true;
                    }
                    if (url && url !== '') {
                        const isValidUrl = await checkMediaURL(url)
                        if (!isValidUrl) {
                            if (!url?.includes('d2rstorage1')) {
                                url = await uploadFileToBlobFromApi(
                                    selectedImage?.data,
                                    'userPost',
                                    uid,
                                    'user-posts'
                                )
                                mediaUploadFromAPi = true;
                                // check again for valid url from API upload
                                const isValidUrl2 = await checkMediaURL(url)
                                if (!isValidUrl2) {
                                    setIsPostUploaded(false)
                                    videoImageUploaded = false;
                                    toast.error('आपकी पोस्ट अपलोड नहीं हो पायी है, दोबारा प्रयास करें।')
                                    return
                                }
                            } else {
                                setIsPostUploaded(false)
                                videoImageUploaded = false;
                                toast.error('आपकी पोस्ट अपलोड नहीं हो पायी है, दोबारा प्रयास करें।')
                                return
                            }

                        }
                    }
                    if ((!url || url === "") && videoUploadRetryCount >= MAX_VIDEO_UPLOAD_RETRIES) {
                        setIsPostUploaded(false)
                        videoImageUploaded = false;
                        toast.error("आपकी पोस्ट अपलोड नहीं हो पायी है, दोबारा प्रयास करें।")
                        return
                    }
                } else {
                    setPercentage(100);
                }
                if (selectedImage?.url) {
                    attachment = url;
                    attachmentType = 'image';
                }
                if (selectedVideo?.url) {
                    attachment = url;
                    attachmentType = 'video';
                }
                if (selectedProductImage?.url) {
                    productImageAttachment = purl;
                }
                if (imageUrl !== -2) {
                    attachment = imageUrl;
                    attachmentType = 'image';
                }
                if (videoUrl !== -2) {
                    attachment = videoUrl;
                    attachmentType = 'video';
                }

                let data;
                if (specific === SCHEME_POST || tag === SCHEME_TAG) {
                    data = {
                        uid,
                        ...(editorContent && { text: editorContent }),
                        ...(attachment && { proof_attachment: attachment }),
                        ...(attachmentType === 'video' && {
                            proof_video_length: videoEl?.current?.duration,
                            proof_video_size: parseFloat((selectedVideo?.size / 1000000).toFixed(2)),
                        }),
                        ...(attachmentType && { proof_attachment_type: attachmentType }),
                        ...(schemeProductName && { product_name: schemeProductName }),
                        ...(schemeCategory && { scheme_category: schemeCategory }),
                        ...(schemeSource && { scheme_source: schemeSource }),
                        ...(productImageAttachment && {
                            attachment: productImageAttachment,
                            attachment_type: 'image'
                        }),
                        category: '',
                        post_type: attachmentType ?? postType,
                        specific,
                        tag: tag,
                    }
                }
                else {
                    data = {
                        uid,
                        ...(editorContent && { text: editorContent }),
                        ...(attachment && { attachment }),
                        ...(attachmentType === 'video' && {
                            video_length: videoEl?.current?.duration,
                            video_size: parseFloat((selectedVideo?.size / 1000000).toFixed(2)),
                        }),
                        ...(attachmentType && { attachment_type: attachmentType }),
                        category: '',
                        post_type: attachmentType ?? postType,
                        specific,
                        tag: tag,
                    }
                }

                // baseUrl: 'https://us-central1-kc-stage-rp.cloudfunctions.net/'
                await baseAxios({
                    endPoint: 'userPost',
                    data: data,
                });

                if (specific === SCHEME_POST || tag === SCHEME_TAG) {
                    mixpanel.track('Scheme Post Submitted', {
                        proof_attachment: attachment,
                        proof_attachment_type: attachmentType,
                        text: editorContent,
                        product_name: schemeProductName,
                        scheme_category: schemeCategory,
                        scheme_source: schemeSource,
                        attachment: productImageAttachment,
                        attachment_type: 'image',
                        post_button_type: position,
                        category: '',
                        post_type: attachmentType ?? postType,
                        specific,
                        tag: tag,
                        pasteContent: pastedEditorContent,
                        ...(mediaUploadFromAPi && { media_upload_api: true }),
                        ...(attachmentType && { media_upload_retries: retryCount, }),
                        ...(attachmentType === 'video' && {
                            proof_video_length: videoEl.current.duration,
                            proof_video_size: parseFloat((selectedVideo?.size / 1000000).toFixed(2)),
                        }),
                    });

                    window.webengage.track('Scheme Post Submitted', {
                        proof_attachment: attachment,
                        proof_attachment_type: attachmentType,
                        text: editorContent,
                        product_name: schemeProductName,
                        scheme_category: schemeCategory,
                        scheme_source: schemeSource,
                        attachment: productImageAttachment,
                        attachment_type: 'image',
                        post_button_type: position,
                        category: '',
                        post_type: attachmentType ?? postType,
                        specific,
                        tag: tag,
                        pasteContent: pastedEditorContent,
                        ...(attachmentType === 'video' && {
                            proof_video_length: videoEl.current.duration,
                            proof_video_size: parseFloat((selectedVideo?.size / 1000000).toFixed(2)),
                        }),
                    });
                } else {
                    mixpanel.track('Post Submitted', {
                        attachment,
                        attachment_type: attachmentType,
                        text: editorContent,
                        post_button_type: position,
                        category: '',
                        post_type: attachmentType ?? postType,
                        specific,
                        tag: tag,
                        pasteContent: pastedEditorContent,
                        ...(mediaUploadFromAPi && { media_upload_api: true }),
                        ...(attachmentType && { media_upload_retries: retryCount, }),
                        ...(attachmentType === 'video' && {
                            video_length: videoEl.current.duration,
                            video_size: parseFloat((selectedVideo?.size / 1000000).toFixed(2)),
                        }),
                    });

                    window.webengage.track('Post Submitted', {
                        attachment,
                        attachment_type: attachmentType,
                        text: editorContent,
                        post_button_type: position,
                        category: '',
                        post_type: attachmentType ?? postType,
                        specific,
                        tag: tag,
                        pasteContent: pastedEditorContent,
                        ...(attachmentType === 'video' && {
                            video_length: videoEl.current.duration,
                            video_size: parseFloat((selectedVideo?.size / 1000000).toFixed(2)),
                        }),
                    });
                }

                setShowSuccessLottie(true);

                const timer = setTimeout(() => {
                    setShowSuccessLottie(false);
                    handleShow();
                    clearTimeout(timer);
                }, 1000);
            } catch (err) {
                console.log('error in post submit: ', err);
            } finally {
                if (videoImageUploaded) {
                    setEditorContent('');
                    setScehemeProductName('');
                    setScehemeCategory('');
                    setSchemeSource('');
                    setSelectedImage({});
                    setImageUrl(-2);
                    setVideoUrl(-2);
                    setSelectedVideo({});
                }
                setPercentage(0);
                setDisableSubmit(false);
            }
        },
        [
            schemeCategory,
            schemeSource,
            schemeProductName,
            selectedProductImage?.url,
            selectedProductImage?.data,
            selectedVideo?.url,
            selectedVideo?.size,
            selectedVideo?.data,
            selectedImage?.url,
            selectedImage?.data,
            imageUrl,
            videoEl,
            videoUrl,
            editorContent,
            postType,
            specific,
            pastedEditorContent,
        ]
    );
    const removeImage = useCallback(() => {
        setSelectedImage({});
    }, []);

    const openVideoView = useCallback(
        (e) => {
            window.sessionStorage.setItem('type', 'video');
            const uid = window.sessionStorage.getItem('data') ?? 'jWjeLoO8C3NG9fmFm1icAq1c33E2';
            mixpanel.track('Clicked on Video Jode Scheme Content');
            const storageName = getStorageName({ folderName: 'userPost', userId: uid });
            // if (user?.version > '5.3.0') {
            // 	openMediaUploader('video', storageName);
            // } else {

            if (selectedVideo?.url) return;
            videoInputRef.current.click();
            // }
            setLoading(true);
        },
        [selectedVideo?.url, videoInputRef.current]
    );

    const onImageSelect = useCallback((e) => {
        setSelectedImage({
            url: URL.createObjectURL(e?.target?.files[0]),
            data: e?.target?.files[0],
        });
        setLoading(false);
    }, []);

    const onProductImageSelect = useCallback((e) => {
        setSelectedProductImage({
            url: URL.createObjectURL(e?.target?.files[0]),
            data: e?.target?.files[0],
        });
        setLoading(false);
    }, []);

    const removeVideo = useCallback(() => {
        setVideoUrl(-2);
        setSelectedVideo();
    }, []);

    const onVideoSelect = useCallback(
        (e) => {
            if (e?.target?.files[0]?.size > 104857600) {
                setcompressionLink(true);
                removeVideo();
                return;
            }
            setcompressionLink(false);
            setSelectedVideo({
                url: URL.createObjectURL(e?.target?.files[0]),
                data: e?.target?.files[0],
                size: e?.target?.files[0]?.size,
            });
            setLoading(false);
        },
        [removeVideo]
    );

    const onChangeTextWithVideo = useCallback((e) => {
        e.preventDefault();
        setEditorContent(e.target.value);
    }, []);

    const handlePaste = (e) => {
        setPastedEditorContent((prev) => {
            const content = [...prev];
            content.push(e?.clipboardData?.getData('text'));
            return content;
        });
    };

    const openImageView = async () => {
        window.sessionStorage.setItem('type', 'photo');
        const uid = window.sessionStorage.getItem('data') ?? 'jWjeLoO8C3NG9fmFm1icAq1c33E2';
        const storageName = getStorageName({ folderName: 'userPost', userId: uid });
        mixpanel.track(
            `Clicked on Photo Jode ${location?.state?.specific === 'mandi' ? 'Mandi Rate' :
                (location?.state?.specific === SCHEME_POST || tag === SCHEME_TAG) ? 'Scheme Content' : 'General Content'}`
        );
        // if (user?.version > '5.3.0') {
        // 	openMediaUploader('photo', storageName);
        // } else {
        if (selectedImage?.url) return;
        imageInputRef.current.click();
        // }
        setLoading(true);
    };

    const openProductImageView = async () => {
        const uid = window.sessionStorage.getItem('data') ?? 'jWjeLoO8C3NG9fmFm1icAq1c33E2';
        const storageName = getStorageName({ folderName: 'userPost', userId: uid });
        mixpanel.track('Clicked on Product Image Scheme Content');
        if (selectedProductImage?.url) return;
        productImageInputRef.current.click();
        setLoading(true);
    };

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    const handlePostSubmitModal = useCallback(() => {
        handleClose();
        if (isPostTypeMandiRate(postType, specific)) {
            navigateToScreen('MandiBhavTab');
        } else navigateToScreen('News');
    }, [postType, specific]);

    const checkInputErr = useMemo(() => {
        return (
            !(editorContent?.replace(/\s+/g, '')?.trim?.()?.length > 7) ||
            isNumeric(editorContent?.replace(/\s+/g, '')?.trim?.())
        );
    }, [editorContent]);

    const disableSubmitBtn = useMemo(() => {
        if (specific === SCHEME_POST || tag === SCHEME_TAG) {
            let schemeMediaProof = !selectedVideo?.url && !selectedImage?.url;
            return !schemeProductName || schemeProductName?.trim() === '' ||
                !schemeCategory || schemeCategory?.trim() === '' ||
                !schemeSource || schemeSource?.trim() === '' ||
                disableSubmit ||
                !editorContent || editorContent?.trim() === '' ||
                !selectedProductImage?.url ||
                schemeMediaProof;
        }
        return isPostTypeVideo(postType)
            ? disableSubmit || (!selectedVideo?.url && videoUrl === -2)
            : disableSubmit || (checkInputErr && imageUrl === -2);
    }, [checkInputErr, disableSubmit, postType, imageUrl, videoUrl,
        specific, schemeProductName, schemeCategory, schemeSource, selectedVideo,
        selectedProductImage, selectedImage, editorContent, tag
    ]);

    const handleKeyDown = useCallback((e) => {
        // Reset field height
        e.target.style.height = 'inherit';

        // Get the computed styles for the element
        const computed = window.getComputedStyle(e.target);

        // Calculate the height
        const height =
            parseInt(computed.getPropertyValue('border-top-width'), 10) +
            parseInt(computed.getPropertyValue('padding-top'), 10) +
            e.target.scrollHeight +
            parseInt(computed.getPropertyValue('padding-bottom'), 10) +
            parseInt(computed.getPropertyValue('border-bottom-width'), 10);

        e.target.style.height = `${height}px`;
    }, []);

    const getHeader = useMemo(() => {
        if (isPostTypeMandiRate(postType, specific)) return headerTxtForMandiPost;
        else if (specific === SCHEME_POST || tag === SCHEME_TAG) return headerTxtForSchemePost;
        return headerTextForKiranaJankari;
    }, [postType, specific, tag]);

    const PostKareButton = ({ postButtonData }) => {
        return (
            <div
                className="flex flex-row-reverse justify-end"
                onClick={() => {
                    if (postButtonData.checkInputErr) {
                        toast.info('पोस्ट करने के लिए कम से कम 8 अक्षर जमा करे');
                    }
                }}
            >
                <RippleButton
                    onSubmit={() => {
                        if (postButtonData.checkInputErr) {
                            if (postButtonData?.errorTxtRef?.current?.style) {
                                postButtonData.errorTxtRef.current.style.animation = 'horizontal-shaking 0.55s';
                            }
                            const timer = setTimeout(() => {
                                if (postButtonData?.errorTxtRef?.current?.style) {
                                    postButtonData.errorTxtRef.current.style.animation = '';
                                }
                                clearTimeout(timer);
                            }, 550);
                            return;
                        }
                        postButtonData.handleSubmit();
                    }}
                    isDisabled={postButtonData.disableSubmitBtn}
                    title={isPostUploaded ? "पोस्ट करें" : "Retry करें"}
                    className={`flex font-bold ${!postButtonData.disableSubmitBtn ? 'bg-[#D45339]' : 'bg-[#777777]'
                        } text-[#fff] w-24 h-10 justify-center text-center items-center self-center rounded-lg text-base`}
                    rippleColor="#003310"
                />
            </div>
        );
    };

    const showPercentageLoader = useCallback(()=>{
		if(percentage > 0){
		 return <UploadLoader percentage={percentage} />
		} 
	},[percentage])

    return (
        <div className="flex flex-col bg-gray min-h-screen w-[100%] overflow-y-auto">
            {isLoading && (
                <div className="absolute top-0 left-0 right-0 bottom-0 bg-[#000000] bg-opacity-60 z-30 flex flex-1 justify-center items-center">
                    <Oval
                        height={100}
                        width={100}
                        color="green"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel="oval-loading"
                        secondaryColor="green"
                        strokeWidth={3}
                        strokeWidthSecondary={3}
                    />
                </div>
            )}

            {bottomSheetToggle && (
                <BottomSheetForSamplePosts
                    isOpen={bottomSheetToggle}
                    setOpen={setBottomSheetToggle}
                    removeHash={removeHash}
                    type={type}
                    setType={setType}
                    hash={SAMPLE_POSTS}
                    location={location}
                    specific={specific}
                    postType={postType}
                />
            )}
            {showSuccessLottie && (
                <div className="absolute top-0 left-0 right-0 bottom-0 bg-[#000000] bg-opacity-60 z-30 flex flex-1 justify-center items-center">
                    <Lottie options={defaultOptions} height={200} width={200} />
                </div>
            )}

            <SubmitPostModal showModal={showModal} handlePostSubmitModal={handlePostSubmitModal} />

            <div
                className={`flex flex-col ${isPostTypeMandiRate(postType, specific) ? 'min-h-[80vh]' : ''
                    } w-[100%]`}
            >
                <div className='fixed top-0 w-full z-10'>
                    <Header
                        onPressBack={
                            location?.state?.from === 'postType'
                                ? null
                                : () => {
                                    navigateToScreen('MandiBhavTab');
                                }
                        }
                        headerTxt={header && header !== '' ? header : getHeader}
                        specific={SCHEME_POST}
                        rightComponent={
                            <PostKareButton
                                postButtonData={{ checkInputErr, errorTxtRef, disableSubmitBtn, handleSubmit }}
                            />
                        }
                    />
                </div>
                {!isPostTypeMandiRate(postType, specific) && specific !== SCHEME_POST && tag !== SCHEME_TAG && (
                    <UserDetailHeader
                        profileImage={user?.profile_image_url}
                        name={user?.name}
                        shopName={user?.shop_name}
                        district={user?.district}
                        state={user.state}
                    />
                )}

                {/* Error message on text type post */}
                {/* {imageUrl && checkInputErr && isPostTypeText(postType) && (
					<div ref={errorTxtRef} class="err-shake">
						<div>फोटो के साथ इसके बारे में एक लाइन लिखें</div>
					</div>
				)} */}
                {/* input box for upload */}
                <div className="flex mt-24 flex-col">
                    {(location?.state?.specific === SCHEME_POST || tag === SCHEME_TAG) && (
                        <>
                            <div>
                                <h5
                                    className="ml-3 mb-2 pl-1"
                                    style={{
                                        color: '#000',
                                        fontSize: '16px',
                                        fontStyle: 'normal',
                                        fontWeight: 700,
                                        lineHeight: '150%',
                                        textTransform: 'capitalize',
                                    }}
                                >प्रोडक्ट का नाम डालें
                                </h5>

                                <TextField
                                    className="mb-4 px-[0.8rem] "
                                    fullWidth
                                    id="productName"
                                    name="productName"
                                    value={schemeProductName}
                                    type="text"
                                    style={{ fontSize: 14 }}
                                    placeholder="मंच चॉकलेट 5 rs वाली"
                                    onInput={(e) => {
                                        if (e?.target?.value?.length > PRODUCT_NAME_MAX_LENGTH_LIMIT) return
                                        setScehemeProductName(e?.target?.value);
                                    }}

                                />
                            </div>

                            <div>
                                <h5
                                    className="ml-3 mb-2 pl-1"
                                    style={{
                                        color: '#000',
                                        fontSize: '16px',
                                        fontStyle: 'normal',
                                        fontWeight: 700,
                                        lineHeight: '150%',
                                        textTransform: 'capitalize',
                                    }}
                                >स्कीम कब मिली थी
                                </h5>

                                <div className='px-3'>
                                    <Select
                                        className="mb-4"
                                        fullWidth
                                        style={{ fontSize: 14 }}
                                        placeholder='कोई एक चुने'
                                        displayEmpty
                                        renderValue={(selected) => {
                                            if (!selected || selected?.length === 0) {
                                                return <span style={{ fontSize: 16 }} className=' text-gray-400'>कोई एक चुने</span>;
                                            }
                                            return selected;
                                        }}
                                        onChange={(e) => {
                                            setScehemeCategory(e?.target?.value)
                                        }}
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        {SCHEME_CATEGORIES?.map((item, idx) => (<MenuItem key={idx} value={item}>{item}</MenuItem>))}
                                    </Select>
                                </div>
                            </div>

                            <div>
                                <h5
                                    className="ml-3 mb-2 pl-1"
                                    style={{
                                        color: '#000',
                                        fontSize: '16px',
                                        fontStyle: 'normal',
                                        fontWeight: 700,
                                        lineHeight: '150%',
                                        textTransform: 'capitalize',
                                    }}
                                >स्कीम किससे मिली थी?
                                </h5>

                                <div className='px-3'>
                                    <Select
                                        className="mb-4"
                                        fullWidth
                                        style={{ fontSize: 14 }}
                                        placeholder='कोई एक चुने'
                                        displayEmpty
                                        renderValue={(selected) => {
                                            if (!selected || selected?.length === 0) {
                                                return <span style={{ fontSize: 16 }} className=' text-gray-400'>कोई एक चुने</span>;
                                            }
                                            return selected;
                                        }}
                                        onChange={(e) => {
                                            setSchemeSource(e?.target?.value)
                                        }}
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        {SCHEME_SOURCE_OPTIONS?.map((item, idx) => (<MenuItem key={idx} value={item}>{item}</MenuItem>))}
                                    </Select>
                                </div>
                            </div>
                        </>
                    )}

                    {(location?.state?.specific === SCHEME_POST || tag === SCHEME_TAG) && (
                        <div
                            className="ml-3 mb-2 pl-1"
                            style={{
                                color: '#000',
                                fontSize: '16px',
                                fontStyle: 'normal',
                                fontWeight: 700,
                                lineHeight: '150%',
                                textTransform: 'capitalize',
                            }}
                        >
                            स्कीम के बारें में बताएं
                        </div>
                    )}
                    <div className="flex flex-col justify-center">
                        <TextInput
                            setFocussedTextArea={setFocussedTextArea}
                            placeholder={`<h5 style = "font-size:14px;padding:5px;">अपने क्षेत्र के लोकल आइटम के बारे में बताएं जिनसे ग्राहक संतुष्ट हो। <br></br> ध्यान रखे! केवल सही जानकारी बताइयेगा, सभी किराना भाई आप पर बहुत भरोसा करते है। <h5>`}
                            specific={SCHEME_POST}
                            editorContent={editorContent}
                            textareaContainerStyle={
                                isPostTypeMandiRate(postType, specific)
                                    ? `mb-4 bg-[#F5F5F5] 
								rounded-xl 
								h-48 text-base
								outline-none
								border-[1px] border-[#2C99FF]
								px-[0.8rem] pt-[0.5rem] w-[90%] 
								self-center`
                                    : ''
                            }
                            containerStyle={
                                `flex bg-[#F5F5F5] border-[1px] 
								border-[rgba(0,0,0,0.2)] border-solid text-[10px] px-[0.8rem] pt-[0.5rem] h-48 
								text-[rgba(0,0,0,0.4)] mx-3 rounded-xl mb-8`
                            }
                            setEditorContent={(e) => {
                                if (e?.length > SCHEME_DESCRIPTION_MAX_LENGTH_LIMIT) return
                                setEditorContent(e)
                            }}
                            handlePaste={handlePaste}
                            userName={user?.name}
                        />
                        {!focussedTextArea && isPostTypeMandiRate(postType, specific) && (
                            <>
                                <div className="-translate-y-[4.5rem] h-[2px] w-[90vw]  self-center bg-[#E7E7E7]" />

                                <div
                                    className="-translate-y-16 pl-[1.2rem] -mb-8 text-[#D45339]"
                                    style={{ display: 'flex' }}
                                >
                                    <Image
                                        alt="user"
                                        src={user?.profile_image_url ? user?.profile_image_url : personIcon}
                                        className="w-6 h-6 rounded-full mx-1"
                                    />
                                    <div style={{ alignSelf: 'center' }}>{user?.name}</div>
                                </div>
                            </>
                        )}
                    </div>

                </div>


                {/* sample line for user below input box in txt post */}
                {/* {focussedTextArea &&
					isPostTypeText(postType) &&
					!isPostTypeMandiRate(postType, specific) && (
						<div className="flex text-[#0169BB] mt-[-10px] text-sm bg-[#F0FAFF] mx-3 px-3 py-1 mb-2 items-center rounded-lg">
							<div className="font-bold text-base justify-center">उदाहरण :</div>
							&nbsp;{currPostIdea}
						</div>
					)} */}

                {isPostTypeMandiRate(postType, specific) && location?.state?.specific !== 'mandi' && (
                    <div className="flex flex-col">
                        {videoUrl === -2 && !selectedVideo?.url && imageUrl === -2 && !selectedImage?.url ? (
                            <div
                                className="flex py-2 mx-4 rounded-xl justify-center items-center mb-8"
                                style={{
                                    background: 'radial-gradient(50% 50% at 50% 50%, #FFF 0%, #FFE4DB 100%)',
                                    fontSize: '23.416px',
                                }}
                                onClick={openImageView}
                            >
                                <input
                                    type="file"
                                    ref={imageInputRef}
                                    className="hidden"
                                    onChange={onImageSelect}
                                    accept="image/*"
                                />
                                फोटो जोड़ें{' '}
                                <Image
                                    alt="plus"
                                    src={plus}
                                    className="ml-[0.8rem] w-[1.35rem] h-[1.35rem] bg-[#fff] rounded-full"
                                />
                            </div>
                        ) : null}
                    </div>
                )}
                {/* {isPostTypeMandiRate(postType, specific) && (
					<div
						onClick={() => {
							mixpanel.track(`Clicked on 'कैसे बनाये बेहतरीन पोस्ट' hyperlink`, {
								postType,
								specific: specific ?? '',
							});
							handeSamplePostView();

							if (isPostTypeVideo(postType)) setType(0);
							else setType(1);
						}}
						className="flex text-[#000] bg-[#FEEACC] px-3 py-2
					text-center self-center rounded-2xl text-lg items-center underline mb-3 "
					>
						<Image alt="plus" src={bulb} className="ml-3 w-6 h-6 mr-2 bg-[#FEEACC] rounded-full" />
						कैसे बनाये बेहतरीन पोस्ट?
					</div>
				)} */}

                {(specific === SCHEME_POST || tag === SCHEME_TAG) && <div
                    className="ml-3 mb-2 pl-1"
                    style={{
                        color: '#000',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: 700,
                        lineHeight: '150%',
                        textTransform: 'capitalize',
                    }}
                >
                    प्रोडक्ट की फोटो जोड़ें
                </div>}

                {
                    (specific === SCHEME_POST || tag === SCHEME_TAG) && productImageUrl === -2 && !selectedProductImage?.url ? (
                        <div
                            className="rounded-2xl w-36 mx-3 mb-6"
                            style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                display: 'flex',
                                textAlign: 'center',
                                backgroundColor: '#FFF6F3',
                                fontSize: '15.416px',
                                fontWeight: 600,
                                borderWidth: 1,
                                borderColor: '#D45339',
                                borderStyle: 'dashed',
                                strokeDasharray: 5,
                            }}
                        >
                            <div
                                className="flex my-3"
                                onClick={openProductImageView}
                                style={{ placeContent: 'center', textAlign: 'center', alignItems: 'center' }}
                            >
                                <input
                                    type="file"
                                    ref={productImageInputRef}
                                    className="hidden"
                                    onChange={onProductImageSelect}
                                    accept="image/*"
                                />
                                फोटो जोड़ें{' '}
                                <Image
                                    alt="plus"
                                    src={plus}
                                    className="ml-[0.8rem] w-[1.35rem] h-[1.35rem] bg-[#fff] rounded-full"
                                />
                            </div>
                        </div>
                    ) : null
                }

                {loading ? (
                    (specific === SCHEME_POST || tag === SCHEME_TAG) &&
                    <div
                        className="flex flex-col  h-40"
                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    >
                        <CircularProgress size={30} sx={{ color: '#D45339' }} />
                    </div>
                ) : (
                    (productImageUrl !== -2 || selectedProductImage?.url) && (
                        <div className="flex mb-2 flex-col">
                            <Image
                                src={productImageUrl !== -2 ? productImageUrl : selectedProductImage?.url}
                                alt=""
                                className="w-[90%] h-40 object-cover self-center mb-4"
                            />
                            <ImCross
                                color="#fff"
                                className="p-2 rounded-full bg-gray-400 w-7 h-7 absolute right-2 -top-30 "
                                onClick={() => {
                                    setProductImageUrl(-2);
                                    setSelectedProductImage();
                                }}
                            />
                        </div>
                    )
                )}

                {
                    (specific === SCHEME_POST || tag === SCHEME_TAG) && (
                        <div
                            className="ml-3 mb-2 pl-1">
                            <p style={{
                                color: '#000',
                                fontSize: '16px',
                                fontStyle: 'normal',
                                fontWeight: 700,
                                lineHeight: '150%',
                            }}>

                                स्कीम की पुष्टि के लिए सही प्रमाण दें
                            </p>
                            <p className='text-sm mb-1 text-black'>पक्का बिल या कोई और वैध प्रमाण जोड़ें</p>
                        </div>
                    )
                }

                {!isPostTypeMandiRate(postType, specific) &&
                    videoUrl === -2 &&
                    !selectedVideo?.url &&
                    imageUrl === -2 &&
                    !selectedImage?.url ? (
                    <Row className="mx-3">
                        <Col
                            className="rounded-2xl"
                            style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                display: 'flex',
                                flex: 4,
                                textAlign: 'center',
                                backgroundColor: '#FFF6F3',
                                fontSize: '15.416px',
                                fontWeight: 600,
                                borderWidth: 1,
                                borderColor: '#D45339',
                                borderStyle: 'dashed',
                                strokeDasharray: 5,
                            }}
                        >
                            <div
                                className="flex my-3"
                                onClick={openImageView}
                                style={{ placeContent: 'center', textAlign: 'center', alignItems: 'center' }}
                            >
                                <input
                                    type="file"
                                    ref={imageInputRef}
                                    className="hidden"
                                    onChange={onImageSelect}
                                    accept="image/*"
                                />
                                फोटो जोड़ें{' '}
                                <Image
                                    alt="plus"
                                    src={plus}
                                    className="ml-[0.8rem] w-[1.35rem] h-[1.35rem] bg-[#fff] rounded-full"
                                />
                            </div>
                        </Col>
                        <Col style={{ textAlign: 'center', alignSelf: 'center' }}>
                            <div
                                className="mx-1"
                                style={{
                                    textAlign: 'center',
                                    backgroundColor: 'white',
                                    color: '#D45339',
                                    fontSize: '20px',
                                    fontWeight: 600,
                                }}
                            >
                                या
                            </div>
                        </Col>
                        <Col
                            className="rounded-2xl"
                            style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                display: 'flex',
                                flex: 4,
                                textAlign: 'center',
                                backgroundColor: '#FFF6F3',
                                fontSize: '15.416px',
                                fontWeight: 600,
                                borderWidth: 1,
                                borderColor: '#D45339',
                                borderStyle: 'dashed',
                            }}
                        >
                            <div
                                className="flex my-3"
                                onClick={openVideoView}
                                style={{ placeContent: 'center', textAlign: 'center', alignItems: 'center' }}
                            >
                                <input
                                    type="file"
                                    ref={videoInputRef}
                                    className="hidden"
                                    onChange={onVideoSelect}
                                    accept="video/*,.mkv"
                                />
                                वीडियो जोड़ें{' '}
                                <Image
                                    alt="plus"
                                    src={plus}
                                    className="ml-[0.8rem] w-[1.35rem] h-[1.35rem] bg-[#fff] rounded-full"
                                />
                            </div>
                        </Col>
                        {compressionLink && (
                            <div
                                style={{
                                    backgroundColor: '#FFF8F8',
                                    padding: 14,
                                    borderColor: '#E3E3E3',
                                    borderWidth: 1,
                                    borderRadius: 14,
                                    display: 'flex',
                                    marginTop: 44,
                                }}
                            >
                                <SVGIconSelector icon={SVGIcons.alert} />

                                <div style={{ marginLeft: 9 }}>
                                    <h4
                                        style={{ fontSize: 18, fontWeight: 'bold', color: '#FF0000', marginBottom: 0 }}
                                    >
                                        कृपया फाइल की साइज 100 MB से कम रखें!
                                    </h4>

                                    <a
                                        target="_blank"
                                        href="https://play.google.com/store/apps/details?id=com.idea.videocompress"
                                        style={{ color: 'black', textAlign: 'left', fontSize: 14 }}
                                    >
                                        वीडियो छोटा करें
                                    </a>
                                </div>
                            </div>
                        )}
                    </Row>
                ) : null}


                {/* video preview */}
                {(videoUrl !== -2 || !!selectedVideo?.url) && (
                    <div className="flex flex-col mb-6">
                        <video
                            ref={videoEl}
                            src={videoUrl !== -2 ? videoUrl : selectedVideo?.url}
                            width="200"
                            height="200"
                            controls
                            className="self-center"
                        />
                        <ImCross
                            color="#fff"
                            className="p-2 rounded-full bg-gray-400 w-7 h-7 absolute right-4 top"
                            onClick={removeVideo}
                        />
                    </div>
                )}
                {/* image preview */}
                {selectedVideo?.url && isPostTypeVideo(postType) && checkInputErr && (
                    <div ref={errorTxtRef} class="err-shake">
                        <div>वीडियो के साथ इसके बारे में एक लाइन लिखें।</div>
                    </div>
                )}

                {/* {(location?.state?.specific !== 'mandi' && (videoUrl === -2 && !selectedVideo?.url && imageUrl === -2 && !selectedImage?.url)) && (!header || header === '') &&
					<div>
						<h5 style={{ fontSize: 20, marginLeft: 16, marginTop: 40 }}>कुछ इस तरह के पोस्ट को मिलते है <strong>ज्यादा लाइक्स</strong>👌</h5>
						<Carousel
							ssr
							deviceType={'mobile'}
							autoPlay
							autoPlaySpeed={3000}
							partialVisbile
							responsive={responsive1}
							containerClass={'carousel-container'}
							itemClass={'image-item'}
							infinite
							removeArrowOnDeviceType={['tablet', 'mobile']}
							className="ml-4"
						>
							{imageUrls?.map((item, idx) => {
								return (
									<div
										onClick={() => {
											if (item?.nav) {
												mixpanel.track('Clicked on Suggested Incentive Content', {
													index: idx,
													itemID: item?.nav?.params?.item?.entity,
												});
												navigateToScreen(item?.nav?.name, item?.nav?.params);
											}
										}}
										style={{
											position: 'relative',
											height: '300px', // Adjust the height as needed
											overflow: 'hidden',
											marginRight: 'auto',
											marginLeft: 'auto',
											backgroundColor: 'white',
											marginTop: '2px',
											marginBottom: '20px',
											paddingTop: '10px',
											paddingBottom: '10px',
											textAlign: 'center',
											marginLeft: 10,
										}}
									>
										{item?.banner_type === 'video' && (
											<AiFillPlayCircle
												size="40px"
												style={{
													position: 'absolute',
													top: '50%',
													left: '50%',
													transform: 'translate(-50%, -50%)',
													color: 'white',
													zIndex: 100,
												}}
											/>
										)}
										<div>
											<div style={{ position: 'relative', display: item?.banner_type === 'image' ? 'block' : 'inline-block' }}>
												{item?.banner_type === 'image' ? (
													<div
														style={{
															height: '280px',
															borderRadius: '10px 10px 10px 10px',
															backgroundColor: 'black',
															display: 'flex',
															flexDirection: 'column',
															justifyContent: 'center',
															// width: '100%',
														}}
													>
														<img
															src={item?.img}
															style={{ height: 150, width: '100%', objectFit: 'cover' }}
														/>
													</div>
												) : (
													<img
														className="my-0"
														alt="img"
														style={{
															height: '280px',
															borderRadius: '10px 10px 10px 10px',
															border: 'solid',
															borderColor: 'white',
															zIndex: 3,
															position: 'relative',
															display: 'block',
															// boxShadow: '3px 6px 8px -1px',
														}}
														src={item?.img}
													/>
												)}
												<div
													style={{
														color: 'white',
														fontSize: 14,
														fontWeight: 500,
														position: 'absolute',
														bottom: '15%',
														zIndex: 100,
														display: 'flex',
													}}
												>
													<div
														style={{
															paddingBottom: '4px',
															textAlign: 'left',
															paddingLeft: 10,
															lineHeight: '130%',
															paddingRight: 10,
														}}
													>
														{item?.headline?.substring(0, 55)}...
													</div>
												</div>

												<div
													style={{
														color: 'white',
														fontSize: 30,
														fontWeight: 700,
														lineHeight: '185%',
														position: 'absolute',
														bottom: '0%',
														left: '9%',
														zIndex: 100,
													}}
												>
													{idx + 1}
												</div>

												<div
													style={{
														color: 'white',
														fontSize: 15,
														fontWeight: 500,
														position: 'absolute',
														bottom: '3%',
														right: '10%',
														zIndex: 100,
														display: 'flex',
													}}
												>
													<div style={{ paddingRight: '2px', paddingBottom: '1px' }}>
														<AiFillLike color="rgba(5, 131, 243, 1)" size={'19px'} />
													</div>
													<div style={{ placeSelf: 'end' }}>{item?.likes ?? 2000}</div>
												</div>
												<div
													style={{
														position: 'absolute',
														bottom: 0,
														left: 0,
														width: '100%',
														height: '60%',
														background: 'linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))',
														borderRadius: '10px',
														borderColor: 'white',
														zIndex: 4,
													}}
												/>
												<div
													style={{
														position: 'absolute',
														bottom: 0,
														left: 0,
														width: '100%',
														height: '50%',
														background: 'linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))',
														borderRadius: '10px',
														zIndex: 4,
													}}
												/>
												<div
													style={{
														position: 'absolute',
														bottom: 0,
														left: 0,
														width: '100%',
														height: '30%',
														background: 'linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))',
														borderRadius: '10px',
														zIndex: 4,
													}}
												/>
											</div>
										</div>
									</div>
								);
							})}
						</Carousel>
					</div>} */}

                {/* image attached in text post */}
                {loading ? (
                    <div
                        className="flex flex-col  h-40"
                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    >
                        <CircularProgress size={30} sx={{ color: '#D45339' }} />
                    </div>
                ) : (
                    (imageUrl !== -2 || selectedImage?.url) && (
                        <div className="flex flex-col">
                            <Image
                                src={imageUrl !== -2 ? imageUrl : selectedImage?.url}
                                alt=""
                                className="w-[90%] h-40 object-cover self-center mb-4"
                            />
                            <ImCross
                                color="#fff"
                                className="p-2 rounded-full bg-gray-400 w-7 h-7 absolute right-2 -top-30 "
                                onClick={() => {
                                    setImageUrl(-2);
                                    setSelectedImage();
                                }}
                            />
                        </div>
                    )
                )}
            </div>

            <div
                // onClick={() => {
                // 	if (checkInputErr) {
                // 		toast.info('पोस्ट करने के लिए कम से कम 8 अक्षर जमा करे');
                // 	}
                // }}
                className="mx-3"
            >
                {/* <RippleButton
					onSubmit={() => {
						if (checkInputErr) {
							errorTxtRef.current.style.animation = 'horizontal-shaking 0.55s';
							const timer = setTimeout(() => {
								errorTxtRef.current.style.animation = '';
								clearTimeout(timer);
							}, 550);
							return;
						}
						handleSubmit();
					}}
					isDisabled={disableSubmitBtn}
					title="पोस्ट करें"
					className={`flex font-bold ${!disableSubmitBtn ? 'bg-[#D45339]' : 'bg-[#777777]'
						} text-[#fff] h-14 justify-center text-center px-20 items-center self-center rounded-2xl text-xl my-4`}
					rippleColor="#003310"
				/> */}
                {/* {!isPostTypeMandiRate(postType, specific) && (
					<div
						onClick={() => {
							mixpanel.track('Clicked on Asli Nakli Banner');
							window.webengage.track('Clicked on Asli Nakli Banner');
						}}
						className="mb-1"
					>
						<img src={asliNakliBanner} alt="punji" />
					</div>
				)} */}
                {/* {!!isPostTypeMandiRate(postType, specific) && (
					<div
						className="flex items-center self-center text-lg font-bold text-[red] my-[0.6rem]"
						style={{ placeContent: 'center', backgroundColor: "#FFF2F2", borderRadius: 12, paddingTop: 6, paddingBottom: 6, marginBottom: 30 }}
					>
						<IoMdWarning size={20} className="mr-1" />
						गलत रेट बताने पे 1000 पूंजी की फाइन
					</div>
				)} */}
                {/* {!isPostTypeMandiRate(postType, specific) && (
					<div
						onClick={() => {
							mixpanel.track(`Clicked on 'कैसे बनाये बेहतरीन पोस्ट' hyperlink`, {
								postType,
								specific: specific ?? '',
							});
							handeSamplePostView();

							if (isPostTypeVideo(postType)) setType(0);
							else setType(1);
						}}
						style={{ placeContent: 'center' }}
						className="flex text-[#000] px-3 py-1
					text-center self-center rounded-2xl text-lg items-center underline mb-3 "
					>
						<Image alt="plus" src={bulb} className="ml-3 w-6 h-6 mr-2 bg-[#FEEACC] rounded-full" />
						कैसे बनाये बेहतरीन पोस्ट?
					</div>
				)} */}

                {/* <div
                    className="flex items-center mt-4 p-1 self-center text-lg font-bold text-black"
                    style={{
                        placeContent: 'center',
                        backgroundColor: '#FFF2F2',
                        borderRadius: 12,
                        paddingTop: 6,
                        paddingBottom: 6,
                        marginBottom: 30,
                    }}
                >
                    <div className='w-3 mr-2'>
                        <IoMdWarning size={20} />
                    </div>
                    स्कीम अगर पहले से किसी अन्य साथी ने दी है तो आपकी पोस्ट अप्प्रोवे नहीं की जाएगी
                </div> */}



                {(!!isPostTypeMandiRate(postType, specific) || (specific === SCHEME_POST || tag === SCHEME_TAG)) && (
                    <div
                        className="flex items-center mt-4 self-center text-lg font-bold text-[red]"
                        style={{
                            placeContent: 'center',
                            backgroundColor: '#FFF2F2',
                            borderRadius: 12,
                            paddingTop: 6,
                            paddingBottom: 6,
                            marginBottom: 30,
                        }}
                    >
                        <IoMdWarning size={20} className="mr-1" />
                        गलत {specific === 'mandi' ? 'रेट' : 'स्कीम'} बताने पे 1000 पूंजी की फाइन
                    </div>
                )}
            </div>
            {showPercentageLoader()}
            {location?.state?.specific === 'mandi' && (
                <Modal centered show={isVisible} onHide={toggleModal} className="w-[90vw] mx-[5vw]">
                    <div style={{ borderRadius: 30 }}>
                        <IoMdCloseCircle
                            style={{ position: 'absolute', right: '2%', top: '2%' }}
                            size={30}
                            onClick={() => {
                                toggleModal();
                                mixpanel.track('Clicked Close Icon on Mandi Suchna Modal');
                                window.webengage.track('Clicked Close Icon on Mandi Suchna Modal');
                            }}
                        />
                        <img src="https://d2rstorage1.azureedge.net/news/December/28/ad16140d-55c9-4aa6-8976-7412ea557db4/banner/images/1703767531016.png" />
                    </div>
                </Modal>
            )}
            {/* {location?.state?.specific !== 'mandi' &&
				<Modal centered show={isSocialPostModalVisible} onHide={toggleSocialModal} className="w-[90vw] mx-[5vw]">
					<div style={{ borderRadius: 30 }}>
						<IoMdCloseCircle style={{ position: "absolute", right: "2%", top: "2%" }} size={30} onClick={() => {
							toggleSocialModal()
							mixpanel.track('Clicked Close Icon on Social Suchna Modal')
							window.webengage.track('Clicked Close Icon on Social Suchna Modal')
						}} />
						<img src="https://d2rstorage1.blob.core.windows.net/news/February/5/849c4736-b4db-48e1-8621-73f616a33daf/banner/images/1707111382222.webp" />
					</div>
				</Modal>
			} */}
        </div>
    );
}

export default React.memo(SchemePostCreate);
