import React from "react";

function RightArrowThickOutline({
  width = 44,
  height = 44,
  color = "#fff",
  background = "#918E8E",
  className,
}) {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 44 44"
      fill="none"
    >
      <circle cx={width*0.496} cy={width*0.496} r={width*0.496} fill={background} />
      <path
        d="m13.681 10.684 20.902 9.997a1.24 1.24 0 0 1 0 2.238l-20.902 9.997a1.24 1.24 0 0 1-1.732-1.445l2.091-7.666a.496.496 0 0 1 .412-.361l10.129-1.376a.249.249 0 0 0 .193-.149l.018-.061a.248.248 0 0 0-.156-.266l-.054-.015L14.463 20.2a.496.496 0 0 1-.41-.361l-2.104-7.71a1.24 1.24 0 0 1 1.732-1.446Z"
        fill={color}
      />
    </svg>
  );
}

export default React.memo(RightArrowThickOutline);
