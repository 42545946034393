import React from 'react';

function Whatsapp({ width = 24, height = 24, className }) {
	return (
		<svg width={width} height={height} className={className} viewBox="0 0 24 24" fill="none">
			<g clipPath="url(#a)">
				<path
					fill="url(#b)"
					d="M1.232 12.451c0 1.906.497 3.766 1.444 5.406L1.14 23.459l5.733-1.503a10.81 10.81 0 0 0 5.168 1.316h.005c5.96 0 10.812-4.85 10.815-10.812a10.744 10.744 0 0 0-3.165-7.649 10.747 10.747 0 0 0-7.65-3.17c-5.961 0-10.813 4.85-10.815 10.81"
				/>
				<path
					fill="url(#c)"
					d="M.844 12.449c0 1.973.515 3.9 1.495 5.599L.75 23.85l5.939-1.557a11.198 11.198 0 0 0 5.353 1.363h.005c6.174 0 11.2-5.025 11.203-11.2a11.131 11.131 0 0 0-3.278-7.923 11.133 11.133 0 0 0-7.925-3.284C5.872 1.25.847 6.274.844 12.449Zm3.537 5.306-.222-.352a9.284 9.284 0 0 1-1.423-4.954c.002-5.132 4.178-9.308 9.315-9.308a9.251 9.251 0 0 1 6.583 2.73 9.252 9.252 0 0 1 2.724 6.586c-.002 5.132-4.179 9.308-9.31 9.308h-.005a9.3 9.3 0 0 1-4.739-1.297l-.34-.202-3.524.924.94-3.435Z"
				/>
				<path
					fill="#fff"
					d="M9.247 7.764c-.21-.466-.43-.476-.63-.484-.163-.007-.35-.007-.536-.007-.187 0-.49.07-.746.35-.257.28-.98.958-.98 2.335s1.003 2.708 1.143 2.895c.14.187 1.936 3.103 4.782 4.225 2.364.933 2.845.747 3.359.7.513-.046 1.656-.676 1.889-1.33.233-.654.233-1.214.163-1.33-.07-.117-.256-.188-.536-.327-.28-.14-1.656-.818-1.913-.911-.257-.093-.443-.14-.63.14s-.723.91-.886 1.097c-.163.187-.327.21-.606.07-.28-.14-1.182-.435-2.252-1.389-.832-.742-1.394-1.658-1.557-1.938-.163-.28-.017-.432.123-.571.126-.126.28-.327.42-.49.14-.164.186-.28.28-.467.093-.187.046-.35-.024-.49s-.614-1.525-.863-2.078"
				/>
			</g>
			<defs>
				<linearGradient
					id="b"
					x1={12.002}
					x2={12.002}
					y1={23.459}
					y2={1.641}
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#1FAF38" />
					<stop offset={1} stopColor="#60D669" />
				</linearGradient>
				<linearGradient id="c" x1={12} x2={12} y1={23.851} y2={1.25} gradientUnits="userSpaceOnUse">
					<stop stopColor="#F9F9F9" />
					<stop offset={1} stopColor="#fff" />
				</linearGradient>
				<clipPath id="a">
					<path fill="#fff" d="M.75 1.25h22.5v22.676H.75z" />
				</clipPath>
			</defs>
		</svg>
	);
}

export default React.memo(Whatsapp);
