import React from 'react';
import PropTypes from 'prop-types';
import CrossCircle from './CrossCircle';
import Edit from './Edit';
import Camera from './Camera';
import RightArrowThickOutline from './RightArrowThickOutline';
import ReplyCircle from './ReplyCircle';
import DoubleArrowDown from './DoubleArrowDown';
import Emoji from './Emoji';
import Cross from './Cross';
import Verified from './Verified';
import LeftArrow from './LeftArrow';
import BellOn from './BellOn';
import BellOff from './BellOff';
import Coin from './Coin';
import TickCircle from './TickCircle';
import Download from './Download';
import Whatsapp from './Whatsapp';
import Bulb from './Bulb';
import Alert from './Alert';

export const SVGIcons = {
	crossCircle: 'cross-circle',
	edit: 'edit',
	camera: 'camera',
	rightArrowThickOutline: 'right-arrow-thick-outline',
	replyCircle: 'reply-circle',
	doubleArrowDown: 'double-arrow-down',
	emoji: 'emoji',
	cross: 'cross',
	verified: 'verified',
	leftArrow: 'left-arrow',
	bellOn: 'bell-on',
	bellOff: 'bell-off',
	coin: 'coin',
	tickCircle: 'tick-circle',
	download: 'download',
	whatsapp: 'whatsapp',
	bulb: 'bulb',
	alert: 'alert',
};

function SVGIconSelector({ icon, width, height, color, background, className }) {
	switch (icon) {
		case SVGIcons.crossCircle: {
			return (
				<CrossCircle
					width={width}
					height={height}
					color={color}
					background={background}
					className={className}
				/>
			);
		}
		case SVGIcons.edit: {
			return (
				<Edit
					width={width}
					height={height}
					color={color}
					background={background}
					className={className}
				/>
			);
		}
		case SVGIcons.camera: {
			return <Camera width={width} height={height} color={color} className={className} />;
		}
		case SVGIcons.rightArrowThickOutline: {
			return (
				<RightArrowThickOutline
					width={width}
					height={height}
					color={color}
					background={background}
					className={className}
				/>
			);
		}
		case SVGIcons.replyCircle: {
			return (
				<ReplyCircle
					width={width}
					height={height}
					color={color}
					background={background}
					className={className}
				/>
			);
		}
		case SVGIcons.doubleArrowDown: {
			return <DoubleArrowDown width={width} height={height} color={color} className={className} />;
		}
		case SVGIcons.emoji: {
			return <Emoji width={width} height={height} color={color} className={className} />;
		}
		case SVGIcons.cross: {
			return <Cross width={width} height={height} color={color} className={className} />;
		}
		case SVGIcons.verified: {
			return (
				<Verified
					width={width}
					height={height}
					color={color}
					background={background}
					className={className}
				/>
			);
		}
		case SVGIcons.leftArrow: {
			return <LeftArrow width={width} height={height} color={color} className={className} />;
		}
		case SVGIcons.bellOn: {
			return (
				<BellOn
					width={width}
					height={height}
					color={color}
					background={background}
					className={className}
				/>
			);
		}
		case SVGIcons.bellOff: {
			return (
				<BellOff
					width={width}
					height={height}
					color={color}
					background={background}
					className={className}
				/>
			);
		}
		case SVGIcons.coin: {
			return <Coin width={width} height={height} className={className} />;
		}
		case SVGIcons.tickCircle: {
			return (
				<TickCircle
					width={width}
					height={height}
					color={color}
					background={background}
					className={className}
				/>
			);
		}
		case SVGIcons.download: {
			return <Download width={width} height={height} color={color} className={className} />;
		}
		case SVGIcons.whatsapp: {
			return <Whatsapp width={width} height={height} className={className} />;
		}
		case SVGIcons.bulb: {
			return <Bulb width={width} height={height} className={className} />;
		}
		case SVGIcons.alert: {
			return <Alert width={width} height={height} className={className} />;
		}

		default: {
			return null;
		}
	}
}

SVGIconSelector.propTypes = {
	icon: PropTypes.oneOf(Object.values(SVGIcons)).isRequired,
	width: PropTypes.number,
	height: PropTypes.number,
	color: PropTypes.string,
	background: PropTypes.string,
	className: PropTypes.string,
};

SVGIconSelector.defaultProps = {
	width: undefined,
	height: undefined,
	color: undefined,
	background: undefined,
	className: undefined,
};

export default SVGIconSelector;
