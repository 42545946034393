import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	kiranatv: [],
};

export const kiranatvSlice = createSlice({
	name: 'kiranatv',
	initialState,
	reducers: {
		storeKiranaTvInitData(state, { payload }) {
			state.kiranatv = payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const { storeKiranaTvInitData } = kiranatvSlice.actions;

export default kiranatvSlice.reducer;
