import React, { useCallback } from 'react';
import { BiArrowBack } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
// import { navigate } from 'react-router-dom';

function Header({ headerTxt, onPressBack = null, rightComponent = null,bottomPadding=true }) {
	const navigate = useNavigate();

	const onThikeHaiPress = useCallback(() => {
		if (!window.history.state) {
			window.ReactNativeWebView.postMessage(
				JSON.stringify({ type: 'navigation', nav: 'NewsScreen' })
			);
		} else {
			navigate(-1);
		}
	}, [navigate]);

	return (
		<div className="flex items-center bg-[#fff] py-3 px-3 shadow-md"
		style={{
			marginBottom:bottomPadding ? 20 :0
		}}
		>
			<BiArrowBack color="#000" size={20} onClick={onPressBack ?? onThikeHaiPress} />
			<p className="flex-1 font-semibold text-xl text-black mb-0 ml-7">{headerTxt}</p>
			{rightComponent}
		</div>
	);
}

export default React.memo(Header);
