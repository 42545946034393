export const formKharidBetchStateSelector = (state) => state?.kharidBetch?.formState;

export const formSubmitLoadingStateSelector = (state) => state?.kharidBetch?.formSubmitLoading;

export const requirementFormLoadingStateSelector = (state) =>
	state?.kharidBetch?.requirementFormLoading;

export const sellProductsLoadingSelector = (state) => state?.kharidBetch?.sellProductsLoading;

export const sellProductsExtraLoadingSelector = (state) =>
	state?.kharidBetch?.sellProductsExtraLoading;

export const sellProductsSelector = (state) => state?.kharidBetch?.sellProducts ?? [];

export const getAvailableDeliveryRanges = (state) => state?.kharidBetch?.availableDeliveryRanges;

export const getSujhavDeFormData = (state) => state?.kharidBetch?.formBody;

export const sellProductOffsetSelector = (state) => state?.kharidBetch?.sellProductOffset;

export const buyProductsLoadingSelector = (state) => state?.kharidBetch?.buyProductsLoading;

export const buyProductsExtraLoadingSelector = (state) => state?.kharidBetch?.buyProductsExtraLoading;

export const buyProductsSelector = (state) => state?.kharidBetch?.buyProducts;

export const bottomsheetOpenSelector = (state) => state?.kharidBetch?.isFeedBack;

export const buyProductOffsetSelector = (state) => state?.kharidBetch?.buyProductOffset;

export const tabState = (state) => state?.kharidBetch?.tab;

export const sellProductDetailSelector = (state) => state?.kharidBetch?.sellProductDetail;

export const sellProductDetailLoadingSelector = (state) => state?.kharidBetch?.sellProductDetailLoading;

export const userIdSelector = (state) => state?.kharidBetch?.userId;





