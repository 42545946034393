import React from 'react';

function ReplyCircle({ width = 22, height = 22, color = '#fff', background = '#000', className }) {
	return (
		<svg width={width} height={height} className={className} viewBox="0 0 25 25" fill="none">
			<circle cx={12.5} cy={12.5} r={12.5} fill={background} fillOpacity={0.2} />
			<g filter="url(#a)">
				<path
					d="M10.989 9.422V6L5 11.989l5.989 5.989V14.47c4.278 0 7.272 1.369 9.411 4.363-.856-4.277-3.422-8.555-9.411-9.41Z"
					fill={color}
				/>
			</g>
			<defs>
				<filter
					id="a"
					x={1}
					y={2}
					width={23.4}
					height={20.834}
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity={0} result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset />
					<feGaussianBlur stdDeviation={2} />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
					<feBlend in2="BackgroundImageFix" result="effect1_dropShadow_18691_44149" />
					<feBlend in="SourceGraphic" in2="effect1_dropShadow_18691_44149" result="shape" />
				</filter>
			</defs>
		</svg>
	);
}

export default React.memo(ReplyCircle);
