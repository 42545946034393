import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import { useMutation } from '@tanstack/react-query';
import Image from '../../components/ui/Image';
import { Modal, Spinner } from 'react-bootstrap';
import './styles.css';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Link } from 'react-router-dom';
import { navigateToScreen, openMediaUploader } from '../../utils/postMessage';
import { toast } from 'react-toastify';
import baseAxios from '../../utils/baseAxios';
import uploadFileToBlob from '../../config/azureconfig';
import mixpanel from '../../utils/mixpanel';
import { IoArrowBack } from 'react-icons/io5';
import Calendar from '../../assests/calendar.svg';
import Tick from '../../assests/svg/Tick';
import CustomButton from '../Button';
import { getUserId } from '../../utils/userData';
import { Button } from 'reactstrap';
import { MdFileUpload } from 'react-icons/md';
import { useQueryFetch } from '../../hooks/useQueryHook';
import { useNavigate, useLocation } from 'react-router-dom';

const responsive = {
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 3,
		paritialVisibilityGutter: 60,
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 2,
		paritialVisibilityGutter: 50,
	},
	mobile: {
		breakpoint: { max: 580, min: 0 },
		items: 2,
		paritialVisibilityGutter: 50,
	},
};
const imageUrls = [
	{
		MixPanelEventName: 'Entry 1',
		img: 'https://d2rstorage1.blob.core.windows.net/widget/June/20/1116d3b4-fc15-4ce3-a2c1-902a80f5a14e/1718879626315.webp',
		nav: {
			name: 'BrandProfile',
			nav_type: 'Redirect to BrandProfile',
			params: {
				brandId: 'brand_fortune',
				screen_name: 'BrandProfile_Fortune',
			},
		},
	},
	{
		MixPanelEventName: 'Entry 2',
		img: 'https://d2rstorage1.blob.core.windows.net/widget/June/20/b7c8a7ba-c71d-4b89-abc6-8d6fe4d6f7a3/1718879109831.webp',
		nav: {
			name: 'BrandProfile',
			nav_type: 'Redirect to BrandProfile',
			params: {
				brandId: 'brand_fortune',
				screen_name: 'BrandProfile_Fortune',
			},
		},
	},
	{
		MixPanelEventName: 'Entry 3',
		img: 'https://d2rstorage1.blob.core.windows.net/widget/June/20/09adc877-f145-4ef5-8021-27d830057b06/1718879188129.webp',
		nav: {
			name: 'BrandProfile',
			nav_type: 'Redirect to BrandProfile',
			params: {
				brandId: 'brand_fortune',
				screen_name: 'BrandProfile_Fortune',
			},
		},
	},
	{
		MixPanelEventName: 'Entry 4',
		img: 'https://d2rstorage1.blob.core.windows.net/widget/June/20/e287376e-cd93-4d48-85a6-bf507de8b646/1718879675293.webp',
		nav: {
			name: 'BrandProfile',
			nav_type: 'Redirect to BrandProfile',
			params: {
				brandId: 'brand_fortune',
				screen_name: 'BrandProfile_Fortune',
			},
		},
	}
];

const CAMPAIGN_NAME = 'FORTUNE_BILL_CAMPAIGN' ;

function GenericScreen3({}) {
	const cameraOpenRef = useRef(null);
	const hasMutatedRef = useRef(false);
	const navigate = useNavigate();
	const search = useLocation().search;
	let isVisibleParams = new URLSearchParams(search).get('isVisible');
	const userID = window.sessionStorage.getItem('data');
	const [selectedImage, setSelectedImage] = useState('');
	const [selectedImageUrl, setSelectedImageUrl] = useState('');
	const [submitted, setSubmitted] = useState(false);
	const [loading, setLoading] = useState(false);
	const [isVisible, setisVisible] = useState(isVisibleParams ?? false);
	const [isVisible1, setisVisible1] = useState(false);

	const [percentage, setPercentage] = useState(0);
	const [user, setUser] = useState('');

	const { data, isFetching, refetch } = useQueryFetch({
		url: 'https://asia-south1-op-d2r.cloudfunctions.net/userSubmissions',
		params: {
			data: {
				campaign: CAMPAIGN_NAME ,
				selfUserId: getUserId(),
				uid: getUserId(),
				type: 'campaignDetails',
			},
		},
		type: 'POST',
		isBaseUrl: false,
	});

	const fetchUser = useCallback(async () => {
		try {
			const res = await baseAxios({
				endPoint: 'userProfile',
				data: {
					type: 'getUserProfile',
					selfUserId: getUserId(),
					uid: getUserId(),
				},
			});
			mixpanel.track('ScreenView', { screen_name: 'Fortune_BillContest' });
			window.webengage.track('ScreenView', {
				screen_name: 'Fortune_BillContest',
			});
			setUser(res?.result?.data);
		} catch (err) {
		} finally {
		}
	}, [userID]);

	const { isLoading, mutate } = useMutation({
		mutationFn: (data) =>
			baseAxios({
				endPoint: 'userSubmissions',
				data: {
					videoUrl: data,
					type: 'general',
					uId: userID,
					campaign: CAMPAIGN_NAME ,
				},
			}),
		onSuccess: (res) => {
			setisVisible(true);
			setLoading(false);
			mixpanel.track('Campaign Photo Submitted', {
				campaign_name: 'Fortune Bill Contest' ,
				submission_count: (data?.result?.submissions?.submissionCount ?? 0) + 1,
				image_url: res?.result?.videoUrl,
			});
			window.webengage.track('Campaign Photo Submitted', {
				campaign_name: 'Fortune Bill Contest' ,
				submission_count: (data?.result?.submissions?.submissionCount ?? 0) + 1,
				image_url: res?.result?.videoUrl,
			});
			setSubmitted(true);
		},
		onError: (res) => {
			setLoading(false);
		},
	});

	useEffect(() => {
		window.addEventListener('message', (message) => {
			if (message?.data?.data?.mediaData?.mediaUrl && !hasMutatedRef.current) {
				hasMutatedRef.current = true;
				mutate(message?.data?.data?.mediaData?.mediaUrl);
			}
			setLoading(false);
		});
		return () => {
			window.removeEventListener('message', () => {});
		};
	}, []);

	useEffect(() => {
		fetchUser();
	}, []);

	const onClickImage = async () => {
		mixpanel.track('FortuneBillContest_Popup');
		window.webengage.track('FortuneBillContest_Popup');

		if (user?.version >= '5.5.1') {
			openMediaUploader({
				source: 'camera',
				mediaType: 'photo',
				compressFactor: -1,
				campaign: CAMPAIGN_NAME ,
				folder: 'campaign-submissions',
			});
			setLoading(true);
		} else {
			cameraOpenRef.current.click();
		}
	};

	const handleUpload = async (filePath) => {
		// if (filePath) {
		// 	alert('Please choose a file first!');
		// }
		setLoading(true);
		const url = await uploadFileToBlob(
			filePath,
			CAMPAIGN_NAME ,
			userID,
			setPercentage,
			'campaign-submissions'
		);
		setSelectedImage(null);
		mutate(url);
	};

	const onImageSelect = useCallback((e) => {
		handleUpload(e.target.files[0]);
	}, []);
	const toggleModal = () => setisVisible(!isVisible);
	const toggleModal1 = () => setisVisible1(!isVisible1);

	const renderModal = useMemo(() => {
		return (
			<Modal
				centered
				show={isVisibleParams ?? isVisible}
				onHide={() => navigate(-1)}
				className="w-[90vw] mx-[5vw]"
			>
				<div className="flex flex-col items-center p-[1.5rem]">
					<div
						style={{ fontSize: 25, width: '100%' }}
						className="font-[hind] text-center my-[.5rem] "
					>
						<span style={{ color: 'red', fontWeight: 'bold' }}>ध्यान रखें </span>
						फार्च्यून के पक्के बिल की साफ़ फोटो भेजें
					</div>
					<div className="font-[hind] text-center my-[.5rem]">
						<img src="https://d2rstorage1.blob.core.windows.net/widget/June/21/1edeee36-fc3a-4772-9961-6dc701709c6a/1718970301596.webp" />
					</div>
					<div
						style={{
							width: '100%',
							padding: '2px',
							borderRadius: 7,
							backgroundColor:
								data?.result?.campaign_details?.maxPlayCount -
									data?.result?.submissions?.submissionCount ===
								1
									? '#FFF3F3'
									: '#F5FFF5',
							margin: '10px 0',
						}}
					>
						{data?.result?.campaign_details?.maxPlayCount -
							data?.result?.submissions?.submissionCount >=
						2 ? (
							<h3 style={{ fontSize: 16, margin: '10px 0', textAlign: 'center', color: '#468C43' }}>
								<span style={{ color: 'black' }}>ध्यान दें: </span>
								आप{' '}
								{data?.result?.campaign_details?.maxPlayCount -
									data?.result?.submissions?.submissionCount}{' '}
								बार फोटो भेज सकते है
							</h3>
						) : data?.result?.campaign_details?.maxPlayCount -
								data?.result?.submissions?.submissionCount ===
						  1 ? (
							<h3 style={{ fontSize: 16, margin: '10px 0', textAlign: 'center', color: '#DC0000' }}>
								<span style={{ color: 'black' }}>ध्यान दें: </span>
								आखरी मौका है फोटो भेजने का
							</h3>
						) : (
							<h3 style={{ fontSize: 16, margin: '10px 0', textAlign: 'center', color: '#468C43' }}>
								<span style={{ color: 'black' }}>ध्यान दें: </span>
								आप फोटो जमा कर चुके है
							</h3>
						)}
					</div>
					<button
						style={{ fontSize: 25 }}
						onClick={onClickImage}
						type="button"
						className={`min-w-[7.5rem] flex justify-center pt-[.5rem] bg-[#DE3809] font-[hind] font-bold text-white rounded-lg mt-2`}
					>
						ठीक है
					</button>
				</div>
			</Modal>
		);
	}, [
		data?.result?.campaign_details?.maxPlayCount,
		data?.result?.submissions?.submissionCount,
		isVisible,
		onClickImage,
		toggleModal,
	]);

	return loading ? (
		<div
			style={{
				height: '100vh',
				width: '100%',
				display: 'flex',
				backgroundColor: 'rgba(0,0,0,0.65)',
				position: 'absolute',
				top: 0,
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<div
				style={{
					padding: '5%',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<Spinner animation="border" role="status" style={{ color: '#F16943' }} />
				<h4 style={{ color: 'white', textAlign: 'center', marginTop: 20 }}>
					आपकी इमेज अपलोड हो रही है, कृपया प्रतीक्षा करें।
				</h4>
			</div>
		</div>
	) : submitted ? (
		<div style={{ marginTop: '45%' }}>
			<div className="thankYouDiv">
				<Tick />
				<h3 style={{ marginBottom: 20 }}>धन्यवाद 🙏</h3>
				<h4>हमे फोटो भेजने के लिए</h4>
				<CustomButton
					backgroundColor="#DE3809"
					onPress={() => {
						setSubmitted(false);
						navigateToScreen('NewsScreen');
					}}
					title={'वापस जाएं'}
				/>
			</div>
		</div>
	) : (
		<div style={{ backgroundColor: '#FFFFFF' }}>
			<div style={{ position: 'relative' }}>
				{/* <div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						padding: 10,
						alignItems: 'center',
						position: 'absolute',
						top: 0,
						width: '100%',
					}}
				>
					<IoArrowBack
						onClick={() => navigateToScreen('NewsScreen')}
						style={{ color: 'black' }}
						size={24}
					/>
				</div> */}
				<Image
					style={{ marginTop: 0 }}
					className="genericImage"
					src="https://d2rstorage1.blob.core.windows.net/widget/July/1/b950c2ed-a757-46f8-8ff9-a7974d995eca/1719825756656.webp"
				/>
			</div>
			<img
				style={{ height: 24, margin: 'auto', marginTop: 40, marginBottom: 10 }}
				src="https://d2rstorage1.blob.core.windows.net/widget/June/20/00012eb6-04f5-4a79-bbc0-a80a21256701/1718880076866.webp"
			/>
			<Carousel
				ssr
				deviceType={'mobile'}
				autoPlay
				autoPlaySpeed={3000}
				partialVisbile
				responsive={responsive}
				containerClass={'carousel-container'}
				itemClass={'image-item'}
				infinite
				removeArrowOnDeviceType={['tablet', 'mobile']}
				className="mx-3"
			>
				{imageUrls.map((item) => {
					return (
						<div
							// onClick={() => navigateToScreen(item?.nav?.name, item?.nav?.params)}
							style={{
								width: 104,
								height: 104,
								borderRadius: 10,
								overflow: 'hidden',
								marginBottom: 20,
							}}
						>
							<img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={item?.img} />
						</div>
					);
				})}
			</Carousel>
			<input
				onChange={onImageSelect}
				ref={cameraOpenRef}
				style={{ display: 'none' }}
				accept="image/*"
				id="icon-button-file"
				type="file"
				capture="environment"
			/>
			<div>
				<Image
					onClick={() => {
						mixpanel.track('FortuneBillContest_Clicked',{cta_type:'primary BIll Upload CTA'});
						window.webengage.track('FortuneBillContest_Clicked',{cta_type:'primary BIll Upload CTA'});
						if (
							!(
								data?.result?.campaign_details?.maxPlayCount -
									data?.result?.submissions?.submissionCount <=
								0
							)
						) {
							navigate('/genericScreen3?isVisible=true');
						}
					}}
					style={{ marginBottom: 20, marginTop: 30 }}
					className="genericImage"
					src={
						data?.result?.campaign_details?.maxPlayCount -
							data?.result?.submissions?.submissionCount <=
						0
							? 'https://d2rstorage1.blob.core.windows.net/widget/June/20/6caaacb1-ebff-4026-95dd-c8ff6cdce9e4/1718883240614.webp'
							: 'https://d2rstorage1.blob.core.windows.net/widget/June/21/414bc502-63b9-404a-b35e-3c2df844cb59/1718973979829.webp'
					}
				/>
				<div style={{ padding: 20, paddingTop: 40, paddingBottom: 0 }}>
					<Image
						className="genericImage"
						src="https://d2rstorage1.blob.core.windows.net/widget/July/1/fc1edb11-c1c6-4946-b33c-0de4f7167652/1719825978612.webp"
					/>
				</div>
				{/* <Image
					style={{ marginBottom: 0 }}
					className="genericImage"
					src="https://d2rstorage1.blob.core.windows.net/widget/May/20/49dd019a-dd03-40cd-87b0-9456ebc8581e/1716195725458.webp"
				/> */}
			</div>
			<div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 16 }}>
				<Button
					onClick={() => {
						mixpanel.track('FortuneBillContest_Clicked', {cta_type:'secondary BIll Upload CTA'});
						window.webengage.track('FortuneBillContest_Clicked', {cta_type:'secondary BIll Upload CTA'});
						navigate('/genericScreen3?isVisible=true');
					}}
					disabled={
						data?.result?.campaign_details?.maxPlayCount -
							data?.result?.submissions?.submissionCount <=
						0
					}
					style={{
						fontSize: 24,
						color: 'white',
						backgroundColor:
							data?.result?.campaign_details?.maxPlayCount -
								data?.result?.submissions?.submissionCount <=
							0
								? 'rgba(0,0,0,0.2)'
								: '#E81224',
						borderColor:
							data?.result?.campaign_details?.maxPlayCount -
								data?.result?.submissions?.submissionCount <=
							0
								? 'rgba(0,0,0,0.2)'
								: '#E81224',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						borderRadius: 12,
					}}
				>
					बिल अपलोड करें <MdFileUpload size={24} style={{ marginLeft: 5 }} />
				</Button>
			</div>
			{data?.result?.campaign_details?.maxPlayCount - data?.result?.submissions?.submissionCount >=
			2 ? (
				<h3 style={{ fontSize: 14, margin: '10px 0', textAlign: 'center', color: '#468C43' }}>
					आप{' '}
					{data?.result?.campaign_details?.maxPlayCount -
						data?.result?.submissions?.submissionCount}{' '}
					बार फोटो भेज सकते है
				</h3>
			) : data?.result?.campaign_details?.maxPlayCount -
					data?.result?.submissions?.submissionCount ===
			  1 ? (
				<h3 style={{ fontSize: 14, margin: '10px 0', textAlign: 'center', color: '#DC0000' }}>
					आखरी मौका है फोटो भेजने का
				</h3>
			) : (
				<h3 style={{ fontSize: 14, margin: '10px 0', textAlign: 'center', color: '#468C43' }}>
					आप फोटो जमा कर चुके है
				</h3>
			)}
			{renderModal}
		</div>
	);
}

export default GenericScreen3;
