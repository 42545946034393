import React, { useCallback, useMemo, useRef } from 'react';
import Header from '../../PostCreateRevanp/Header';
import { useState } from 'react';
import TextInput from '../../PostCreateRevanp/TextInput';
import RippleButton from '../RippleButton';
import uploadFileToBlob from '../../config/azureconfig';
import baseAxios from '../../utils/baseAxios';
import mixpanel from 'mixpanel-browser';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { toast } from 'react-toastify';
import { ImCross } from 'react-icons/im';
import Image from '../ui/Image';
import UploadLoader from '../../PostCreateRevanp/UploadLoader';
import { Player } from 'video-react';
import SubmitPostModal from '../../PostCreateRevanp/SubmitPostModal';
import { useNavigate } from 'react-router-dom';

const headerTxt = 'स्पेशल पोस्ट डाले';
const placeholder = 'यहाँ लिखे';
const thanksIcon = require('../../assests/thanksIcon.webp');

function SpecialSuggestions() {
	const [editorContent, setEditorContent] = useState('');
	const [disableSubmit, setDisableSubmit] = useState(false);
	const navigate = useNavigate();
	// Uploaded content can be single or multiple
	const [selectedUploadContent, setSelectedUploadContent] = useState([]);
	const [percentage, setPercentage] = useState(0);

	const uploadInpuRef = useRef();

	const [showModal, setShowModal] = useState(false);


	const onUploadClick = useCallback(() => {

		uploadInpuRef.current.click();
	}, []);

	const onContentUpload = useCallback((e) => {

		if (e.target.files[0].size > 104857600) {
			toast.error('कृपया फाइल की size 100  MB  से काम रखें !');
			return;
		}
		setSelectedUploadContent((prev) => [
			...prev,
			{
				url: URL.createObjectURL(e.target.files[0]),
				data: e.target.files[0],
				type: e.target.files[0].type.includes('image') ? 'image' : 'video',
			},
		]);

	}, []);

	const checkInputErr = useMemo(() => {
		return (
			!(editorContent?.replace(/\s+/g, '')?.trim?.()?.length > 2) && !selectedUploadContent?.length
		);
	}, [editorContent, selectedUploadContent?.length]);

	const disableSubmitBtn = useMemo(() => {
		return disableSubmit || checkInputErr;
	}, [checkInputErr, disableSubmit]);

	const handlePostSubmitModal = useCallback(() => {
		setShowModal(false);
		navigate(-1);
	}, [navigate]);

	const handleSubmit = useCallback(
		async () => {
			try {
				setDisableSubmit(true);
				let attachments;
				let uploadData;

				const uid = window.sessionStorage.getItem('data') ?? 'jWjeLoO8C3NG9fmFm1icAq1c33E2';

				if (selectedUploadContent?.length)
					uploadData = await Promise.all(selectedUploadContent?.map(async (i) => {
						return await uploadFileToBlob(i?.data, 'SPECIAL_SHARABLE_POST', uid, setPercentage, 'campaign-submissions');
					}));

				setPercentage(100);

				if (selectedUploadContent?.length) {
					attachments = uploadData;
				}


				await baseAxios({
					endPoint: 'userSubmissions',
					data: {
						uid,
						campaign: 'SPECIAL_SHARABLE_POST',
						text: editorContent,
						...( attachments?.length && {media_urls: attachments}),
						type: 'general',
					},	
				});

				mixpanel.track('Sujhav Form Submitted', {
					...( attachments?.length && {media_urls: attachments}),
					campaign: 'SPECIAL_SHARABLE_POST',
					text: editorContent,
				});
	
				window.webengage.track('Sujhav Form Submitted', {
					...( attachments?.length && {media_urls: attachments}),
					campaign: 'SPECIAL_SHARABLE_POST',
					text: editorContent,
				});

				setShowModal(true);
				const timer = setTimeout(() => {
					setShowModal(false);
					handlePostSubmitModal();
					clearTimeout(timer);
				}, 2500);
			} catch (err) {
				mixpanel.track('error in special post submit: ', { error: err });
			} finally {
				setEditorContent('');
				setSelectedUploadContent([]);
				setPercentage(0);
				setDisableSubmit(false);
			}
		},
		[editorContent, handlePostSubmitModal, selectedUploadContent]
	);

	const removeContent = useCallback((index) => {
		setSelectedUploadContent((prev) => {
			return prev.filter((i, idx) => idx !== index);
		});
	}, []);

	const showUploadedContent = useCallback(() => {
		return (
			<div>
				{selectedUploadContent?.map((i, idx) => {
					return i?.type === 'image' ? (
						<div className="flex flex-col mb-3">
							<Image src={i?.url} alt="" className="w-[90%] h-40 object-contain self-center" />
							<ImCross
								color="#fff"
								className="p-2 rounded-full bg-gray-400 w-7 h-7 absolute right-[0.5rem]"
								onClick={() => removeContent(idx)}
							/>
						</div>
					) : (
						<div className="flex flex-col mb-3">
							<Player className='mx-3 self-center bg-[rgba(0,0,0,0.04)]' aspectRatio={'4:3'} playsInline poster="/assets/poster.png" src={i?.url} />
							<ImCross
								color="#fff"
								className="p-2 rounded-full bg-gray-400 w-7 h-7 absolute right-[0.5rem] "
								onClick={() => removeContent(idx)}
							/>
						</div>
					);
				})}
			</div>
		);
	}, [removeContent, selectedUploadContent]);
	return (
		<div className="flex min-h-[100vh] flex-col justify-between">
			<div className="flex flex-1  min-h-[80%] flex-col">
				<Header headerTxt={headerTxt} />
				<div className="text-[1.2rem] mx-3 mb-1">भेजे ऐसी स्टोरी पोस्ट, वीडियो या लेख, जिसे शेयर करने के लिए लोग हो जायें मजबूर</div>
				<div className="flex flex-col mt-2">
					<TextInput
						placeholder={placeholder}
						specific={''}
						editorContent={editorContent}
						setEditorContent={setEditorContent}
						handlePaste={()=>{}}
						containerStyle={`flex bg-[rgba(0,0,0,0.03)] border-[1px] w-[92%]
                        border-[rgba(0,0,0,0.2)] border-solid text-[1.2rem] h-[9rem] 
                        text-[rgba(0,0,0,0.25)] text-center rounded-xl justify-start self-center
                        pt-2 pl-3
                        items-start mb-4`}
						textareaContainerStyle={`
                        flex mb-4 
                        bg-[rgba(0,0,0,0.02)] 
                        rounded-xl 
                        h-[10.5rem]  text-base
                        outline-none
                        border-[1px] border-[#2C99FF]
                        py-2 px-3 w-[92%] 
                        self-center`}
					/>
				</div>
				<div className="self-start mx-3 text-[1.1rem] my-2 ">
                आप व्यापार सम्बंधित कोई भी फोटो, स्क्रीनशॉट या वीडियो डाल सकते है
				</div>

				<div
					onClick={onUploadClick}
					isDisabled={disableSubmitBtn}
					className="flex mt-2
                    font-bold border-[#F16943] border-2 text-[#F16943]
                    h-[3rem] justify-center items-center
                    text-center px-16 mx-3  rounded-lg text-[1.2rem] leading-[1.6rem] mb-4"
				>
					<input
						type="file"
						ref={uploadInpuRef}
						className="hidden"
						onChange={onContentUpload}
						accept="image/*,video/*"
					/>
					अपलोड करें
					<AiOutlineCloudUpload className="ml-[0.70rem]" size={24} />
				</div>
				{selectedUploadContent?.length ? showUploadedContent() : null}
			</div>
			<div
				className="flex items-end mb-[0.65rem] mx-3 rounded-lg"
				style={{ boxShadow: '0px 4px 17px rgba(0, 0, 0, 0.25)',  }}
			>
				<RippleButton
					onSubmit={handleSubmit}
					isDisabled={disableSubmitBtn}
					title="जमा करें"
					className={`flex flex-1 
                    font-bold  border-2 text-[#fff]
                    h-[3.2rem] justify-center items-center
                    text-center px-16  rounded-lg text-[1.25rem] leading-[1.6rem]`}
					style={{ backgroundColor: disableSubmitBtn ? `rgba(0,0,0,0.6)` : `#D45339` }}
					rippleColor="#003310"
				/>
			</div>
			<SubmitPostModal from='userSuggestion' showModal={showModal} thanksIcon={thanksIcon} handlePostSubmitModal={handlePostSubmitModal} />
			{percentage > 0 && <UploadLoader percentage={percentage} />}
		</div>
	);
}

export default SpecialSuggestions;
