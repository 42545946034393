export const SCHEME_TAG = 'Retailer Scheme';

export const TEJI_MANDI_TAG = "Teji Mandi"

// removing 'RMEoxIDhpWScljtTlJ9p4KOUOgl1', from reji mandi allowed users --> gaurav's task
export const TEJI_MANDI_ALLOWED_USERS = [
  '1OHq5PfQ6aTHIvP1HDGz1DZjjT93',
  'I7nob6nW0qZx7PhNVkE43rHcODn2',
  'tbjsd6p3stcA0tnVjpMkxvmb7Yi1',
  'FYRUcOeMoDYYCfAQb6h6CTg0p4A2',
	'bV8M7uF8uHaUHeCzwTZJYaMPdEy2',
	'aolJoCYIXQPEIT52Boo0fIyfVXq2',
	'2kxkHlPkObO8u2Cnf98bvTY53F93',
	'gF8vIzxkGvNwadoS0nFZrAv834u2',
	'xAP4NRWp70b899alEA3K8afTqgV2',
	'ssdIGH5nBqSE4IR61bytj3A66Rd2',
	'2SYQHD96rwS0r8jhGvAcUdL6DSV2',
	'XkOExufEktP25tJlsZ1Cr5jFpD93',
	'qhhs2Q9TtnV2xLm4eewFUsY83U22',
	'Z6h7SIqIoIYl0nNmaR8q3pEASi63',
	'VqRi3Ft3crZCb0DGBLZ1NbH2cFe2',
	'wMn4a578ChXPCC34Vl2Qn7KZFHV2',
	'zqkrZZNnK9hZ7h6rMGLwo5koWt13',
  'P3QTXgnd0GYUnpDzd4En5bgkAfH2',
  'fPrSDSqB7bZlzVmzFO2ZQ0tTeH93',
];

export const checkHindiWordPercentage = (text) => {
  if (typeof text !== 'string') {
    return 0;
  }

  const standaloneNumbersRegex = /\b\d+\b/g;
  const cleanedText = text
    .replace(standaloneNumbersRegex, '')
    .replace(/\s{2,}/g, ' ')
    .trim();

  const hindiRegex = /[\u0900-\u097F]/;
  const words = cleanedText.split(/\s+/);

  let hindiCount = 0;
  for (const word of words) {
    if (word.length > 0 && hindiRegex.test(word)) {
      hindiCount++;
    }
  }
  if (words.length === 0) {
    return 0;
  }
  let percentage = (hindiCount / words.length) * 100;
  return percentage.toFixed(0);
};

export function fromEntries(iterable) {
  return [...iterable].reduce((obj, [key, val]) => {
    obj[key] = val
    return obj
  }, {})
};
