import React from 'react';

function LeftArrow({ width = 24, height = 24, color = '#fff', className }) {
	return (
		<svg width={width} height={height} className={className} viewBox="0 0 24 24" fill="none">
			<path
				d="m10.875 19.3-6.6-6.6a.883.883 0 0 1-.213-.325A1.115 1.115 0 0 1 4 12c0-.134.02-.259.062-.375a.883.883 0 0 1 .213-.325l6.6-6.6a.978.978 0 0 1 .687-.288.933.933 0 0 1 .713.288c.2.183.304.412.313.687a.933.933 0 0 1-.288.713L7.4 11h11.175a.97.97 0 0 1 .713.287.97.97 0 0 1 .287.713c0 .283-.096.52-.287.712a.968.968 0 0 1-.713.288H7.4l4.9 4.9c.183.183.28.416.288.7a.872.872 0 0 1-.288.7c-.183.2-.417.3-.7.3a.988.988 0 0 1-.725-.3Z"
				fill={color}
			/>
		</svg>
	);
}

export default React.memo(LeftArrow);
