import React from 'react';

function BellOff({ width = 44, height = 44, color = '#fff', background = '#000', className }) {
	return (
		<svg width={width} height={height} className={className} viewBox="0 0 44 44" fill="none">
			<circle cx={22} cy={22} r={20} fill={background} fillOpacity={0.25} />
			<path
				d="M32.11 31.46 12.39 11.73 11.11 13l4.72 4.72A6.975 6.975 0 0 0 15 21v6l-2 2v1h15.11l2.73 2.73 1.27-1.27ZM17 28v-7c0-.61.11-1.21.34-1.77L26.11 28H17Zm3 3h4a2 2 0 0 1-4 0Zm-1.71-15.91c.53-.34 1.11-.59 1.71-.8V14a2 2 0 1 1 4 0v.29c2.97.88 5 3.61 5 6.71v4.8l-2-2V21a5 5 0 0 0-5-5c-.78 0-1.55.2-2.24.56l-1.47-1.47Z"
				fill={color}
			/>
		</svg>
	);
}

export default React.memo(BellOff);
