import { memo, useEffect, useState } from 'react';
import defaultErrorImg from '../../assests/brand-product-placeholder.png';
import greyBackground from '../../assests/grey-background.png';

function Image(props) {
	const {
		src,
		alt = '',
		className = '',
		fallbackSrc = greyBackground,
		onLoad,
		onError,
		...otherProps
	} = props;

	const [imgSrc, setImgSrc] = useState(src);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setImgSrc(src);
		setLoading(true);
	}, [src]);

	const handleLoad = (evt) => {
		setLoading(false);
		onLoad?.(evt);
	};

	const handleError = (evt) => {
		setLoading(false);
		setImgSrc(fallbackSrc);
		onError?.(evt);
	};
	return (
		<img
			src={imgSrc}
			alt={alt}
			className={`${loading ? 'bg-[#ededed]' : ''} ${className}`}
			onLoad={handleLoad}
			onError={handleError}
			{...otherProps}
		/>
	);
}

export default memo(Image);
